import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-04-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/136.png',

  heading: `Per difendere il risparmio`,
  paragraph: `Una buona strategia, se hai dei risparmi, è fare come la nonna di Omar con le uova! Non devi impiegarli tutti nello stesso modo ma è prudente “diversificare”, cioè dividerli in investimenti diversi. Potresti guadagnare meno che mettendoli tutti nell’investimento più rischioso, ma se qualcosa andrà storto ne perderai solo una parte. Immagina di comprare azioni sia di uno stabilimento balneare sia di una fabbrica di ombrelli: se l’estate sarà bella la fabbrica venderà meno ombrelli ma tu guadagnerai bene con lo stabilimento, e viceversa se invece l’estate sarà piovosa e la stagione balneare un disastro.`
}