import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Prova a collegare ciascuna di queste parole alla definizione corretta.`,
  paragraph: ``,

  items: [
    {
      term: `Mutuo ipotecario`,
      text: 'Prestito a lunga scadenza che viene utilizzato prevalentemente per acquistare, costruire o ristrutturare un immobile. Il pagamento delle rate è garantito da un\'ipoteca sul bene acquistato.'
    },
    {
      term: `Crowdfunding`,
      text: 'Raccolta di fondi che artisti, inventori, promotori di iniziative chiedono al pubblico, tipicamente per mezzo di una piattaforma online, per realizzare un progetto. Ogni sostenitore dà poco, ma se aderiscono in molti si può raccogliere tantissimo.'
    },
    {
      term: `Rata`,
      text: 'Pagamento che il cliente effettua periodicamente, secondo le scadenze stabilite nel contratto (mensili, trimestrali, semestrali), per restituire la somma presa in prestito.'
    },
    {
      term: `Credito al consumo`,
      text: 'Finanziamento per acquistare beni e servizi importanti per sé o per la propria famiglia - un\'auto, un elettrodomestico, un corso di lingue - oppure per affrontare situazioni in cui è necessario disporre di denaro liquido.'
    },
    {
      term: `Fido o affidamento`,
      text: 'È l’importo massimo di credito che la banca concede a un cliente.'
    },
  ],
  extra_terms: [],

  points: 1,

  feedback: {
    correct: `Grande!!! Davvero un’ottima serie di risposte.`,
    wrong: `Che confusione con tutti questi nomi, vero?`
  }
}