import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-03-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Da cosa è composto l’IBAN?`,
  paragraph: `In Italia l’IBAN è formato da una serie di 27 tra lettere e numeri. Questo è l’IBAN del conto di Omar IT 98 Z 12345 54321 123456789012.
  Sai dire a cosa corrispondono?`,

  items: [
    {
      text: 'IT',
      term: 'paese'
    },
    {
      text: '98',
      term: 'controllo'
    },
    {
      text: 'Z',
      term: 'CIN'
    },
    {
      text: '12345',
      term: 'ABI'
    },
    {
      text: '54321',
      term: 'CAB'
    },
    {
      text: '123456789012',
      term: 'Conto'
    },
  ],
  extra_terms: [],

  points: 1,

  feedback: {
    correct: `Bravo, corretto!`,
    wrong: `Mi dispiace, risposta sbagliata.`
  }
}