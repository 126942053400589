import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-01-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/308.png',

  heading: `Per il bene di tutti`,
  paragraph: `Una parte di ciò che guadagniamo va allo Stato; sono i tributi: imposte e tasse. Le imposte sono indipendenti dalla fruizione di un servizio e si pagano secondo il principio della capacità contributiva quando si verificano determinate condizioni, ad esempio l’IVA che si applica in fase di acquisto di un bene o servizio. Le tasse sono il corrispettivo di un servizio pubblico secondo il principio delle prestazioni e controprestazioni, che si applica quando il soggetto beneficiario è individuabile e non si ritiene che la tassa debba gravare su tutti, ad esempio la tassa sui rifiuti.
  Non pagare i tributi è da furbi? No! Con i tributi lo Stato finanzia attività di interesse generale ed è grazie alle imposte che abbiamo strade e ponti, polizia e ospedali, biblioteche e pompieri, sussidi per le fasce di popolazione più debole e tantissime altre cose utili per la comunità. Chi evade o elude, cioè paga meno di quanto dovrebbe, ruba un pezzo di tutte queste cose, togliendole a tutti noi, e penalizzando soprattutto i più svantaggiati. No, non è furbo per niente e commette un illecito punito dalla legge!`
}