import slide1 from './slides/01/section-02-page-01-slide-01';
import slide2 from './slides/02/section-02-page-01-slide-02';
import slide3 from './slides/03/section-02-page-01-slide-03';

export default {
  id: 'SECT-02–P-01',

  first_page: true,
  base_path: '/scuole/media/entrate-uscite',

  name: `Entrate e uscite... di sicurezza!`,

  icon: '/images/section-02/icons/page-01.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/103_0005_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 5
      },
      {
        image: '/images/section-02/animated/layers/103_0004_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      },
      {
        image: '/images/section-02/animated/layers/103_0003_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-02/animated/layers/103_0002_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-02/animated/layers/103_0001_5.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/103_0000_6.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Entrate e uscite... di sicurezza!`,
    paragraph: `Omar, Nina, Isotta e Teo si chiedono che faranno da grandi. Scrittore o fashion blogger? Tecnico spaziale o proprietario di sushi bar? Sono tutti lavori che danno belle soddisfazioni... e i soldi per vivere.`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};