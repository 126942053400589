import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j3.png',

  heading: ``,
  paragraph: `Aiuta Nina a fare il giusto budget per la settimana, controllando se i soldi le basteranno, facendo attenzione a non rimanere mai senza. Scegli una delle due ipotesi.`,

  answers: [
    {
      text: `Nina riceve 12 € dalla mamma lunedì mattina.
      Spende 1,5 € per la merenda di scuola ogni mattina da lunedì al venerdì.
      Spende 7 € per un braccialetto il mercoledì.
      Riceve 5 € dalla zia il sabato pomeriggio.`,
      feedback: `Attenzione, così hai fatto rimanere Nina senza soldi (e senza merenda) a partire da giovedì!`,
      feedbackType: 'error',
      points: 0
    },
    {
      text: `Nina riceve 10 € di paghetta lunedì mattina
      Spende 1,5 € per la merenda di scuola ogni mattina da lunedì al venerdì.
      Riceve 5 € dalla nonna il mercoledì pomeriggio.
      Spende 7 € per andare al cinema il venerdì.`,
      feedbackType: 'success',
      feedback: `Ottimo, sei già in grado di fare un budget!`,
      points: 1
    }
  ],
  hint: {
    message: '',
    image: ''
  }
}