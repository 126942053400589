import constants from '../../../../../../constants';

import hotspot1 from './hotspots/section-01-page-02-slide-03-hotspot-01';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/018_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Cosa sono domanda e offerta?`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1
  ],

  extra_class: 's1p2s3'
};