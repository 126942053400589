import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_SORT,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j23.png',

  heading: `La paghetta non è infinita (magari!). Cosa dovrebbe comprare Omar prima delle meringhe? Metti in ordine le idee di Omar. Prima le cose importanti!`,
  paragraph: ``,

  items: [
    {
      text: `Una penna e delle matite nuove, così smette di chiederle al suo compagno di banco!`,
    },
    {
      text: `Un nuovo paio di scarpe per giocare a calcio, quelle vecchie sono tutte scassate!`,
    },
    {
      text: `Un nuovissimo videogioco per la console, il trailer è bellissimo!`,
    },
  ],

  points: 1,

  feedback: {
    correct: `Bravissimo, ora Omar potrà finalmente lasciare in pace il suo compagno di banco!`,
    wrong: `Sì, sono belle le scarpe, e il videogioco anche, ma ora Omar continuerà a disturbare il suo compagno di banco!`
  }
}