import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j22.png',

  heading: `È mio! No è mio!`,
  paragraph: `I prezzi non vengono definiti solo dal percorso che fanno dalla produzione alla vendita ma anche da noi. Ebbene sì! Pensa a quando tu e tutti i tuoi amici volete qualcosa, mettiamo l'ultima fetta di dolce, questa improvvisamente ha un valore maggiore perché la volete in tanti e può averla solo uno di voi, mentre se ci fosse un'altra torta intera varrebbe di meno. `
}