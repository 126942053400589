import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-05-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/036.png',

  heading: `Quando sono tanti`,
  paragraph: `Torniamo al nostro amico Teo: ha bisogno di un po' di soldi per realizzare il suo sogno e organizzare un concerto. Ma come possiamo aiutarlo?`,

  answers: [
    {
      text: `Chiedo a un amico ricco, mi ha detto che devo riportargliene il doppio fra un mese`,
      feedbackType: 'error',
      feedback: `Mi dispiace, non è la risposta giusta, ma sbagliando si impara! L’offerta del tuo amico proprio non funziona! Ti chiede indietro troppi soldi rispetto a quelli che è disposto a prestarti.`,
      points: 0
    },
    {
      text: `Convinco Teo che la sua è un'idea pessima e che è meglio non fare il concerto`,
      feedbackType: 'error',
      feedback: `Mi dispiace, non è la risposta giusta, ma sbagliando si impara! Prima di rinunciare, considera tutte le opzioni possibili per portare avanti il tuo progetto.`,
      points: 0
    },
    {
      text: `Vado direttamente in banca a chiedere i soldi per Teo`,
      feedbackType: 'error',
      feedback: `Mi dispiace, non è la risposta giusta, ma sbagliando si impara! Da grande potrai rivolgerti direttamente alla banca. Per ora devi chiederei aiuto ad una persona adulta.`,
      points: 0
    },
    {
      text: `Parlo con Teo e lo convinco a parlare con i suoi genitori, che valuteranno se chiedere un piccolo finanziamento per il suo sogno, solo gli adulti possono chiedere i soldi in banca, lo sanno tutti!`,
      feedbackType: 'success',
      feedback: `Bravo, risposta giusta!`,
      points: 1
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}