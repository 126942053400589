import React from 'react';

import MarkdownParser from '../../../components/shared/MarkdownParser';

class BalloonHotspot extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };
  }

  handleClick = (e) => {
    e.stopPropagation();

    const { hotspot, pos, enabled } = this.props;

    if (enabled) {
      this.activate();
      this.props.onClick(e);
    }
  }

  activate = (e) => {
    this.setState({
      active: true
    });

    // activate content again, and add short delay to improve animation
    window.setTimeout(() => {
      this.setState({
        showContent: true
      });
    }, 200);
  }

  dismiss = (e) => {
    e.stopPropagation();

    this.setState({
      active: false
    }, () => {
      this.props.onClose();
    });

    // hide content to fix z-index issue
    window.setTimeout(() => {
      this.setState({
        showContent: false
      });
    }, 500);
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;
    const x = this.props.x || '20%';
    const y = this.props.y || '-10%';

    return (
      <div className={`HOTSPOT-BALLOON bankitalia-circle bankitalia-circle--${pos} js-circle ${this.state.active ? 'active' : ''} ${!enabled && !this.state.active ? 'bankitalia-circle--disabled' : ''} ${this.props.big ? 'big' : ''} magictime ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleClick}>
        <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        <div className="bankitalia-circle__info-label magictime tinUpIn"><span>INFO</span></div>

        {
          this.state.showContent && (
            <>
              <button className="bankitalia-circle__close" type="button" onClick={this.dismiss}>
                <img src="/images/icons/close.png" />
              </button>

              <img className="bankitalia-circle__icon" src={hotspot.featured_image} />

              <div className="bankitalia-circle__desc"><MarkdownParser source={hotspot.paragraph} /></div>
            </>
          )
        }
      </div>
    );
  }
}

export default BalloonHotspot;