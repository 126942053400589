import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/333.png',

  heading: `La capitalizzazione composta`,
  paragraph: `La capitalizzazione composta è una macchina molto potente. Investire un capitale in regime di capitalizzazione composta significa che il montante segue una funzione esponenziale. Non sei convinto? Guarda dai valori in tabella la differenza di interessi che puoi ottenere tra i regimi di capitalizzazione semplice e composta.

  Attenzione però: la capitalizzazione composta è un potente moltiplicatore dei risparmi nel lungo periodo, ma agisce a nostro sfavore quando ci indebitiamo.`
}