import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: '',
  paragraph: `Immagina che il tasso annuo di usura sia il 9%. Sai riconoscere se queste proposte di prestito sono legali o se il loro tasso è superiore a quello usuraio?`,

  label: 'LEGALE?',

  items: [
    {
      term: 'Prendi 300 € e tra un anno devi restituirne 321.',
      sum_cond_value: true
    },
    {
      term: 'Prendi 150 € e tra un anno devi restituirne 162.',
      sum_cond_value: true
    },
    {
      term: 'Prendi 500 € e tra un anno devi restituirne 550.',
      sum_cond_value: false
    }
  ],

  points: 1,

  feedback: {
    correct: `Benone! Sai smascherare chi pretende troppo da te...`,
    wrong: `Sembra difficile, ma pensaci bene... 21 € di interessi su 300 è il 7% (7 € ogni 100 prestati), 12 € su 150 è l’8%, 50 € su 500 è il 10%.`
  }
}