import constants from '../../../../../../constants';

import hotspot1 from './hotspots/section-01-page-03-slide-01-hotspot-01';
import hotspot2 from './hotspots/section-01-page-03-slide-01-hotspot-02';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/020_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/020_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
    ]
  },

  page_content: {
    heading: `Una tessera magica`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot2
  ],

  extra_class: 's1p3s1'
};