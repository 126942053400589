import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Omar è un po' confuso, non ricorda chi fa questi lavori. Fai tu i giusti collegamenti.`,
  paragraph: ``,

  items: [
    {
      text: `Lavorano in un negozio o in un'azienda per conto di qualcuno e vengono pagati ogni mese`,
      term: 'Cameriere, Cassiera'
    },
    {
      text: `Lavorano da soli o in uno studio di professionisti come loro e vengono pagati in base alle prestazioni che fanno`,
      term: 'Idraulico, Avvocato'
    },
    {
      text: `Hanno un negozio o un'attività e guadagnano in base a quanta merce riescono a vendere`,
      term: 'Panettiere, Gelataia'
    },
  ],
  extra_terms: [],
  
  points: 1,

  feedback: {
    correct: `Bravo, sono i collegamenti giusti!`,
    wrong: `I collegamenti non sono giusti`
  }
}