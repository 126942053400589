import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-02-HOT-04',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: '',
  paragraph: `Le conseguenze della mancata restituzione: se chiediamo un finanziamento e non rispettiamo il piano concordato di restituzione possiamo incorrere in una serie di conseguenze. Aiuta Isotta a capire quali sono vere e quali false.`,

  label: 'VERO?',

  items: [
    {
      term: 'L’intermediario può vendere il bene ipotecato per recuperare i suoi soldi',
      sum_cond_value: true
    },
    {
      term: 'L’intermediario può rivalersi sui beni di chi ha prestato garanzia solo se quest’ultimo è d’accordo',
      sum_cond_value: false
    },
    {
      term: 'Scatta una segnalazione come cattivo pagatore nei sistemi creditizi',
      sum_cond_value: true
    },
    {
      term: 'Solo l’intermediario a cui non vengono restituiti i soldi saprà che non siamo riusciti a ripagare il debito',
      sum_cond_value: false
    },
    {
      term: 'Dopo un anno, sicuramente non ci sarà più alcuna conseguenza',
      sum_cond_value: false
    },
    {
      term: 'Le possibilità di ottenere altri finanziamenti in futuro diminuiranno',
      sum_cond_value: true
    }
  ],

  points: 1,

  feedback: {
    correct: `Ottimo, hai ben compreso cosa significa assumere un impegno!`,
    wrong: `Non ci siamo proprio, fossi in te ci penserei bene prima di non pagare le rate! Non ripagare un debito, o non farlo nei tempi e modi convenuti, ha tante conseguenze, anche nel medio termine!`
  }
}