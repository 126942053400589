import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/108.png',

  heading: `Per il bene di tutti`,
  paragraph: `Non tutto quello che guadagniamo finisce nelle nostre tasche. Una parte viene data allo Stato e agli Enti locali (come le Regioni e i Comuni): sono le imposte. Paghiamo anche le tasse, come il ticket sulle medicine o il canone RAI, ogni volta che otteniamo certi servizi. E l’IVA, che è un’imposta su ogni cosa che compriamo. Tasse e imposte sono dette tributi.

  Stato ed Enti locali che cosa ci fanno, con questi soldi? Pagano i servizi pubblici: strade e ponti, polizia e ospedali, assistenza ai bisognosi, biblioteche e pompieri... E tantissime altre cose utili a ognuno di noi, che altrimenti non si potrebbero garantire a tutti. Chi evade le tasse ci ruba un pezzo di tutte queste cose.`
}