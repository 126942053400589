const PointsUtil = {
  getStoreKey: (pageId) => {
    const storeKey = `bankitalia:score:${pageId}`;

    return storeKey;
  },

  resetPoints: (pageId) => {
    if (!pageId) {
      console.error('ERROR: no page ID while trying to reset points');

      return;
    }

    const storeKey = PointsUtil.getStoreKey(pageId);

    window.localStorage.setItem(storeKey, 0);
  },

  addPoints: (hotspot, numPoints) => {
    if (!numPoints) {
      console.error('WARNING: no points passed, but it\'s always 1 so just override');

      numPoints = 1;
    }

    const pageId = hotspot.id.split('-SL')[0];

    if (!pageId) {
      console.error('ERROR: no page ID while trying to add points (will dump hotspot)', hotspot);

      return;
    }

    const storeKey = PointsUtil.getStoreKey(pageId);

    window.localStorage.setItem(storeKey, Number(window.localStorage.getItem(storeKey)) + Number(numPoints));
  },

  getPoints: (pageId) => {
    if (!pageId) {
      console.error('ERROR: no page ID while trying to get points');

      return 'N/D';
    }

    const storeKey = PointsUtil.getStoreKey(pageId);

    return window.localStorage.getItem(storeKey);
  }
};

export default PointsUtil;