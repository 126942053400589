import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Me li merito?`,
  paragraph: `Oramai è chiaro: siamo disposti a prestare i nostri soldi a qualcuno solo se pensiamo che ce li restituirà. Lo stesso vale per la banca, anche perché non utilizza i propri soldi ma quelli dei risparmiatori ai quali, comunque vada il prestito, dovrà restituirli.

  Per questo prima di concedere un prestito la banca valuta alcune caratteristiche dei richiedenti, che definiranno il loro merito creditizio, cioè la loro affidabilità dal punto di vista economico-finanziario, come ad esempio eventuali altri debiti, la presenza di un reddito fisso e se in passato hanno onorato i propri impegni con le banche. Più alto è il merito creditizio, più alta la possibilità di ricevere credito, migliori le condizioni a cui si riceve il prestito e minori le garanzie richieste. E se il mio merito creditizio fosse troppo basso potrei non trovare nessuno disposto a finanziarmi.`
}