import slide1 from './slides/01/section-01-page-03-slide-01';
import slide2 from './slides/02/section-01-page-03-slide-02';
import slide3 from './slides/03/section-01-page-03-slide-03';

export default {
  id: 'SECT-01_P-03',
  
  first_page: false,
  base_path: '/scuole/elementare/cose-da-grandi',

  name: `Cose da grandi`,

  icon: '/images/section-01/icons/page-03.png',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/019_0006_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 6
      },
      {
        image: '/images/section-01/layers/animate/019_0007_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 7
      },
      {
        image: '/images/section-01/layers/animate/019_0000_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/019_0001_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-01/layers/animate/019_0005_5.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 5
      },
      {
        image: '/images/section-01/layers/animate/019_0002_6.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-01/layers/animate/019_0003_7.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-01/layers/animate/019_0004_8.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      }
    ]
  },

  page_content: {
    heading: `Cose da grandi`,
    paragraph: `Nina e Isotta non smettono mai di crescere e vogliono diventare subito adulte, così si stanno preparando per quando saranno grandi e potranno usare una loro carta di debito e avranno un loro conto in banca. Ma forse non hanno ancora le idee chiare, puoi dargli una mano?`
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};