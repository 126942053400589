import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-04-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: '',
  paragraph: `Tenere i soldi su un conto corrente ha i suoi vantaggi… Sai indovinare quali sono veri e quali falsi?`,

  label: 'VERO?',

  items: [
    {
      term: 'Puoi riprendere i tuoi soldi quando vuoi, anche a banche chiuse grazie agli sportelli bancomat.',
      sum_cond_value: true
    },
    {
      term: 'A ogni Natale la banca ti manda un pandoro o un panettone.',
      sum_cond_value: false
    },
    {
      term: 'Hai tutta una serie di servizi a disposizione, dalla possibilità di fare bonifici a quella di pagare bollette e tasse.',
      sum_cond_value: true
    },
    {
      term: 'I soldi nel tuo conto sono assicurati fino a 100.000 euro:  non li perderesti nemmeno se fallisse la banca.',
      sum_cond_value: true
    },
    {
      term: 'Se presenti un amico alla tua banca e apre un conto anche lui, per i primi sei mesi gli interessi del suo conto li danno a te.',
      sum_cond_value: false
    }
  ],

  points: 1,

  feedback: {
    correct: `Bel colpo! Sei proprio un esperto di conti correnti`,
    wrong: `Mi sa che hai le idee un po’ troppo confuse...`
  }
}