import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-04-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: 'Su cosa risparmiare?',
  paragraph: `Impariamo a distinguere le cose necessarie da quelle non necessarie così riusciremo a risparmiare i soldi per Attila!`,

  label: 'SUPERFLUO?',

  items: [
    {
      term: 'Quaderni per la scuola',
      sum_cond_value: false
    },
    {
      term: 'Penne e matite',
      sum_cond_value: false
    },
    {
      term: 'Gelato',
      sum_cond_value: true
    },
    {
      term: 'Figurine',
      sum_cond_value: true
    },
    {
      term: 'Nuovo videogioco',
      sum_cond_value: true
    },
    {
      term: 'Pallone',
      sum_cond_value: true
    },
    {
      term: 'Un gioco da tavola',
      sum_cond_value: true
    },
    {
      term: 'Sciarpa',
      sum_cond_value: false
    }
  ],

  points: 1,

  feedback: {
    correct: `Bravo! Hai risposto correttamente!`,
    wrong: `Sei sicuro che tutte le cose non siano necessarie?`
  }
}