import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_DISCOVER,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Come è fatta una carta di debito`,
  paragraph: ``,

  items: [
    {
      text: `Di solito sulla carta c'è scritto il nome della banca che custodisce i tuoi soldi. O magari un logo che la identifica.`,
      term: 'Nome della banca',
      color: 'xxxx'
    },
    {
      text: `Si tratta di un numero di 16 cifre che serve a identificare la carta e che ti puo' essere chiesto per fare  acquisti online.`,
      term: 'Numero della carta',
      color: 'xxxx'
    },
    {
      text: `Un mini processore che contiene le informazioni necessarie per utilizzare la carta, ad esempio per gli acquisti in negozio o per prelevare.`,
      term: 'Chip',
      color: 'xxxx'
    },
    {
      text: `Le carte scadono, proprio come il cibo. È un modo per aggiornarle a nuovi sistemi di protezione. Ma la data di scadenza è anche uno dei dati che ti potrebbero essere richiesti  per fare acquisti online.`,
      term: 'Data di scadenza',
      color: 'xxxx'
    },
    {
      text: `È il circuito, ad esempio Mastercard o Visa, che identifica la società che organizza le procedure per utilizzare la carta.`,
      term: 'Circuito di pagamento',
      color: 'xxxx'
    },
    {
      text: `Sono quattro piccole linee curve simili a delle onde che crescono verso destra. Al di sotto di una certa cifra puoi pagare semplicemente accostando la carta al dispositivo del negoziante e come per magia il pagamento è fatto! … non serve nemmeno inserire il PIN.`,
      term: 'Simbolo del Contactless',
      color: 'xxxx'
    },
    {
      text: `contiene tutte le informazioni della carta ma in un formato che può essere interpretato solo dai lettori magnetici!`,
      term: 'Banda magnetica',
      color: 'xxxx'
    },
    {
      text: `Si tratta di un codice di 3 cifre utilizzato nei pagamenti online. `,
      term: 'Codice di sicurezza',
      color: 'xxxx'
    },
  ]
}