import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j3.png',

  heading: `La paghetta è il reddito dei bambini. Ma cos'è il reddito?`,
  paragraph: ``,

  answers: [
    {
      text: `è la somma di denaro che riceviamo in un determinato periodo di tempo`,
      feedback: `Bravo, è la risposta giusta!`,
      feedbackType: 'success',
      points: 1
    },
    {
      text: `sono i soldi che ti danno i parenti e che puoi spendere per comprare i videogiochi`,
      feedbackType: 'error',
      feedback: `Ci sei quasi, ma non è la risposta corretta. Il reddito puoi usarlo come vuoi, non è obbligatorio spenderlo per i videogiochi!`,
      points: 0
    },
    {
      text: `sono i soldi da restituire a qualcuno che ci sta molto simpatico`,
      feedbackType: 'error',
      feedback: `Mi dispiace, non è la risposta giusta, ma sbagliando si impara!`,
      points: 0
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}