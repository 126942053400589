
import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';

import PointsUtil from '../../../utils/PointsUtil';

import HotspotModal from './HotspotModal';

export default class InvestmentIdeasModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.INITIAL_CAPITAL = 100;

    this.state = {
      modalOpen: false,

      amounts: [],

      moneyLeft: 0,
      returns: 0,

      submitted: false
    };
  }

  componentDidMount() {
    const { hotspot } = this.props;

    this.setState({
      moneyLeft: hotspot.starting_balance,
      amounts: hotspot.ideas.map(x => 0) // just init to 0
    });
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });
    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  increaseValue = (i) => {
    const nextValue = this.state.amounts[i] + 1;

    this.setCurrValue(i, nextValue);
  }
  decreaseValue = (i) => {
    const nextValue = this.state.amounts[i] - 1;

    this.setCurrValue(i, nextValue);
  }
  setCurrValue = (i, value) => {
    const { hotspot } = this.props;

    value = value === '' ? '' : (!value ? 0 : parseInt(value));
    if (value < 0) {
      value = 0;
    }

    let amounts = [...this.state.amounts];
    amounts[i] = value;

    const moneyLeft = hotspot.starting_balance - amounts.reduce((a, b) => a + b, 0);

    if (moneyLeft < 0) {
      console.warn('not enough money left');

      return;
    }

    this.setState({
      submitted: false, // reset

      amounts,
      moneyLeft
    });
  }

  handleSubmit = () => {
    const { hotspot, pos, enabled } = this.props;

    // validate
    if (this.state.submitted) {
      console.warn('Already submitted');

      return;
    }

    // calculate investment returns
    let returns = 0;
    hotspot.ideas.forEach((idea, i) => {
      const amount = parseInt(this.state.amounts[i]);

      returns += amount * idea.payoff_multiplier;
    });

    this.setState({
      submitted: true,

      returns
    }, () => {
      // update score
      const shouldAddPoints = returns > this.INITIAL_CAPITAL;
      const numPoints = hotspot.points;
      if (shouldAddPoints) {
        PointsUtil.addPoints(hotspot, numPoints);
      }
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });

    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    return (
      <>
        <div className={`HOTSPOT-INV-IDEAS bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 bankitalia-modal__col bankitalia-modal__col--question">
              <div className="bankitalia-quiz">
                <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
                <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

                <h3 className="bankitalia-quiz__question" style={{marginTop: '1em', zoom: 0.8}}>Ti rimangono: {this.state.moneyLeft ? this.state.moneyLeft + ' €, continua ad investire!' : 0}</h3>

                {
                  hotspot.ideas.map((idea, i) => {
                    return (
                      <div className="bankitalia-quiz__answer">
                        <div className="row">
                          <div className="col-12 col-md">
                            <p className="bankitalia-quiz__desc" style={{zoom: 0.85}}>{idea.descr}</p>
                          </div>
                          <div className="col-12 col-md-auto d-flex justify-content-start align-items-start pl-0 pl-md-4">
                            <div className="bankitalia-quiz__actions bankitalia-quiz__actions--amount" style={{zoom: 0.8, margin: 0}}>
                              <div className="bankitalia-amount">
                                <strong className="bankitalia-amount__currency">€</strong>
                                <input className="bankitalia-amount__input" type="text" value={this.state.amounts[i]} data-min="0" data-max={this.state.starting_balance || 0} onChange={(e) => this.setCurrValue(i, e.currentTarget.value)} />
                                <div className="bankitalia-amount__controls">
                                  <button className="bankitalia-amount__increase" type="button" onClick={(e) => this.increaseValue(i)}>
                                    <img src="/images/icons/arrow-up.png" srcset="/images/icons/arrow-up.png, /images/icons/arrow-up-2x.png 2x" title="Close menu" alt="Close" />
                                  </button>
                                  <button className="bankitalia-amount__decrease" type="button" onClick={(e) => this.decreaseValue(i)}>
                                    <img src="/images/icons/arrow-down.png" srcset="/images/icons/arrow-down.png, /images/icons/arrow-down-2x.png 2x" title="Close menu" alt="Close" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        { this.state.submitted && <p className="bankitalia-quiz__desc" style={{zoom: 0.75}}><strong>{idea.feedback}</strong></p> }
                      </div>
                    );
                  })
                }

                <p>
                  <button className={`bankitalia-btn ${(this.state.submitted || this.state.moneyLeft) && 'disabled'}`} type="button" onClick={this.handleSubmit}>
                    <span className="bankitalia-btn__label">Ho scelto!</span>
                  </button>
                </p>

                {
                  this.state.submitted && (
                    <div className="bankitalia-quiz__message">
                      <div className={`bankitalia-message bankitalia-message--${this.state.returns > hotspot.starting_balance ? 'success' : 'error'}`}>
                        <p>
                          Sei partito da un capitale di {this.INITIAL_CAPITAL} € e adesso dopo aver fatto i tuoi investimenti hai: {this.state.returns} €!
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}