import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_INPUT_VALUE,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Quante punte di freccia servono?`,
  paragraph: `Per capire il vantaggio della moneta-merce come unità di conto, calcola quante punte di freccia servono per ottenere 3 pecore in base alle equivalenze riportate di seguito:

  Due pellicce = tre sacchi di semi
  Due pecore = una carcassa di bisonte
  Due vasi di coccio = una lancia
  Una pietra focaia = due punte di freccia
  Tre spiedi di ferro = una pelliccia
  Un sacco di semi = due pietre focaie
  Quattro lance = mezza carcassa di bisonte
  Una pecora = due spiedi di ferro`,

  feedback_by_value: [
    {
      value: 12,
      operator: '==',
      feedback_message: `Bel colpo! La risposta è esatta.`,
      feedback_type: 'success'
    },
    {
      value: 12,
      operator: '!=',
      feedback_message: `Purtroppo no... La soluzione è 12. Per ottenere 3 pecore occorre infatti dare 6 spiedi, che valgono 2 pellicce, che valgono 3 sacchi di semi, che valgono 6 pietre focaie, le quali valgono appunto 12 punte di freccia.`,
      feedback_type: 'error'
    }
  ],

  value_type_override: ' ',

  feedback_comment: `Ma pensa come sarebbe stato più facile il calcolo se il pugno di sale fosse stata la moneta-merce, sapendo che una pecora vale 4 pugni di sale e una punta di freccia 1 pugno di sale!`
}