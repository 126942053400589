import constants from '../../../../../../constants';

import hotspot1 from './hotspots/section-01-page-03-slide-02-hotspot-01';
import hotspot2 from './hotspots/section-01-page-03-slide-02-hotspot-02';
import hotspot3 from './hotspots/section-01-page-03-slide-02-hotspot-03';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/022_0000_1.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `A cosa serve il PIN?`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: 's1p3s2'
};