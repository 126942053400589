import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Risultati interessanti`,
  paragraph: `Investire dei soldi significa rinunciare temporaneamente alla loro disponibilità per poter percepire un interesse, che è una ricompensa per il tempo durante il quale non possiamo utilizzare il nostro denaro.

  Solitamente, l’interesse che percepiamo si esprime con una percentuale sul capitale versato su base annua: un tasso del 3% all’anno, ad esempio, significa che per ogni 100 € investiti il proprietario dei soldi ne ottiene 3 dopo un anno. Se l’investimento dura più anni, il tasso di interesse può essere calcolato secondo due metodi diversi. A partire dal secondo anno di investimento infatti, l’interesse percepito può essere calcolato solo sul capitale iniziale (e in questo caso si parla di capitalizzazione semplice) o sulla somma tra capitale iniziale e interessi percepiti nell’anno precedente (e in questo caso si parla di capitalizzazione composta). La formula per calcolare il montante (e cioè il valore del capitale iniziale e degli interessi maturati nel periodo di riferimento) varia a seconda dell’utilizzo del metodo di capitalizzazione semplice o composta.

  Se utilizziamo il metodo di capitalizzazione semplice la formula è:
  Montante = Capitale iniziale x (1 + tasso di interesse x numero di anni). Quindi, investendo 1.200 ad un tasso del 3% per 2 anni otterremmo 1.272 euro.
  Nella capitalizzazione composta la formula è:
  Montante = Capitale iniziale x (1 + tasso di interesse)^(numero di anni).
  Quindi, investendo 1.200 ad un tasso del 3% per 2 anni otterremmo 1.273,08 euro.

  Da cosa dipende la differenza tra i due montanti?

  Alla fine del primo anno il rendimento del nostro investimento è pari a 36 euro, qualunque sia il metodo di capitalizzazione utilizzato.

  Il secondo anno però utilizzando il metodo di capitalizzazione semplice matureremo interessi solo sul capitale iniziale. I nostri interessi saranno quindi il 3% di 1.200 euro ovvero 36 euro. Utilizzando il metodo della capitalizzazione composta, per il secondo anno matureremo interessi non solo sul capitale iniziale – come nella capitalizzazione semplice - ma sulla somma tra il capitale iniziale e gli interessi maturati nel primo anno: i nostri interessi saranno quindi il 3% di 1.236 euro. In altri termini, con la capitalizzazione composta gli interessi si applicano ad importi che – a parità di capitale iniziale – sono maggiori.`
}