import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-03-HOT-04',

  type: constants.HOTSPOT_TYPE_MODAL_INPUT_VALUE,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Dai un contributo alla tua scuola`,
  paragraph: `Proprio come i grandi che pagano le tasse per i servizi, decidi di dare un contributo alla tua scuola.

  Hai a disposizione 5 euro di paghetta alla settimana, decidi quanto donare.`,

  feedback_by_value: [
    {
      value: 5,
      operator: '>',
      feedback_message: `Ma è più di quello che hai! D'accordo che ti piace la scuola, ma le tasse devono essere minori di quanto guadagni!`,
      feedback_type: 'error'
    },
    {
      value: 5,
      operator: '==',
      feedback_message: `Sei molto generoso! Ma la paghetta serve anche a te! Altrimenti come le compri le figurine?`,
      feedback_type: 'error'
    },
    {
      value: 4,
      operator: '==',
      feedback_message: `Complimenti! In un anno riusciresti a donare 208 euro e potresti donare:

      1 Risma di carta: 6 euro
      2 Confezioni di pennarelli e matite: 8 euro
      3 Palloni per la palestra: 30 euro
      5 Libri per la biblioteca scolastica: 50 euro
      1 Super telescopio: 114 euro`,
        feedback_type: 'success'
    },
    {
      value: 3,
      operator: '==',
      feedback_message: `Ottimo! In un anno riusciresti a donare 156 euro e acquistare:

      3 Risme di carta: 18 euro
      2 Confezioni di pennarelli e matite: 8 euro
      3 Palloni per la palestra: 30 euro
      10 Libri per la biblioteca scolastica: 100 euro`,
      feedback_type: 'success'
    },
    {
      value: 2,
      operator: '==',
      feedback_message: `Molto bene! In un anno riusciresti a donare 104 euro e acquistare:

      1 Confezione di pennarelli e matite: 4 euro
      4 Palloni per la palestra: 40 euro
      6 libri per la biblioteca scolastica: 60 euro`,
      feedback_type: 'success'
    },
    {
      value: 1,
      operator: '==',
      feedback_message: `Bene! In un anno riusciresti a donare 52 euro e acquistare:

      3 Confezioni di pennarelli e matite: 12 euro
      1 pallone per la palestra: 10 euro
      3 libri per la biblioteca scolastica: 30 euro`,
      feedback_type: 'success'
    },
    {
      value: 0,
      operator: '==',
      feedback_message: `Suvvia, è pur sempre la tua scuola! Prova a fare una donazione.`,
      feedback_type: 'error'
    }
  ]
}