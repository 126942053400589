import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-03-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/327.png',

  heading: `A chi fa comodo il contante?`,
  paragraph: `Nonostante l’utilizzo di strumenti di pagamento alternativi al contante sia cresciuto nel tempo, i pagamenti in contante rimangono quelli più utilizzati e l’Italia è tra i paesi che fa ne fa maggior uso nell’area dell’euro. In tutti i paesi le banconote sono detenute sia per effettuare i pagamenti sia come riserva di valore. Esiste però un lato oscuro nella detenzione di banconote, soprattutto di quelle di importo maggiore. L’utilizzo del contante infatti permette di effettuare transazioni che non lasciano traccia… il contante può essere utilizzato per pagare i lavoratori senza un regolare contratto di lavoro (i lavoratori in nero) e per altre operazioni illecite: contrabbando, riciclaggio, spaccio di stupefacenti, usura…

  Inoltre i pagamenti alternativi al contante offrono opportunità di risparmio per la società. Sia le banconote sia gli strumenti di pagamento alternativi al contante hanno un costo. Questi ultimi offrono però opportunità di risparmio. Una volta realizzate le infrastrutture, aumentare i volumi dei pagamenti elettronici non comporta grandi costi aggiuntivi mentre un maggior utilizzo delle banconote fa crescere sempre i costi di produzione e quelli legati alla loro circolazione.`
}