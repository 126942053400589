export default {
  HOTSPOT_TYPE_INFO_PANEL: 'info_panel',
  HOTSPOT_TYPE_BALLOON: 'balloon',
  HOTSPOT_TYPE_BIG_BALLOON: 'balloon_big',
  HOTSPOT_TYPE_MODAL_MULTI: 'modal_multi',
  HOTSPOT_TYPE_MODAL_SORT: 'modal_sort',
  HOTSPOT_TYPE_MODAL_ASSOC: 'modal_assoc',
  HOTSPOT_TYPE_MODAL_MULTI_TOGGLES: 'modal_multi_toggles',
  HOTSPOT_TYPE_MODAL_DISCOVER: 'modal_discover',
  HOTSPOT_TYPE_MODAL_GAME: 'modal_game',
  HOTSPOT_TYPE_MODAL_INPUT_VALUE: 'modal_input_value',
  HOTSPOT_TYPE_MODAL_INVESTMENT_IDEAS: 'modal_multi_input_value',
  HOTSPOT_TYPE_MODAL_INTEREST_CALC: 'modal_interest_calc',
  HOTSPOT_TYPE_CUSTOM_CC_PIN: 'modal_pin'
};