import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02_P-01-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/105.png',

  heading: `Ma che cos’è questo reddito?`,
  paragraph: `Il reddito è la quantità di denaro che riceviamo in un certo periodo di tempo, e che abbiamo a disposizione per le nostre spese. Come regola generale, le spese non devono superare il reddito. È anche opportuno imparare a risparmiarne un po’, tenendolo da parte per quando ci servirà.`
}