import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-02-HOT-04',

  type: constants.HOTSPOT_TYPE_BIG_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Il trasporto`,
  paragraph: `L'agricoltore, invece di venirci a cercare uno per uno per vendere le fragole che produce, le vende a qualcuno che le può trasportare in tanti posti diversi. La frutta viene caricata in camion e camioncini, e mandata in posti vicini e lontani per poter essere venduta, ma il trasporto ha un costo e quindi le fragole salgono un pochino di prezzo, diciamo a 2 euro al chilo.`
}