import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_SORT,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Isotta vuole comprare su Internet un binocolo per le sue escursioni in montagna. Ne ha trovati cinque che le potrebbero piacere: tu sai metterli in ordine da quello meno costoso al più caro in euro, sapendo il cambio del giorno?

  Cambio del giorno:

  1 Euro=1,25 dollari USA
  1 dollaro USA =92 rubli russi
  1 rublo russo= 0,1 yuan cinesi
  1 yuan cinese =0,4 rand sudafricani
  1 rand sudafricano= 0,375 corone danesi

  Prezzi:`,

  items: [
    {
      text: `200 Yuan cinesi`,
    },
    {
      text: `2.400 Rubli russi`,
    },
    {
      text: `40 Dollari USA`,
    },
    {
      text: `760 Rand sudafricani`,
    },
    {
      text: `400 Corone danesi`,
    }
  ],

  points: 1,

  feedback: {
    correct: `Risposta esatta! Tu sì che sai individuare l’offerta più conveniente. Considera però che quando acquisti dall’estero potresti dover pagare la dogana`,
    wrong: `Mi spiace, sbagliato. Riprova a convertire tutti i prezzi in euro.`
  }
}