import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/142.png',

  heading: `Mi conviene o no?`,
  paragraph: `Per valutare un finanziamento che ci viene offerto e capire se è conveniente confrontandolo con altri, occorre esaminarne le diverse caratteristiche.

  Per prima cosa bisogna calcolare quanto costa. Quando investiamo, il nostro “disturbo” è remunerato con un interesse attivo, cioè che possiamo riscuotere; invece quando riceviamo un prestito dobbiamo remunerare il “disturbo” degli altri, pagando un interesse passivo. Il tasso di interesse misura, in percentuale, quanto va restituito in più rispetto alla somma ricevuta: più il tasso è alto, più il prestito è costoso. Il tasso può essere fisso o variabile: se è fisso rimane uguale nel tempo, mentre se è variabile può cambiare a seconda del verificarsi di specifiche condizioni, ad esempio in base all’andamento di un parametro.
  
  Poi bisogna valutare la durata del prestito: in quanto tempo va restituito. Più a lungo terremo i soldi, più saranno alti gli interessi che dovremo pagare.
  
  Nelle valutazioni ci aiuta il piano di ammortamento, cioè una tabella in cui indichiamo le somme da restituire alle varie scadenze, cioè le rate. Confrontando la rata con il nostro reddito possiamo capire una cosa fondamentale e cioè se riusciremo a rimborsare tutto il debito senza rinunciare a una parte troppo grande del nostro reddito.`
}