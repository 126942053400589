import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-04-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Tra questi due tipi di deposito in banca, sai indovinare qual è quello che dà al correntista gli interessi più alti?`,

  answers: [
    {
      text: `Conto corrente: le somme depositate si possono usare quando si vuole per prelievi, bonifici e altro ancora.`,
      feedback: `Peccato, hai sbagliato: in effetti la comodità di disporre del denaro si paga ricevendo pochi interessi.`,
      feedbackType: 'error',
      points: 0
    },
    {
      text: `Deposito vincolato: impegnano chi versa a lasciare il capitale alla banca fino a una scadenza stabilita per avere in cambio l’interesse pattuito.`,
      feedbackType: 'success',
      feedback: `Risposta esatta: Bravo, hai indovinato! La banca dispone dei soldi per tutto il periodo; chi li deposita rinuncia temporaneamente ad utilizzarli e questo “favore” che fa alla banca gli viene pagato con un interesse più alto.`,
      points: 1
    }
  ],
  hint: {
    message: '',
    image: ''
  }
}