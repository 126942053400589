import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-05-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/036.png',

  heading: `Quando sono pochi`,
  paragraph: `Facciamo un esempio: ti mancano i soldi per una bella granita e fa veramente caldo, è proprio fondamentale mangiarne una! Ma controlli le tasche e... niente, neanche un centesimo! A chi chiedi?`,

  answers: [
    {
      text: `A un amico che è lì con te, la prossima volta glielo offrirai tu!`,
      feedbackType: 'success',
      feedback: `Bravo, risposta giusta!`,
      points: 1
    },
    {
      text: `Vado in banca a chiedere un finanziamento per 2 euro e mezzo, sennò a che servono le banche?`,
      feedbackType: 'error',
      feedback: `Mi dispiace, non è la risposta giusta, ma sbagliando si impara! Quando i soldi che ci mancano sono pochi, è più semplice chiedere ai nostri familiari.`,
      points: 0
    },
    {
      text: `A un familiare, magari il prestito si trasforma in un regalo!`,
      feedbackType: 'success',
      feedback: `Bravo, risposta giusta!`,
      points: 1
    },
    {
      text: `Chiedo al chiosco se posso mangiare la granita gratis!`,
      feedbackType: 'error',
      feedback: `Mi dispiace, non è la risposta giusta, ma sbagliando si impara! Il negoziante non può regalare le granite a tutti coloro che le chiedono, altrimenti come fa a guadagnare?`,
      points: 0
    }
  ],
  hint: {
    message: '',
    image: ''
  }
}