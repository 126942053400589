import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-02-HOT-05',

  type: constants.HOTSPOT_TYPE_BIG_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Il fruttivendolo`,
  paragraph: `I camion arrivano dai fruttivendoli o dai supermercati che comprano la frutta a 2 euro al chilo, e la preparano per essere venduta al cliente finale: cioè noi! Però anche il fruttivendolo ha delle spese, ci sono l'affitto del negozio, gli scaffali, il suo lavoro e le tasse, quindi alla fine il prezzo delle fragole che compriamo noi è di 3 euro e mezzo al chilo. Semplice no?`
}