import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-05-SL-01-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-05-SL-01-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-05-SL-01-HOT-03';

export default {
  id: 'SECT-02–P-05-SL-01',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/139_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: 'fastest',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Prestare vuol dire fiducia`,
    paragraph: `Da sempre il credito fa progredire l’umanità…`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};