import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-01-SL-01-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-01-SL-01-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-01-SL-01-HOT-03';
import hotspot4 from './hotspots/SECT-02–P-01-SL-01-HOT-04';

export default {
  id: 'SECT-02–P-01-SL-01',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/104_0001_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/104_0000_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Come si fa a guadagnare?`,
    paragraph: `Ci sono vari modi per ottenere soldi…`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3,
    hotspot4
  ],

  extra_class: ''
};