import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Trascina ciascuna di queste parole vicino alla definizione corretta...`,

  items: [
    {
      text: `Tessera con cui si possono pagare acquisti senza dare i soldi subito, rimborsandoli dopo un certo tempo: di solito ogni mese, talvolta a rate. Consente anche di farsi anticipare contanti.`,
      term: 'Carta di credito'
    },
    {
      text: 'Prestito illegale a tassi d’usura.',
      term: 'Strozzinaggio'
    },
    {
      text: 'Prestito concesso per acquistare una casa o un terreno.',
      term: 'Mutuo fondiario'
    },
    {
      text: 'Raccolta di fondi che promotori di iniziative innovative, ad esempio artisti e inventori, chiedono al pubblico, attraverso siti web specializzati, per realizzare un progetto. Ogni sostenitore dà poco, ma se aderiscono in molti si può raccogliere tantissimo.',
      term: 'Crowdfunding'
    },
    {
      text: 'Prestito di piccole somme di denaro a un tasso di interesse contenuto, dato a chi vuole avviare un’attività ma non può offrire garanzie; concesso da enti autorizzati, viene comunque quasi sempre restituito per onestà e senso dell’onore. ',
      term: 'Microcredito'
    }
  ],
  extra_terms: [],

  points: 1,

  feedback: {
    correct: `Grande!!! Hai vinto 1 punto`,
    wrong: `Che confusione con tutti questi nomi, vero?`
  }
}