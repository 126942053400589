import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-03-HOT-01',
  
  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j24.png',

  heading: `Il conto corrente`,
  paragraph: `Isotta ha sentito la mamma parlare di conto corrente, cioè una specie di grande salvadanaio virtuale che una persona tiene in banca. La carta di debito è collegata proprio a quel conto ed è un metodo per usare i soldi che ci sono dentro.`
}