import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-03-SL-01-HOT-04',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: 'Come funzionano i pagamenti con le carte?',
  paragraph: `I ragazzi stanno cercando di capire come funzionano i pagamenti con le carte. Sai indovinare quali di queste affermazioni sono vere e quali false?`,

  label: 'VERO?',

  items: [
    {
      term: 'La carta di debito consente al possessore di effettuare pagamenti solo se dispone di fondi sul conto al momento della transazione.',
      sum_cond_value: true
    },
    {
      term: 'La carta di debito consente sempre al possessore di effettuare pagamenti anche se non dispone di fondi sul conto al momento della transazione.',
      sum_cond_value: false
    },
    {
      term: 'La carta di credito consente al possessore di effettuare pagamenti solo se dispone di fondi sul conto al momento della transazione.',
      sum_cond_value: false
    },
    {
      term: 'La carta di credito consente al possessore di effettuare pagamenti anche se non dispone di fondi sul conto al momento della transazione.',
      sum_cond_value: true
    },
    {
      term: 'Le carte prepagate possono essere utilizzate anche oltre l’importo pre-caricato. I soldi mancanti verranno prelevati dal conto collegato alla carta',
      sum_cond_value: false
    },
    {
      term: 'Le carte ibanizzate si chiamano così perché possono essere ricaricate attraverso un bonifico da qualunque conto',
      sum_cond_value: false
    }
  ],

  points: 1,

  feedback: {
    correct: `Perfetto, ti intendi di carte di pagamento e sai interpretare ogni clausola (hai indovinato tutte le risposte).`,
    wrong: `Attenzione... Prima di procurarti una carta, informati bene ({total} risposta/e errate).`
  }
}