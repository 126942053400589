import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/130.png',

  heading: `E se qualcun altro ha comprato il libro con i miei soldi?`,
  paragraph: `A volte ci accorgiamo che sul nostro conto risulta un’operazione di pagamento che non ci ricordiamo di aver fatto.... Intanto ricordiamoci di controllare sempre con attenzione  le entrate e le uscite del nostro conto! E che fare se ci accorgiamo di una spesa sospetta? In primo luogo è bene verificare che effettivamente non siamo stati noi a fare quel pagamento…potremmo aver comprato qualcosa e essercene dimenticati. Ma se invece siamo proprio sicuri, allora dobbiamo subito informare la banca che ci dirà cosa fare!`
}