import slide1 from './slides/01/SECT-02–P-03-SL-01';
import slide2 from './slides/02/SECT-02–P-03-SL-02';
import slide3 from './slides/03/SECT-02–P-03-SL-03';

export default {
  id: 'SECT-02–P-03',

  first_page: false,
  base_path: '/scuole/media/sveglia-ragazzi',

  name: `Sveglia ragazzi!`,

  icon: '/images/section-02/icons/page-03.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/120_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Sveglia ragazzi!`,
    paragraph: `Omar, Nina, Isotta e Teo vogliono iscriversi a una grande gara di hip hop. Ma il sito www.ballaleggero.bah chiede una quota d’iscrizione piuttosto alta e vuole avere tutti i dati bancari e personali dei genitori. Sarà forse una truffa?`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};