import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-03_P-04-SL-01-HOT-01';

export default {
  id: 'SECT-03_P-04-SL-01',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/330.png',
        from: '',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Non mangiamoci tutto...`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1
  ],

  extra_class: ''
};