import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/118.png',

  heading: `Chi decide il prezzo`,
  paragraph: `Il prezzo delle merci e dei servizi dipende da quanto è disposto a pagare chi compra e da quanto vuole ricevere chi vende. L’acquisto avviene se il prezzo è accettabile per entrambi. Molte cose possono influenzarlo: innanzitutto i costi che deve affrontare chi offre il bene o servizio. Se Omar al bar ordina una spremuta di arance, il prezzo dipende da quanto il barista ha pagato le arance, l’affitto del locale, lo spremiagrumi, la corrente per farlo funzionare... Deve rifarsi di tutte le spese e anche un po’guadagnarci! Inoltre il prezzo dipende anche dalla domanda e dall’offerta: le arance costano di meno quando sono di stagione, di più se sono una primizia o il raccolto è scarso. E se tutti ne vogliono il prezzo sale, mentre se la gente ne compra poche verranno offerte a prezzo più basso per invogliare i clienti. Così i prezzi cambiano anche in base ai momenti, le stagioni, le mode. Inoltre, la domanda stimola l’offerta: se al mercato tutti vogliono pomodori e pochi gli spinaci, i coltivatori pianteranno più pomodori e meno spinaci. Il tempo che crescano e… l’offerta di pomodori salirà mentre il loro prezzo scenderà!`
}