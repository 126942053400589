import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-04-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/137.png',

  heading: `Conto sulla banca`,
  paragraph: `Ci sono tanti “prodotti finanziari” che si possono acquistare per investire i propri risparmi. Vediamo i più conosciuti.

  Obbligazioni: titoli emessi da banche, imprese o Stati per ottenere soldi in prestito, garantendo in cambio interessi, a tasso fisso o variabile. In inglese si chiamano bond. Le obbligazioni emesse dagli stati si chiamano Titoli di Stato,  come per esempio i BOT (Buoni Ordinari del Tesoro) e BTP (Buoni del Tesoro Poliennali).

  Azioni: rappresentano le quote di proprietà di una società e chi compra le azioni ne diventa socio, cioè proprietario di un “pezzetto”. Se le cose vanno bene e l’azienda consegue profitti, l’azionista ne riceve una parte in proporzione al pezzetto di sua proprietà, sotto forma di dividendi. A seconda di come vanno gli affari dell’impresa, il valore delle azioni sale o scende: quando il socio le rivenderà potrebbe guadagnarci o rimetterci. Alcune società sono quotate in Borsa: le loro azioni si comprano e si vendono in un mercato regolamentato.`
}