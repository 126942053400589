import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/338.png',

  heading: `Dalla sussistenza all’abbondanza`,
  paragraph: `Fino a tempi recenti, la maggior parte degli uomini ha avuto a malapena ciò che bastava per sopravvivere. Ancora oggi è così in tanti paesi. Ma sono sempre più numerose le persone che, nelle aree economicamente più stabili, hanno una situazione che permette loro di avere ben più del necessario. Questo non significa tuttavia che possano sempre permettersi tutto quello che vorrebbero. Anche perché bisogni e reddito variano nel tempo, spesso in maniera diversa: solitamente il reddito cresce grazie all’esperienza e alle capacità acquisite nel tempo e quindi possono volerci molti anni. I bisogni, invece, spesso si manifestano quando siamo ancora molto giovani, agli inizi della nostra carriera e con uno stipendio o un'altra tipologia di reddito ancora basso...

  Se non abbiamo un reddito o risparmi sufficienti per una certa spesa, dobbiamo rinunciare per forza? No, possiamo accedere a un finanziamento: ci facciamo anticipare i soldi e li restituiremo nel tempo, con gli interessi. Indebitarsi per anticipare i consumi è quindi una importante opportunità, ma è una decisione che va presa con molta attenzione. Inoltre non è scontato che sia possibile trovare qualcuno disposto a farci credito.`
}