import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/339.png',

  heading: `Gli impegni vanno onorati`,
  paragraph: `Se il risparmio è mettere da parte denaro che potremo spendere nel futuro, ottenendo così un interesse, il credito è esattamente l’opposto: spendere oggi soldi che dovremo restituire, spesso attraverso numerose rate, nei mesi, anni o addirittura decenni futuri. È quindi necessario fare bene i conti per capire se saremo in grado di restituire il prestito.

  Abbiamo oggi un reddito che ci permette di restituire i soldi presi a prestito? E soprattutto: il nostro reddito è stabile? Sarà sufficiente a pagare anche in futuro le rate secondo il piano concordato e a coprire le necessità della vita quotidiana come i generi alimentari, le bollette o le rate di altri prestiti che ci siamo già impegnati a pagare? Avremo un margine per eventuali imprevisti?`
}