import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/319.png',

  heading: `I prezzi cambiano `,
  paragraph: `Il prezzo delle merci e dei servizi dipende da molti fattori ed è il risultato dell’incrocio tra la domanda e l’offerta di quel bene: in altri termini il prezzo finale di ciascun bene è il punto di equilibrio a cui sia il venditore che il compratore sono disposti a realizzare la transazione. La domanda e l’offerta di un determinato bene cambiano in base a numerosi parametri: il luogo in cui ci troviamo, il periodo dell’anno e così via.

  Conseguentemente il prezzo di un bene cambierà nel corso del tempo e tra diversi luoghi. Anche le valute hanno un prezzo, ce ne accorgiamo ad esempio quando viaggiamo o facciamo acquisti su internet e abbiamo l’esigenza di utilizzare valute diverse dall’euro. Anche in questo caso il prezzo a cui una moneta può essere comprata con un’altra è stabilito in un mercato, il mercato valutario. Come in ogni mercato, sono la domanda e l’offerta a determinare il prezzo: se in molti vogliono acquistare una certa valuta, il suo prezzo salirà (a parità di quantità offerta). Accadrà il contrario se sono poche le persone che vogliono acquistarla.`
}