import slide1 from './slides/01/section-01-page-04-slide-01';
import slide2 from './slides/02/section-01-page-04-slide-02';
import slide3 from './slides/03/section-01-page-04-slide-03';

export default {
  id: 'SECT-01_P-04',
  
  first_page: false,
  base_path: '/scuole/elementare/maialini-cani-spendaccioni',

  name: `Maialini e cani spendaccioni`,

  icon: '/images/section-01/icons/page-04.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/028_0004_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      },
      {
        image: '/images/section-01/layers/animate/028_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-01/layers/animate/028_0003_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-01/layers/animate/028_0000_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/028_0002_5.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      }
    ]
  },

  page_content: {
    heading: `Maialini e cani spendaccioni`,
    paragraph: `Attila è un cane sveglio ma i conti proprio non sono il suo forte, vuole risparmiare per comprarsi una nuova cuccia, gli diamo una mano?`
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};