import slide1 from './slides/01/section-03-page-01-slide-01';
import slide2 from './slides/02/section-03-page-01-slide-02';
import slide3 from './slides/03/section-03-page-01-slide-03';

export default {
  id: 'SECT-03_P-01',

  first_page: true,
  base_path: '/scuole/superiore/reddito',

  name: `Reddito e pianificazione`,

  icon: '/images/section-03/icons/page-01.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/303.png',
        from: '',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Reddito e pianificazione`,
    paragraph: `Omar è rimasto a secco: niente benzina nello scooter... E niente soldi nel portafogli. Ma com’è potuto succedere? Dov’è finita tutta la sua paghetta?`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};