import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/147.png',

  heading: `Prendi carta e colori e disegna la tua banconota!`,
  paragraph: `Prova a disegnare una banconota tua, ispirandoti a una vera. Esaminane una in euro e guarda quali sono secondo te gli elementi essenziali: il nome della valuta, il valore, il simbolo della Banca Centrale Europea (BCE) e la firma del Governatore, il disegno e i simboli anti-contraffazione, come il numero di serie. Hai notato che sul fronte di tutte le banconote in euro sono raffigurati finestre e portali? E sul retro, ponti? Sai cosa rappresentano? Finestre e portali sono il simbolo dello spirito di apertura e collaborazione che anima l’Europa, mentre i ponti sono una metafora del dialogo fra i popoli europei, nonché fra l’Europa e il resto del mondo. Ora inventa una nuova banconota del valore che vuoi e decorala come preferisci, ma assicurati che abbia anche tutti questi elementi.


  Cosa ci hai disegnato sopra? Pensa ora a cosa metteresti su tutti i diversi tagli, su quella da 5 €, su quella da 10 €….
  Sai che la Banca d’Italia organizza annualmente per le scuole il concorso a premi “Inventiamo una banconota” per chi disegna la banconota più bella? Cerca su internet come far partecipare la tua scuola.?`
}