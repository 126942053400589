import slide1 from './slides/01/SECT-02–P-04-SL-01';
import slide2 from './slides/02/SECT-02–P-04-SL-02';
import slide3 from './slides/03/SECT-02–P-04-SL-03';

export default {
  id: 'SECT-02–P-04',

  first_page: false,
  base_path: '/scuole/media/risparmio',

  name: `Il famoso momento risparmio`,

  icon: '/images/section-02/icons/page-04.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/131_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Il famoso momento risparmio`,
    paragraph: `Omar, Nina e Isotta guardano Teo che sogna a occhi aperti: sdraiato sul divano con la sua paghetta in mano immagina di comprare in futuro un drone, un motorino, un castello…`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};