import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-03_P-03-SL-01-HOT-01';
import hotspot2 from './hotspots/SECT-03_P-03-SL-01-HOT-02';
import hotspot3 from './hotspots/SECT-03_P-03-SL-01-HOT-03';
import hotspot4 from './hotspots/SECT-03_P-03-SL-01-HOT-04';

export default {
  id: 'SECT-03_P-03-SL-01',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/321.png',
        from: '',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Il sistema dei pagamenti`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3,
    hotspot4
  ],

  extra_class: ''
};