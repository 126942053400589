import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/008.png',

  heading: `Stipendio, onorario o profitto?`,
  paragraph: `Per la maggior parte delle persone, il reddito è quello che proviene dal lavoro: lo **stipendio** se si è lavoratori dipendenti, come un cameriere, l'**onorario** se si è liberi professionisti, come un avvocato, il **profitto** se si svolge un'attività imprenditoriale, come chi ha un negozio.`
}