import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/345.png',

  heading: `Tirando le somme`,
  paragraph: `Come abbiamo visto, bisogna essere responsabili con i propri soldi. Distinguiamo tra bisogni e desideri, dando le giuste priorità. Se facciamo un debito, dobbiamo essere consci dell’impegno che assumiamo. Valutiamo prima entrate e uscite per capire quanto possiamo permetterci di spendere, e dunque se dobbiamo rinunciare a qualcosa o farci finanziare.

  Impariamo a fare i conti e prendiamo l’abitudine di controllarli spesso. Vivremo il presente con più serenità ed eviteremo brutte sorprese per il futuro. Ne vale la pena...`
}