import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: 'A ognuno la sua',
  paragraph: `Come abbiamo visto, ogni moneta ha due facce. Per le monete in euro, la prima è uguale in tutta Europa: si chiama faccia comune. C'è scritto sopra quanto vale, un numero, e poi c'è la carta geografica dell'Europa o il globo terrestre, che simboleggiano la coesione dei paesi.

  Dietro c'è la faccia nazionale, e ogni Paese ha messo delle immagini che lo rappresentano. Sulle monete italiane ci sono opere ed edifici veramente famosi! Clicca sulle monete e scopri quali sono invece quelle di altri paesi.`
}