import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `L'origine delle parole "salario" e "pecunia".`,
  paragraph: `La parola ‘salario’ viene dal sale e ‘pecunia’ da pecora... Ma cosa c’entrano sale e pecore con i soldi?`
}