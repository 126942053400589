import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-01-SL-03-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-01-SL-03-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-01-SL-03-HOT-03';

export default {
  id: 'SECT-02–P-01-SL-03',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/109_0001_1.png',
        animation: 'playful',
        from: 'left',
        speed: 'slow',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/109_0000_2.png',
        animation: 'playful',
        from: 'top',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Costruiamoci il budget`,
    paragraph: `Basteranno questi soldi per tutto quello che voglio fare? Me lo dirà il budget.`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};