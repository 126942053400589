import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/112.png',

  heading: `Dalla pecora all’euro`,
  paragraph: `Se Omar dà un libro giallo a Nina in cambio di un panino al salame, questo “affare” si chiama baratto: ma perché possa avvenire occorre che due persone vogliano dare via proprio ciò che desidera l’altro, cioè ci sia una doppia coincidenza dei bisogni.

  Per rendere gli scambi più facili, da migliaia di anni l’uomo utilizza la moneta. Per molto tempo si trattò di monete-merce: a seconda dei luoghi e delle epoche, poteva trattarsi di pecore, sale, conchiglie, semi... tra le monete-merce c’era anche il metallo, che all’inizio veniva fuso in barre o spiedi. Intorno al VI secolo a.C. si iniziò a usarlo per fare le monete vere e proprie, con un valore garantito dallo Stato che poteva non coincidere precisamente con quello del metallo. I cinesi hanno poi inventato le banconote, Marco Polo le ha descritte ne Il Milione, che successivamente si sono diffuse anche in Europa.`
}