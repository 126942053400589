import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02_P-01-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/106.png',

  heading: `Da dove arrivano i soldi`,
  paragraph: `Alcuni di voi ricevono una paghetta dai propri familiari, ma loro come fanno a guadagnare il loro reddito? Dipende dal tipo di reddito:

  Il reddito da lavoro dipendente si ottiene lavorando per qualcuno e ricevendo uno stipendio.
  Il reddito da lavoro autonomo si ottiene svolgendo una libera professione come elettricista, avvocato, medico, artista...
  I redditi d’impresa sono ottenuti attraverso le attività della propria azienda come un negozio o una ditta edile...
  I redditi da capitale si ottengono investendo i soldi che si hanno facendoli fruttare.
  I redditi fondiari dall’affitto o sfruttamento di edifici e terreni.

  Tutti i redditi che non rientrano tra quelli fin qui elencati sono anche chiamati redditi diversi... come ad esempio un’eredità.`
}