import React from 'react';

export default class AnimatedIllustration extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getLayerSpeed = (layer, i) => {
    const speeds = [
      'fastest',
      'fast',
      'slow',
      'slowest'
    ];

    return layer.speed ? layer.speed : (speeds[i] !== undefined ? speeds[i] : 'fast');
  }

  getLayerAnimation = (layer, i, animationType='') => {
    let animationClassName = '';

    switch (animationType) {
      case 'simple':
        animationClassName = 'fadeInDown';
        break;
      case 'playful':
        const animations = {
          top: 'fromTop',
          left: 'fromLeft',
          right: 'fromRight'
        };
        const defaultAnimation = animations['left'];

        animationClassName = layer.from && animations[layer.from] ? animations[layer.from] : defaultAnimation;
    }

    return animationClassName;
  }

  render() {
    let { layers, animationType } = this.props;

    layers.sort((a, b) => (a.stack > b.stack) ? 1 : -1).reverse(); // sort layers by stack. Lower stacks means higher z-index

    const animations = layers.map((layer, i) => {
      return (
        <div className={`bankitalia-kids__animated ${this.getLayerAnimation(layer, i, animationType)}`}>
          <img className={`bankitalia-kids__frame bankitalia-kids__frame--${this.getLayerSpeed(layer, i)}`} src={layer.image} />
        </div>
      );
    });

    return (
      <>
        <div className="bankitalia-kids bankitalia-kids__layer-wrapper">
          <div className="bankitalia-kids__main-layer">
            { animations }
          </div>
          {
            animationType != 'simple' && (
              <>
                <div className="bankitalia-kids bankitalia-kids__body-shadow">
                { animations }
                </div>
                <div className="bankitalia-kids bankitalia-kids__floor-shadow">
                  { animations }
                </div>
              </>
            )
          }
        </div>
      </>
    );
  }
}