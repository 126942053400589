import React from 'react';
import { Link } from 'react-router-dom';

import Settings from '../config/Settings';

import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';

import BANKITALIA from '../BANKITALIA';

class StudentSectionSummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null
    };
  }

  componentDidMount = () => {
    (function($) {
      $(document).ready(function() {
        BANKITALIA.init();
      });
    })(window.jQuery);
  }

  render() {
    const { section } = this.props;

    return (
      <div className={Settings.pageFXClass + ' ' + section.palette}>
        <div className="bankitalia-app bankitalia-app--school">
          <Header>
            <Navbar />
          </Header>

          <div className="container bankitalia-main">
            <div className="row bankitalia-main__row bankitalia-main__row--info">
              <div className="col">
                <strong className="bankitalia-title-h2">{section.name}</strong>
                <h2 className="bankitalia-title">Scegli l'argomento che più ti piace</h2>
                <p>Troverai tante attività per giocare e scoprire tutti i segreti del denaro e del risparmio.</p>
              </div>
            </div>

            <div className="row bankitalia-main__row bankitalia-main__row--chapters">
              <div className="col">
                <ul className="bankitalia-chapters">
                  {
                    section.pages.map((page, i) => (
                      <li className="bankitalia-chapters__item magictime puffIn">
                        <Link className="bankitalia-chapter" to={`${page.base_path}/inizio/`}>
                          <div className="bankitalia-chapter__inner">
                            <figure className="bankitalia-chapter__figure">
                              <img src={page.icon} />
                            </figure>
                            <p className="bankitalia-chapter__name">{i+1}. {page.name}</p>
                          </div>
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>

          <footer className="container bankitalia-footer">
            <div className="row bankitalia-footer__row">
              <div className="col-sm-6 bankitalia-footer__col bankitalia-footer__col--logo">
                <Link className="bankitalia-logo" to="/">
                  <img src="/images/shared/bankitalia-logo.png" />
                </Link>
              </div>
              <div className="col-sm-6 bankitalia-footer__col bankitalia-footer__col--copyright">
                <p className="bankitalia-copyright">&copy; Copyright 2021 Banca d’Italia</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default StudentSectionSummaryPage;