
import React from 'react';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

import HotspotModal from './HotspotModal';

import BANKITALIA from '../../../BANKITALIA';

export default class CustomModalPIN extends React.PureComponent {
  constructor(props) {
    super(props);

    this.CORRECT_NUMBER = 1;
    this.MAX_ATTEMPTS = 3;

    this.state = {
      modalOpen: false,

      numAttempts: 0,
      number: '',

      submitted: false,
      correct: false
    };
  }

  componentDidMount() {
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });

    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  handleSubmit = () => {
    const { hotspot } = this.props;

    const numAttempts = this.state.numAttempts + 1;
    const noAttemptsLeft = numAttempts == this.MAX_ATTEMPTS;
    const isCorrect = this.state.number == this.CORRECT_NUMBER;

    if (isCorrect) {
   const sectionId = hotspot.id.split('_')[0];
      const storeKey = `bankitalia:score:${sectionId}`;



      window.localStorage.setItem(storeKey, Number(window.localStorage.getItem(storeKey)) + 1);
    }

    this.setState({
      number: isCorrect ? this.state.number : '', // only reset if wrong answer

      numAttempts: numAttempts,
      correct: isCorrect,

      submitted: noAttemptsLeft || isCorrect
    });
  }

  handleChange = (event) => {
    const number = event.currentTarget.value
    if (isNaN(number)) {
      return;
    }

    this.setState({
      number
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });

    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    if (!hotspot) {
      return '';
    }

    const numAttemptsLeft = this.MAX_ATTEMPTS - this.state.numAttempts;

    return (
      <>
        <div className={`HOTSPOT-DISCOVER bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal className="" open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="bankitalia-quiz">
              <h2 className="bankitalia-quiz__question">La memoria fa brutti scherzi</h2>
              <div className="bankitalia-quiz__desc">
                <p>Il Pin è un codice che va imparato a memoria, è bene non scriverlo su un foglietto o metterlo nel portafoglio perché potremmo perderlo e non potremmo più usare la carta o peggio qualcun altro potrebbe usarla al posto nostro. Ma quanto è importante? In fondo sono solo 5 cifre, quanto ci vorrà a indovinare il codice corretto se ce ne siamo dimenticati? Nina si ricorda solo 4 cifre su 5, ce la farete a scoprire il Pin completo?</p>
              </div>
              <div className="bankitalia-quiz__actions bankitalia-quiz__actions--pin">
                <div className="bankitalia-pin">
                  <div className="bankitalia-pin__digit">9</div>
                  <div className="bankitalia-pin__digit">0</div>
                  <div className="bankitalia-pin__digit">2</div>
                  <input className="bankitalia-pin__input" placeholder="&bull;" type="text" maxLength="1" value={this.state.submitted ? 'X' : this.state.number} onChange={this.handleChange} />
                  <div className="bankitalia-pin__digit">0</div>
                </div>

                  {/* Submit button */}
                  <div className="bankitalia-quiz__actions">
                    <button className={`bankitalia-btn ${(this.state.number === '' || this.state.submitted) && 'disabled'}`} type="button" onClick={this.handleSubmit}>
                      <span className="bankitalia-btn__label">invia</span>
                    </button>
                  </div>
              </div>
              {
                this.state.numAttempts > 0 && (
                  <div className="bankitalia-quiz__message">
                    <div className={`bankitalia-message ${this.state.correct ? 'bankitalia-message--success' : 'bankitalia-message--error'}`}>
                      {
                        !this.state.correct ? (
                          <p>
                            Risposta errata!
                            { !this.state.submitted && <><br/>Non è questo, possiamo riprovare {numAttemptsLeft > 1 ? ` (${numAttemptsLeft} tentativi rimasti).` : '. Solo 1 tentativo rimasto!'}</> }
                            { this.state.submitted && <><br/>La tua carta è stata bloccata dopo {this.MAX_ATTEMPTS} tentativi.</> }
                          </p>
                        ) : (
                          <p>
                            Risposta esatta! <br />
                            Hai vinto 1 punto.
                          </p>
                        )
                      }

                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}