import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/126.png',

  heading: `Numeri, codici e altri segreti`,
  paragraph: `La sicurezza dei pagamenti elettronici è garantita non solo dalle banche ma anche dai nostri comportamenti. Le banche ci forniscono strumenti che permettono solo a noi di utilizzare i nostri soldi. Ma noi dobbiamo custodire con cura questi strumenti perchè nessuno li possa utilizzare al nostro posto. Ad esempio, per ritirare i soldi presso un ATM con una carta serve il PIN, un codice segreto di 4 o 5 cifre che la banca comunica solo a noi. Non va dato a nessuno e bisogna anche fare attenzione che nessuno ci spii mentre lo digitiamo.`
}