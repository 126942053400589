import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_SORT,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Isotta vuole comprare su Internet un paio di scarpe. Ne ha trovati quattro: tu sai metterli in ordine da quello meno costoso al più caro, controllando il cambio del giorno?

  Cambio del giorno:
  1 Euro = 4 Zloty polacchi
  1 Euro = 80 Rubli russi
  1 Euro = 25 Corone ceche
  1 Euro = 8 Yuan cinesi`,

  items: [
    {
      text: `240 Yuan cinesi`,
    },
    {
      text: `3.200 Rubli russi`,
    },
    {
      text: `200 Złoty polacchi`,
    },
    {
      text: `1.500 Corone ceche`,
    },
  ],

  points: 1,

  feedback: {
    correct: `Esattamente, complimenti! Lo shopping online non ha segreti per te.`,
    wrong: `Mi spiace, sbagliato. Prova a convertire tutti i prezzi in euro: ai cambi di quel giorno 3.200 rubli sono 40 €, 240 yuan sono 30 €, 1.500 corone sono 60 € e 100 zloty sono 50 €.
    E quando valuti la convenienza di un acquisto all’estero considera che potresti dovere pagare la dogana….`
  }
}