import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';

import HotspotModal from './HotspotModal';

export default class InterestCalculatorModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.t = null;

    this.state = {
      modalOpen: false,

      amounts: {
        loan: 0,
        interest: 0
      },
      result: 0,

      moneyLeft: 0,
      returns: 0,

      submitted: false
    };
  }

  componentDidMount() {
    this.setCurrValue('loan', 0);
    window.setTimeout(() => {
      this.setCurrValue('interest', 4);
    }, 20);
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });

    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  increaseValue = (type) => {
    const nextValue = this.state.amounts[type] + 1;

    this.setCurrValue(type, nextValue);
  }
  decreaseValue = (type) => {
    const nextValue = this.state.amounts[type] - 1;

    this.setCurrValue(type, nextValue);
  }
  setCurrValue = (type, value) => {
    const { hotspot } = this.props;

    window.clearTimeout(this.t);

    value = value === '' ? '' : (!value ? 0 : parseInt(value));
    if (value < 0) {
      value = 0;
    }

    let amounts = { ...this.state.amounts };
    amounts[type] = value;

    this.setState({
      submitted: false, // reset

      amounts,

      result: amounts.loan * amounts.interest / 100
    });

    this.t = window.setTimeout(() => {
      this.setState({
        submitted: true
      });
    }, 1000);
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });

    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    return (
      <>
        <div className={`HOTSPOT-INT-CALC bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 bankitalia-modal__col bankitalia-modal__col--question">
              <div className="bankitalia-quiz">
                <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
                <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

                <div className="bankitalia-quiz__fields">
                  <div className="bankitalia-quiz__field">
                    <strong class="bankitalia-quiz__desc">Prestito:</strong><br />
                    <div className="bankitalia-quiz__actions bankitalia-quiz__actions--amount" style={{zoom: 0.8, margin: 0}}>
                      <div className="bankitalia-amount">
                        <strong className="bankitalia-amount__currency">€</strong>
                        <input className="bankitalia-amount__input" type="text" value={this.state.amounts.loan} data-min="0" data-max={1000} onChange={(e) => this.setCurrValue('loan', e.currentTarget.value)} />
                        <div className="bankitalia-amount__controls">
                          <button className="bankitalia-amount__increase" type="button" onClick={(e) => this.increaseValue('loan')}>
                            <img src="/images/icons/arrow-up.png" srcset="/images/icons/arrow-up.png, /images/icons/arrow-up-2x.png 2x" title="Close menu" alt="Close" />
                          </button>
                          <button className="bankitalia-amount__decrease" type="button" onClick={(e) => this.decreaseValue('loan')}>
                            <img src="/images/icons/arrow-down.png" srcset="/images/icons/arrow-down.png, /images/icons/arrow-down-2x.png 2x" title="Close menu" alt="Close" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bankitalia-quiz__field">
                    <strong class="bankitalia-quiz__desc">Interesse:</strong><br />
                    <div className="bankitalia-quiz__actions bankitalia-quiz__actions--amount" style={{zoom: 0.8, margin: 0}}>
                      <div className="bankitalia-amount">
                        <strong className="bankitalia-amount__currency">%</strong>
                        <input className="bankitalia-amount__input" type="text" value={this.state.amounts.interest} data-min="0" data-max={100} onChange={(e) => this.setCurrValue('interest', e.currentTarget.value)} />
                        <div className="bankitalia-amount__controls">
                          <button className="bankitalia-amount__increase" type="button" onClick={(e) => this.increaseValue('interest')}>
                            <img src="/images/icons/arrow-up.png" srcset="/images/icons/arrow-up.png, /images/icons/arrow-up-2x.png 2x" title="Close menu" alt="Close" />
                          </button>
                          <button className="bankitalia-amount__decrease" type="button" onClick={(e) => this.decreaseValue('interest')}>
                            <img src="/images/icons/arrow-down.png" srcset="/images/icons/arrow-down.png, /images/icons/arrow-down-2x.png 2x" title="Close menu" alt="Close" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  this.state.result && (
                    <div className="bankitalia-quiz__message">
                      <div className={`bankitalia-message bankitalia-message--${this.state.amounts.interest <= hotspot.max_rate ? 'success' : 'error'}`}>
                        <p>
                          Interessi da pagare = { this.state.submitted ? <>{this.state.result} €{this.state.amounts.interest > hotspot.max_rate && '!'}</> : <>🤔...</> }
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}