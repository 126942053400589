import slide1 from './slides/01/section-03-page-03-slide-01';
import slide2 from './slides/02/section-03-page-03-slide-02';

export default {
  id: 'SECT-03_P-03',

  first_page: false,
  base_path: '/scuole/superiore/pagamenti',

  name: `Pagamenti e acquisti`,

  icon: '/images/section-03/icons/page-03.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/320.png',
        from: '',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Pagamenti e acquisti`,
    paragraph: `Teo vuole assolutamente comprare un cellulare online: ha visto un sito con l’ultimo modello a un prezzo davvero stracciato. Ma come pagarlo? E ci sarà da fidarsi?`,
  },

  slides: [
    slide1,
    slide2
  ]
};