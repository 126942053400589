import React from 'react';
import ReactDOM from 'react-dom';

import MarkdownParser from '../../../components/shared/MarkdownParser';

class InfoPanelHotspot extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };
  }

  handleClose = () => {
    this.props.onClose();
    document.getElementsByTagName('body')[0].classList.remove('scroll-lock')
  }

  render() {
    const { hotspot, pos, highlighted } = this.props;

    const portalNode = document.getElementById('bankitalia-react-portal');
    if (!portalNode) {
      return '<p>NO PORTAL</p>';
    }

    if (this.props.active) document.getElementsByTagName('body')[0].classList.add('scroll-lock');

    return ReactDOM.createPortal(
      <div className={`bankitalia-info-panel ${this.props.active && 'bankitalia-info-panel--active'} ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `}>
        <div className="bankitalia-info-panel__body">
          <div className="bankitalia-article">
            <figure className="bankitalia-article__figure">
              <img width="100%" src={hotspot.featured_image} />
            </figure>

            <strong className="bankitalia-article__title">{hotspot.heading}</strong>
            <div className="bankitalia-article__txt">
              <MarkdownParser source={String(hotspot.paragraph).replace(/\n/gi, '  \n')} />
            </div>
          </div>

          <button className="bankitalia-navigation-close" type="button">
            <img src="/images/icons/close.png" onClick={this.handleClose} />
          </button>
        </div>
      </div>,
      portalNode
    );
  }
}

export default InfoPanelHotspot;