import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: 'Reddito variabile o fisso?',
  paragraph: `Alcuni redditi sono fissi, cioè pressoché sempre uguali, altri invece cambiano di volta in volta. Li sai riconoscere?`,

  label: 'VARIABILE?',

  items: [
    {
      term: 'L’incasso mensile dello zio di Omar, che ha una pasticceria.',
      sum_cond_value: true
    },
    {
      term: 'Lo stipendio di una cugina grande di Nina e Isotta, tenente dell’Esercito.',
      sum_cond_value: false
    },
    {
      term: 'L’affitto che riceve il proprietario dell’appartamento in cui vive la famiglia di Teo.',
      sum_cond_value: false
    },
    {
      term: 'I guadagni del padre di Omar, che fa l’idraulico e lavora in proprio.',
      sum_cond_value: true
    },
  ],

  points: 1,

  feedback: {
    correct: `Bravo! Hai {total} risposte giuste!`,
    wrong: `Ops, non è andata bene… Ricorda: stipendi e affitti sono redditi fissi, mentre gli incassi dei negozi e i redditi dei lavoratori autonomi variano, anche molto, a seconda della quantità dei clienti e di quello che questi chiedono.`
  }
}