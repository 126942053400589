import React from 'react';
import InfoPanelHotspotPanel from './InfoPanelHotspotPanel';

class InfoPanelHotspot extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };
  }

  handleClick = (e) => {
    e.stopPropagation();

    const { hotspot, pos, enabled } = this.props;

    if (enabled) {
      this.handleOpen();
      this.props.onClick(e);
    }
  }

  handleOpen = () => {
    this.setState({
      active: true
    });
  }

  handleClose = () => {
    this.setState({
      active: false
    }, () => {
      this.props.onClose();
    });
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    return (
      <>
        <div className={`HOTSPOT-INFO-PANEL bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
          <div className="bankitalia-circle__info-label magictime tinUpIn"><span>INFO</span></div>
        </div>

        {
          <InfoPanelHotspotPanel hotspot={hotspot} active={this.state.active} onClose={this.handleClose} />
        }
      </>
    );
  }
}

export default InfoPanelHotspot;