import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-04-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Cosa vuol dire?`,
  paragraph: `Investire il proprio denaro significa provare a farlo aumentare. Come? Esistono vari modi, alcuni più rischiosi degli altri. Proviamo a immaginare di essere diventati già grandi e di dover scegliere come investire i nostri risparmi, pronti?`
}