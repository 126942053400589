import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Un po’ di equilibrio`,
  paragraph: `In un buon budget le uscite non devono essere superiori alle entrate. Può anche succedere in un certo momento, ma nell’arco di tutto il periodo è necessario avere entrate maggiori e risparmiare per il futuro. Inoltre il budget ci può dire se in qualche momento non abbiamo abbastanza soldi in tasca per fare quello che vogliamo, come nei conti di Nina. E quando succede, che cosa possiamo fare? Ci sono varie possibilità...

  1) aumentare le entrate - Nina potrebbe vendere qualche vecchio giocattolo o una maglietta che non usa più al mercatino dell’usato.

  2) diminuire le uscite - Nina potrebbe, per una volta, portarsi una mela da casa o saltare il cinema.

  3) rimandare qualche acquisto - Nina potrebbe comprare il braccialetto di sabato anziché di mercoledì.

  4) ricorrere a un prestito – Nina potrebbe chiedere a Isotta di anticiparle qualche euro… ma poi dovrà restituirle tutto quanto, anche con gli interessi.

  È importante sapere che se per Nina in questo momento può essere facile, non è detto che lo sarà anche in futuro. Diminuire le uscite può essere difficile, e aumentare le entrate anche di più.`
}