import React from 'react';
import { Link } from 'react-router-dom';

import Settings from '../../config/Settings';

class Navbar extends React.PureComponent {
  render() {
    return (
      <>
        <nav className="col-md-auto bankitalia-header__col bankitalia-header__col--nav">
          <ul className="bankitalia-navigation">
              {
                Settings.sections.map((section) => {
                  const label = section.name.toUpperCase().replace('SCUOLA', '').trim();

                  return (
                    <li className="bankitalia-navigation__item">
                      <Link to={`${section.base_path}/sommario/`} className={String(window.location.pathname).indexOf(section.base_path) !== -1 ? 'active' : ''} key={`link-${section.name}`}><span>SCUOLA</span> {label}</Link>
                    </li>
                  );
                })
              }

              <li className="bankitalia-navigation__item">
                <Link to="/insegnanti/" className={String(window.location.pathname).indexOf('/insegnanti') !== -1 ? 'active' : ''}>INSEGNANTI</Link>
              </li>
              <li className="bankitalia-navigation__item">
                <Link to="/info/" className={String(window.location.pathname).indexOf('/info') !== -1 ? 'active' : ''}>INFO</Link>
              </li>
          </ul>

          <button className="bankitalia-navigation-close" type="button">
            <img src="/images/icons/close.png" srcset="/images/icons/close.png, /images/icons/close2x.png 2x" title="Close menu" alt="Close" />
          </button>
        </nav>

        <div className="col bankitalia-header__col bankitalia-header__col--hamburger">
          <button className="bankitalia-hamburger" type="submit">
            <img src="/images/icons/hamburger.png" srcset="/images/icons/hamburger.png, /images/icons/hamburger2x.png 2x" title="Open menu" alt="Hamburger" />
          </button>
        </div>
      </>
    );
  }
}

export default Navbar;