import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: '',
  paragraph: `Omar chiede un prestito a Isotta per la pizza di sabato sera. Lei valuta i motivi se fidarsi o meno a prestare i suoi soldi a Omar... Sai aiutarla a riconoscere quelli importanti?`,

  label: 'IMPORTANTE?',

  items: [
    {
      term: 'Omar ha una collezione di fumetti che può rivendere con facilità ma non se ne separerebbe mai.',
      sum_cond_value: true
    },
    {
      term: 'Omar ama i cani ed è sempre molto gentile con Attila.',
      sum_cond_value: false
    },
    {
      term: 'Se Omar non restituirà nulla entro una settimana, Teo garantisce che penserà lui a ripagare Isotta',
      sum_cond_value: true
    },
    {
      term: 'Omar riceve tutte le settimane una bella paghetta dai suoi genitori.',
      sum_cond_value: true
    },
    {
      term: 'Omar deve già restituire a Teo un prestito avuto ieri per cinema e pop-corn.',
      sum_cond_value: false
    }
  ],

  points: 1,

  feedback: {
    correct: `Ottimo, hai la stoffa giusta per fare il banchiere!`,
    wrong: `Purtroppo non ci siamo, riconsidera meglio ciò di cui fidarsi!`
  }
}