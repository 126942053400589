import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_SORT,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Riordina le scelte dalla migliore alla peggiore. Ogni mese, il cugino grande di Omar, che già lavora, dovrebbe cercare di spendere...`,

  items: [
    {
      text: `Meno di quello che guadagna, risparmiando qualcosa per il futuro`,
    },
    {
      text: `Esattamente quello che guadagna, evitando sprechi`,
    },
    {
      text: `Più di quello che guadagna, per far andare bene l’economia del Paese`,
    },
  ],

  points: 1,

  feedback: {
    correct: `Perfetto, è la scelta più saggia e prudente! Così non farà debiti e avrà dei soldi da parte per imprevisti o acquisti importanti che altrimenti non potrebbe permettersi.`,
    wrong: `Non ci siamo! Se spende sempre tutto, non avrà nulla da parte per affrontare spese impreviste in caso di emergenze o di belle occasioni improvvise. E se spende ogni volta più di quanto guadagna finirà coperto di debiti!`
  }
}