import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/012.png',

  heading: `A cosa servono le tasse`,
  paragraph: `Come vengono pagati tutti quei **servizi** che possiamo utilizzare gratuitamente? Ogni volta che una persona guadagna qualcosa, **una parte dei soldi va allo Stato**.
  Quei soldi si chiamano **tasse**. Le tasse servono a realizzare servizi pubblici, come costruire e mantenere le scuole, gli ospedali, le strade o dare uno stipendio ai vigili del fuoco, alla polizia, ai magistrati e tante altre cose. Grazie a questi servizi, possiamo **vivere meglio** e non dobbiamo pagare ogni volta che li utilizziamo.`
}