import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_INPUT_VALUE,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Isotta e Nina decidono di preparare dei coniglietti di cioccolato per una vendita di beneficenza, fondendo alcune tavolette in uno stampo.

  Hanno calcolato che da una tavoletta di cioccolato possono ricavare 6 coniglietti. Inoltre, una volta che si sono raffreddati, togliendo le sbavature e le parti in eccesso dai coniglietti appena fabbricati possono ottenere dell’altro cioccolato: ogni 3 coniglietti hanno materiale per farne uno nuovo.

  Se Isotta e Nina hanno quattro tavolette di cioccolato e mezzo, quanti coniglietti riusciranno a fabbricare?`,

  feedback_by_value: [
    {
      value: 40,
      operator: '==',
      feedback_message: `Risposta giusta: Bravo! I tuoi conti sono esatti.`,
      feedback_type: 'success'
    },
    {
      value: 40,
      operator: '!=',
      feedback_message: `Risposta sbagliata: Non è corretto, ti è sfuggito qualcosa...`,
      feedback_type: 'error'
    }
  ],

  value_type_override: '🐰',

  feedback_comment: `Da 4 tavolette e mezzo si ricavano 27 coniglietti. Dagli scarti di questi si ottengono altri 9 coniglietti. Dai 9 appena ottenuti si ottengono altri 3 coniglietti. Dagli scarti di questi si ottiene un altro coniglietto ancora. In totale sono 27 + 9 + 3 + 1 = 40 coniglietti. È lo stesso principio dell'interesse composto: gli interessi non si calcolano solo sul capitale, ma anche sugli interessi maturati nel frattempo.`
}