import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/116.png',

  heading: `Ma chi produce i soldi?`,
  paragraph: `Nel nostro paese la moneta è l’euro. La Banca d’Italia emette le banconote in euro in base ai principi e alle regole fissati nell’Eurosistema, cioè l’insieme della Banca centrale europea e delle banche centrali nazionali dei Paesi dell’Unione europea che hanno adottato l’euro. Le Banche centrali svolgono però altri importanti compiti. Uno dei più rilevanti è proteggere il valore della moneta nel tempo. Per farlo, agiscono in modo da influenzare il valore e la quantità di moneta.`
}