import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: '',
  paragraph: `Anche se non garantisce una remunerazione elevata, tenere i soldi in un conto corrente ha molti vantaggi: sai indovinare quali di questi sono veri e quali falsi?`,

  label: 'VERO?',

  items: [
    {
      term: 'Puoi disporre dei tuoi soldi quando vuoi, anche a banche chiuse, se hai una carta di debito o disponi dell’internet banking.',
      sum_cond_value: true
    },
    {
      term: 'Ogni mille giorni, ogni correntista riceve un Buono del Tesoro omaggio dalla banca',
      sum_cond_value: false
    },
    {
      term: 'Consente di utilizzare tanti servizi di pagamento, dai bonifici agli addebiti',
      sum_cond_value: true
    },
    {
      term: 'Esiste una tutela pubblica per ogni correntista presso ogni banca: in caso di fallimento dell’intermediario il titolare del conto avrà diritto a ricevere i suoi soldi indietro fino ad un massimo di 100 mila euro.',
      sum_cond_value: true
    },
    {
      term: 'Il potere di acquisto di una somma depositata in un conto che riconosce un tasso di interesse del 2 per cento, in presenza di inflazione al 3 per cento, dopo un anno è aumentato.',
      sum_cond_value: false
    },
    {
      term: 'Il potere di acquisto di una somma depositata in un conto che riconosce un tasso di interesse del 2 per cento, in presenza di inflazione all’1,5 per cento, dopo un anno è diminuito.',
      sum_cond_value: false
    }
  ],

  points: 1,

  feedback: {
    correct: `Bel colpo! Sei proprio un esperto di conti bancari.`,
    wrong: `Mi sa che hai le idee un po’ confuse… ma informarsi è facile!`
  }
}