import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-03_P-05-SL-02-HOT-01';
import hotspot3 from './hotspots/SECT-03_P-05-SL-02-HOT-03';
import hotspot4 from './hotspots/SECT-03_P-05-SL-02-HOT-04';

export default {
  id: 'SECT-03_P-05-SL-02',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/340.png',
        from: '',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Fra diverse alternative`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot3,
    hotspot4
  ],

  extra_class: ''
};