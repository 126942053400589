import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/313.png',

  heading: `Il baratto e la moneta-merce`,
  paragraph: `Il baratto è un meccanismo di scambio di merci che non prevede l’uso della moneta: è uno scambio in natura, merce contro merce. Un simile sistema di scambio comporta numerose complicazioni, prima fra tutte quella di dover trovare ogni volta qualcuno che abbia quello che noi vogliamo e chi allo stesso tempo voglia quello che noi abbiamo. È verosimile che un simile sistema non sia mai esistito all’interno di società complesse, se non in situazioni brevi ed eccezionali. Le tavolette di argilla mesopotamiche mostrano che già migliaia di anni prima dell’esistenza della moneta metallica gli scambi commerciali non erano normalmente basati sul baratto, ma utilizzando alcune merci (pezzi di argento o oro) come mezzi di pagamento.`
}