import React from 'react';
import { Link } from 'react-router-dom';

import Settings from '../../config/Settings';

class SidebarNav extends React.PureComponent {
  render() {
    const currSection = this.props.section;
    const currPage = this.props.page;

    return (
      <>
        <nav className="col-md-auto bankitalia-header__col bankitalia-header__col--nav">
          <ul className="bankitalia-navigation bankitalia-navigation--alt">
            {
              currSection.pages.map(page => (
                <li className="bankitalia-navigation__item">
                  <Link className={'bankitalia-chapter bankitalia-chapter--alt' + (page.base_path == currPage.base_path ? ' bankitalia-chapter--alt-active' : '')} to={`${page.base_path}/inizio/`}>
                    <div className="bankitalia-chapter__inner">
                      <figure className="bankitalia-chapter__figure">
                        <img src={page.icon} />
                      </figure>

                      <p className="bankitalia-chapter__name">{page.name}</p>
                    </div>
                  </Link>
                </li>
              ))
            }
          </ul>

          <button className="bankitalia-navigation-close" type="button">
            <img src="/images/icons/close.png" />
          </button>
        </nav>

        <div className="col bankitalia-header__col bankitalia-header__col--hamburger">
          <button className="bankitalia-hamburger" type="submit">
            <img src="/images/icons/hamburger.png" srcset="/images/icons/hamburger.png, /images/icons/hamburger2x.png 2x" title="Open menu" alt="Hamburger" />
          </button>
        </div>
      </>
    );
  }
}

export default SidebarNav;