import slide1 from './slides/01/section-02-page-02-slide-01';
import slide2 from './slides/02/section-02-page-02-slide-02';
import slide3 from './slides/03/section-02-page-02-slide-03';

export default {
  id: 'SECT-02–P-02',

  first_page: false,
  base_path: '/scuole/media/valore-soldi',

  name: `Quanto valgono i soldi`,

  icon: '/images/section-02/icons/page-02.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/145_0004_1.png',
        animation: 'playful',
        from: 'left',
        speed: 'xxxxxx', // disable animation
        stack: 5
      },
      {
        image: '/images/section-02/animated/layers/145_0003_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      },
      {
        image: '/images/section-02/animated/layers/145_0002_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-02/animated/layers/145_0000_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-02/animated/layers/145_0001_5.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      }
    ]
  },

  page_content: {
    heading: `Quanto valgono i soldi`,
    paragraph: `Omar desidera tanto la collezione di segnalibri di Nina, che però vorrebbe qualcosa in cambio: le è costata tanta fatica metterla assieme! Faranno un baratto? Omar gliela comprerà? E in questo caso, chi deciderà il prezzo?`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};