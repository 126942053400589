import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Il crowdfunding`,
  paragraph: `Per finanziare vari tipi di progetti negli ultimi anni si ricorre spesso al "crowdfunding" un termine inglese che deriva da funding ("finanziamento") e crowd ("folla").
  Invece di chiedere molti soldi a un solo interlocutore, si chiede un finanziamento meno impegnativo a più persone attraverso piattaforme online.`
}