import slide1 from './slides/01/section-03-page-02-slide-01';
import slide2 from './slides/02/section-03-page-02-slide-02';
import slide3 from './slides/03/section-03-page-02-slide-03';

export default {
  id: 'SECT-03_P-02',

  first_page: false,
  base_path: '/scuole/superiore/prezzi',

  name: `Moneta e prezzi`,

  icon: '/images/section-03/icons/page-02.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/311.png',
        from: '',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Moneta e prezzi`,
    paragraph: `Quante cataste di legna costa quel bel vestitino?`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};