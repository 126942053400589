import slide1 from './slides/01/section-03-page-04-slide-01';
import slide2 from './slides/02/section-03-page-04-slide-02';
import slide3 from './slides/03/section-03-page-04-slide-03';

export default {
  id: 'SECT-03_P-04',

  first_page: false,
  base_path: '/scuole/superiore/risparmio',

  name: `Risparmio e investimento`,

  icon: '/images/section-03/icons/page-04.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/329.png',
        from: '',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Risparmio e investimento`,
    paragraph: `Teo sta pensando al futuro: vuole fare la rockstar! Ma non è un lavoro che si improvvisa: per imparare occorre innanzitutto andare a scuola di musica e procurarsi una buona chitarra. E questo costa...`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};