import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-03-HOT-04',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: '',
  paragraph: `I nostri amici hanno dei sogni, alcuni a breve termine, altri da realizzare nel tempo. Valuta se le loro strategie di investimento sono coerenti con i loro obiettivi.`,

  label: 'COERENTE?',

  items: [
    {
      term: 'Nina il prossimo trimestre vorrebbe frequentare un corso per acquisire la patente europea per l’uso del computer (ECDL); conserva i suoi soldi sul conto corrente',
      sum_cond_value: true
    },
    {
      term: 'Isotta vuole comprare un soprabito di marca per la prossima stagione. Ha appena aperto un deposito a tempo con scadenza annuale dove ha trasferito tutti i suoi risparmi',
      sum_cond_value: false
    },
    {
      term: 'Omar ha iniziato a lavorare regolarmente part time. Non sa ancora cosa farà da grande, ma ha sottoscritto un PAC (piano di accumulo del capitale)',
      sum_cond_value: true
    },
    {
      term: 'Teo ha convinto la nonna ad investire la tredicesima della pensione in BitCoin',
      sum_cond_value: false
    },
    {
      term: 'Nina vuole un telefonino nuovo e lo compra a rate, ciascuna delle quali è pari a metà della propria paghetta',
      sum_cond_value: true
    }
  ],

  points: 1,

  feedback: {
    correct: `Ottima prova, hai un buon fiuto per i comportamenti corretti.`,
    wrong: `Purtroppo non ci siamo... Devi imparare a orientarti meglio fra i vari strumenti a disposizione per ogni necessità.`
  }
}