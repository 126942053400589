import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Settings from './config/Settings.js';
import PointsUtil from './utils/PointsUtil';

import Navbar from './components/shared/Navbar';

import HomePage from './pages/HomePage';
import StudentSectionSummaryPage from './pages/StudentSectionSummaryPage';
import StudentSectionIntroPage from './pages/StudentSectionIntroPage';
import StudentSectionPage from './pages/StudentSectionPage';
import StudentSectionOutroPage from './pages/StudentSectionOutroPage';
import TeachersPage from './pages/TeachersPage';
import AboutPage from './pages/AboutPage';

import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';

import './styles/scss/styles.scss';
import './styles/illustration-animations.css';

const pageTitleSuffix = '| Bankitalia';
let routes = [];
routes.push(<Route exact path="/" render={(props) => {
  const pageTitle = `A scuola di economia con la Banca d'Italia ${pageTitleSuffix}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HomePage {...props} />
    </>
  );
}} />);
Settings.sections.forEach((section) => {
  routes.push(<Route exact path={`${section.base_path}/sommario`} render={(props) => {
  const pageTitle = `${section.name} ${pageTitleSuffix}`;

  return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <StudentSectionSummaryPage section={section} {...props} />
      </>
    );
  }} />);
  section.pages.forEach((page) => {
    routes.push(<Route exact path={`${page.base_path}/inizio`} render={(props) => {
      const pageTitle = `${page.name} (inizio) - ${section.name} ${pageTitleSuffix}`;

      return (
        <>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StudentSectionIntroPage section={section} page={page} {...props} />
        </>
      );
    }} />);
    routes.push(<Route exact path={`${page.base_path}/gioca`} render={(props) => {
      const pageTitle = `${page.name} (gioca) - ${section.name} ${pageTitleSuffix}`;

      return (
        <>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StudentSectionPage section={section} page={page} {...props} />
        </>
      );
    }} />);
    routes.push(<Route exact path={`${page.base_path}/fine`} render={(props) => {
      const pageTitle = `${page.name} (fine) - ${section.name} ${pageTitleSuffix}`;

      return (
        <>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <StudentSectionOutroPage section={section} page={page} {...props} />
        </>
      );
    }} />);
  });
});
routes.push(<Route exact path="/insegnanti" render={(props) => {
  const pageTitle = `Insegnanti ${pageTitleSuffix}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <TeachersPage {...props} />
    </>
  );
}} />);
routes.push(<Route exact path="/info" render={(props) => {
  const pageTitle = `Info ${pageTitleSuffix}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AboutPage {...props} />
    </>
  );
}} />);

class App extends Component {
  constructor(props) {
    super(props);

    // reset points at every page refresh
    Settings.sections.forEach((section) => {
      section.pages.forEach((page) => {
        const pageId = page.id;

        PointsUtil.resetPoints(pageId);
      })
    });
  }

  render() {
    const colorSchemes = ['', 'blue', 'green', 'orange', 'aqua'];
    const currColorScheme = 0;

    return (
      <Router>
        <div className={colorSchemes[currColorScheme]}>
          {routes}
        </div>
      </Router>
    );
  }
}

export default App;
