import React from 'react';

import ReactMarkdown from 'react-markdown';

export default class MarkdownParser extends React.PureComponent {
  render() {
    let markdown = this.props.source;

    // add support for new lines (see: https://github.com/remarkjs/react-markdown/issues/278)
    markdown = String(markdown).replace(/\n/gi, '  \n');

    return (
      <ReactMarkdown source={markdown} />
    );
  }
}