import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-03-SL-03-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-03-SL-03-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-03-SL-03-HOT-03';

export default {
  id: 'SECT-02–P-03-SL-03',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/128_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-02/animated/layers/128_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      }
    ]
  },

  page_content: {
    heading: `I diritti di chi compra`,
    paragraph: `Consumatori di tutto il mondo, svegliatevi!`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};