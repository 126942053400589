import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-04-SL-03-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-04-SL-03-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-04-SL-03-HOT-03';

export default {
  id: 'SECT-02–P-04-SL-03',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/135_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Tutte le uova nello stesso paniere?`,
    paragraph: `Mia nonna mi diceva sempre di non mettere tutte le uova nello stesso cestino... Se ti cade perderai tutte le tue uova. Mentre se le hai distribuite in più panieri, se te ne cade uno, ti resteranno le uova che hai messo negli altri!`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};