import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-05-SL-02-HOT-01',
  
  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Cos'è l'interesse?`,
  paragraph: `Quando si chiedono dei soldi a una banca o a una società finanziaria, queste ci forniscono un servizio, dandoci dei soldi che al momento non abbiamo e “prendendoli” dal nostro futuro reddito. È come un viaggio nel tempo dei soldi! Però questo servizio, come tutti i servizi, ha un costo. Nel costo sono compresi gli interessi, cioè una somma di denaro in più che dovremo restituire oltre alla cifra che ci è stata prestata. Quanti interessi devo restituire ce lo dice il tasso di interesse: un tasso di interesse del 2% all'anno significa che, per ogni 100 euro che ricevo, dopo 12 mesi dovrò restituirne 2 in più alla banca (oltre ai 100 che mi sono stati prestati). In altri termini gli interessi che devo restituire si calcolano moltiplicando il tasso di interesse per la somma che ho preso a prestito. Ancora poco chiaro? Provate a giocare con il calcolatore dell'interesse!`
}