import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/341.png',

  heading: `Mi conviene o no?`,
  paragraph: `I finanziamenti non sono tutti uguali: ognuno ha le sue caratteristiche, che è necessario considerare per capire se è adatto alle nostre esigenze e se è conveniente, anche rispetto alle altre soluzioni sul mercato.
  Per prima cosa la durata: in quanto tempo va restituito. Ovviamente più sarà lungo il prestito e più interessi pagheremo, in aggiunta alla somma da restituire.

  Altro elemento fondamentale è il tasso di interesse: cioè quanto di più, in percentuale, va restituito rispetto alla somma ricevuta. Consideriamo il TAEG, Tasso Annuo Effettivo Globale, che include anche tutte le spese. È più utile del TAN, il Tasso Annuo Nominale, che non ne tiene conto: quando la pubblicità parla di “tasso zero” si riferisce al TAN, ma non significa che il finanziamento sia gratis!

  Il tasso può essere fisso, sempre uguale nel tempo, o variabile, e allora cambia nel tempo in base alle oscillazioni di un parametro di riferimento, di solito stabilito sui mercati monetari e finanziari.

  Un quadro assai utile è il piano di ammortamento, cioè una tabella che riassume le somme da restituire alle varie scadenze. Analizzandola insieme al nostro reddito ci consente di valutare se il prestito che vogliamo sottoscrivere è coerente con le nostre possibilità o stiamo facendo il passo più lungo della gamba.

  È bene ricordare che il contratto di finanziamento va letto e compreso bene prima di firmare, perché è un impegno formale, per noi e per chi ce lo concede!`
}