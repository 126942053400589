import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-01-HOT-01',
  
  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/021.png',

  heading: `Soldi Elettronici`,
  paragraph: `Può sembrare magia, usare una tessera per pagare tutto quello che si vuole senza dover tirare fuori un soldo. Ma dietro c'è il trucco. Infatti ogni carta di debito è collegata a un “portafoglio” che sta in banca e che di solito si chiama “conto corrente”. È da qui che la carta di debito prende i soldi per darli al negoziante quando la usiamo. Ma da cosa è composta una carta di debito? Prova a ricomporla e scoprire a cosa servono le varie parti!`
}