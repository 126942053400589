import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/129.png',

  heading: `Ci penso e ci ripenso`,
  paragraph: `Omar protesta con il libraio e rivuole indietro i soldi: ha comprato un libro che si intitola “La storia infinita”... Invece dopo qualche centinaio di pagine la storia è proprio finita! Il cliente ha sempre ragione? Forse no, ma talvolta sì e per molte cose la legge lo protegge. Ecco i diritti principali di chi compra qualcosa.

  Corretta informazione: non si possono fare pubblicità ambigue o ingannevoli; i prodotti devono riportare informazioni chiare come nome di produttore e importatore, data di scadenza, ingredienti degli alimenti.

  Recesso: ogni tipo di merce ha un periodo entro il quale possiamo restituirla, se non siamo soddisfatti, purché integra nella confezione originale e con un documento di acquisto come la ricevuta o lo scontrino fiscale.

  Reso: se la merce ha difetti possiamo chiedere di cambiarla o di riavere indietro i soldi. `
}