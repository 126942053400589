import constants from '../../constants';
import colorSchemes from '../../color_schemes';

import page1 from './pages/01/section-02-page-01';
import page2 from './pages/02/section-02-page-02';
import page3 from './pages/03/section-02-page-03';
import page4 from './pages/04/section-02-page-04';
import page5 from './pages/05/section-02-page-05';

export default {
  id: 'SECT-02',

  palette: colorSchemes['orange'],

  first_page: false,
  base_path: '/scuole/media',

  name: 'Scuola Media',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/101_0000_omar.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-02/animated/layers/101_0001_isotta.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/101_0002_nina.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-02/animated/layers/101_0003_teo.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-02/animated/layers/101_0004_attila.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      },
    ]
  },
  animation_type: 'playful',

  page_content: {
    heading: `Vai alla Scuola Media?`,
    paragraph: `Clicca e scopri tante curiosità e informazioni sul denaro, in compagnia di quattro simpatici amici e del loro buffo cane!`
  },

  pages: [
    page1,
    page2,
    page3,
    page4,
    page5
  ],

  outro_image: '/images/section-02/outro.png'
};