import constants from './constants';
import colorSchemes from './color_schemes';
import content from './content';

export default {
  ...constants,

  palettes: colorSchemes,

  sections: content.sections,

  pageFXClass: ''
};