import constants from '../../../../../../constants';

import hotspot1 from './hotspots/section-01-page-01-slide-01-hotspot-01';
import hotspot2 from './hotspots/section-01-page-01-slide-01-hotspot-02';
import hotspot3 from './hotspots/section-01-page-01-slide-01-hotspot-03';
import hotspot4 from './hotspots/section-01-page-01-slide-01-hotspot-04';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/003_0001_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-01/layers/animate/003_0000_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/003_0002_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-01/layers/animate/003_0003_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      }
    ]
  },

  page_content: {
    heading: `Occhio alla paghetta!`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3,
    hotspot4
  ],

  extra_class: 's1p1s1'
};