import slide1 from './slides/01/section-01-page-01-slide-01';
import slide2 from './slides/02/section-01-page-01-slide-02';
import slide3 from './slides/03/section-01-page-01-slide-03';

export default {
  id: 'SECT-01_P-01',

  first_page: true,
  base_path: '/scuole/elementare/paghetta',

  name: `Più paghetta più meringhe!`,

  icon: '/images/section-01/icons/page-01.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/002_0002_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-01/layers/animate/002_0003_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-01/layers/animate/002_0000_3.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/002_0001_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      }
    ]
  },

  page_content: {
    heading: `Più paghetta più meringhe!`,
    paragraph: `Gli amici di Omar si sono pappati tutte le meringhe che aveva fatto la sua mamma. Fortunatamente Omar ha appena ricevuto la sua paghetta e può andare a comprarne altre in pasticceria. Solo che lui non è troppo bravo con i soldi. Lo aiuti?`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};