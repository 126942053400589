import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-05-SL-02-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-05-SL-02-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-05-SL-02-HOT-03';

export default {
  id: 'SECT-02–P-05-SL-02',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/141_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-02/animated/layers/141_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      }
    ]
  },

  page_content: {
    heading: `Cercando il tasso basso`,
    paragraph: `Se vuoi te lo do, il mitico slime, ma domani me ne riporti uno uguale e ci aggiungi due figurine d’interesse…`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};