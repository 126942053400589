import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';

import PointsUtil from '../../../utils/PointsUtil';

import { ReactSortable } from "react-sortablejs";

import HotspotModal from './HotspotModal';

export default class SortingModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,

      items: [],
      correct: null,
      submitted: false
    };
  }

  componentDidMount() {
    if (this.props.hotspot && this.props.hotspot.items) {
      let items = [...this.props.hotspot.items];

      // shuffle
      items.sort(() => Math.random() - 0.5);

      // add "pos" property
      items = items.map((item, i) => {
        item.pos = i + 1;

        return item;
      });

      this.setState({
        items
      });
    }
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });
    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  handleSort = (items) => {
    this.setState({
      items
    });
  }

  handleSubmit = () => {
    if (this.state.submitted) {
      console.warn('Already submitted');

      return;
    }

    const { hotspot } = this.props;

    // see if correct
    let correct = true;
    this.state.items.forEach((item, i) => {
      if (item.text != this.props.hotspot.items[i].text) {
        correct = false;
      }
    });

    this.setState({
      submitted: true,
      correct: correct
    }, () => {
      // update score
      const shouldAddPoints = correct;
      const numPoints = hotspot.points;
      if (shouldAddPoints) {
        PointsUtil.addPoints(hotspot, numPoints);
      }
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });
    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    return (
      <>
        <div className={`HOTSPOT-SORT bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="row">

              {/* Hint(s) */}
              <div className="col-lg-3 bankitalia-modal__col bankitalia-modal__col--tips">
                <div className="bankitalia-tips">
                  {
                    hotspot.hint && hotspot.hint.message && (
                      <>
                        <div className="bankitalia-tips__box">
                          <p>{hotspot.hint.message}</p>
                        </div>

                        { hotspot.hint.image && <img src={hotspot.hint.image} /> }
                      </>
                    )
                  }
                </div>
              </div>

              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-0 bankitalia-modal__col bankitalia-modal__col--question">
                <div className="bankitalia-quiz">
                  <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
                  <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

                  <div className="bankitalia-quiz__answers-wrapper">
                    <ul className="bankitalia-quiz__answers bankitalia-quiz__answers--switch-1">
                      {
                        this.state.items && this.state.items.length && (
                          <ReactSortable
                            list={this.state.items}

                            animation={150}
                            ghostClass="bankitalia-quiz__answer--sort-item-ghost"

                            setList={this.handleSort}
                          >
                            {
                              this.state.items.map((item, i) => {
                                return (
                                  <li className="bankitalia-quiz__answer bankitalia-quiz__answer--sort-item">
                                    <div className="bankitalia-answer bankitalia-answer--alt">
                                      <span className="bankitalia-answer__nr">{item.pos}</span>
                                      <div className="bankitalia-answer__label">{item.text}</div>
                                    </div>
                                  </li>
                                );
                              })
                            }
                          </ReactSortable>
                        )
                      }
                      {
                        hotspot.items.map((option, i) => {

                        })
                      }
                    </ul>
                  </div>

                  {/* Submit button */}
                  <div className="bankitalia-quiz__actions">
                    <button className={`bankitalia-btn ${(this.state.answer === null || this.state.submitted) && 'disabled'}`} type="button" onClick={this.handleSubmit}>
                      <span className="bankitalia-btn__label">invia</span>
                    </button>
                  </div>

                  {/* Feedback message (classes are "success", "error") */}
                  {
                    this.state.submitted && (
                      <div className="bankitalia-quiz__message magictime slideDownReturn">
                        <div className={`bankitalia-message bankitalia-message--${this.state.correct ? 'success' : 'error'}`}>
                          <p>
                            {this.state.correct ? hotspot.feedback.correct : hotspot.feedback.wrong}<br />
                            { this.state.correct && <>Hai vinto {hotspot.points} {hotspot.points > 1 ? 'punti' : 'punto'}</>}
                          </p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}