import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-04-SL-01-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-04-SL-01-HOT-02';

export default {
  id: 'SECT-02–P-04-SL-01',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/132_0000s_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: 'fastest',
        stack: 0
      },
      {
        image: '/images/section-02/animated/layers/132_0000s_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: 'fast',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/132_0000s_0002_3.png',
        animation: 'playful',
        from: 'left',
        speed: 'slow',
        stack: 2
      }
    ]
  },

  page_content: {
    heading: `La gallina domani!`,
    paragraph: `Meglio un uovo oggi o una gallina domani? O cinque uova dopodomani? O cinque galline fra tre giorni? O forse venticinque uova fra quattro giorni…`,
  },

  hotspots: [
    hotspot1,
    hotspot2
  ],

  extra_class: ''
};