import slide1 from './slides/01/section-01-page-02-slide-01';
import slide2 from './slides/02/section-01-page-02-slide-02';
import slide3 from './slides/03/section-01-page-02-slide-03';

export default {
  id: 'SECT-01_P-02',
  
  first_page: false,
  base_path: '/scuole/elementare/testa-croce',

  name: `Testa o croce?`,

  icon: '/images/section-01/icons/page-02.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/013_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Testa o croce?`,
    paragraph: `Nina e Isotta stanno collezionando le monete in euro di tutti i paesi, ma non riescono a capire a che nazioni appartengono. Le aiuti?`
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};