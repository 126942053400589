import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import MarkdownParser from '../components/shared/MarkdownParser';

import Settings from '../config/Settings';

import PointsUtil from '../utils/PointsUtil';

import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import AnimatedIllustration from '../components/shared/AnimatedIllustration';

import BANKITALIA from '../BANKITALIA';
import PageNavbar from '../components/sections/PageNavbar';
import SidebarNav from '../components/sections/SidebarNav';

class StudentSectionOutroPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showResults: false
    };

    window.setTimeout(() => {
      this.setState({
        showResults: true
      });
    }, 1000);
  }

  componentDidMount = () => {
    (function($) {
      $(document).ready(function() {
        BANKITALIA.init();
      });
    })(window.jQuery);
  }

  handleRestart = () => {
    const { section, page, history } = this.props;

    // reset points
    const pageId = page.id;
    PointsUtil.resetPoints(pageId);

    // navigate to intro page
    const path = page.base_path + '/inizio/';
    history.push(path);
  }

  handleContinue = () => {
    const { section, page, history } = this.props;

    // find next page
    let nextPage = null;

    section.pages.forEach((sectionPage, i) => {
      if (sectionPage.base_path == page.base_path) {
        nextPage = section.pages[i+1];
      }
    });

    if (nextPage) {
      const path = nextPage.base_path + '/inizio/';

      history.push(path);
    }
  }

  render() {
    const { section, page } = this.props;

    const pageId = page.id;
    const numPoints = PointsUtil.getPoints(pageId);

    return (
      <div className={Settings.pageFXClass + ' ' + section.palette}>
        <div className="bankitalia-app bankitalia-app--start">
          <Header>
            <PageNavbar section={section} page={page} />
            <SidebarNav section={section} page={page} />
          </Header>

          <div className="container bankitalia-main">
            <div className="row bankitalia-game-result">
              <div className="col-sm-8 offset-sm-2 bankitalia-game-result__main">
                {
                  this.state.showResults && (
                      <div className="bankitalia-game-result__box magictime tinUpIn">
                        <strong>Capitolo completato!</strong>
                        <p>Hai {numPoints} {numPoints == 1 ? 'punto' : 'punti'}</p>
                      </div>
                  )
                }

                <AnimatedIllustration layers={[
                  {
                    image: section.outro_image,
                    animation: 'playful',
            from: 'left',
                    speed: 'fastest',
                    delay: '',
                    stack: 0
                  }]}
                  animationType="playful"
                />
              </div>
              <div className="col-sm-2 bankitalia-game-result__actions">
                <a className="bankitalia-ico-btn bankitalia-ico-btn--with-label" href="javascript:void(0)" onClick={this.handleRestart}>
                  <img className="bankitalia-icon-btn__icon" src="/images/icons/revert.png" srcset="/images/icons/revert.png, /images/icons/revert-2x.png 2x" title="" alt="" />
                  <span className="bankitalia-ico-btn__label">RIPROVA</span>
                </a>
                <a className="bankitalia-ico-btn bankitalia-ico-btn--negative bankitalia-ico-btn--with-label" href="javascript:void(0)" onClick={this.handleContinue}>
                  <img className="bankitalia-icon-btn__icon" src="/images/icons/arrow-2.png" srcset="/images/icons/arrow-2.png, /images/icons/arrow-2-2x.png 2x" title="" alt="" />
                  <span className="bankitalia-ico-btn__label">AVANTI</span>
                </a>
              </div>
            </div>
          </div>

          <footer className="container bankitalia-footer">
            <div className="row bankitalia-footer__row">
              <div className="col-sm-6 bankitalia-footer__col bankitalia-footer__col--logo">
                <Link className="bankitalia-logo" to="/">
                  <img src="/images/shared/bankitalia-logo.png" />
                </Link>
              </div>
              <div className="col-sm-6 bankitalia-footer__col bankitalia-footer__col--copyright">
                <p className="bankitalia-copyright">&copy; Copyright 2021 Banca d’Italia</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(StudentSectionOutroPage);