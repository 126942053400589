import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/342.png',

  heading: `A garanzia di chi presta`,
  paragraph: `Ottenere credito non è un diritto, ma una opportunità, e come tale la scelta di indebitarsi deve essere ben ponderata. La prima condizione per poter ricevere un credito è… essere credibili: infatti chi ci concede i soldi deve essere convinto che glieli restituiremo nei tempi e nei modi prestabiliti, anche perché i soldi che una banca presta non sono i suoi, ma quelli che raccoglie dai risparmiatori a cui dovrà comunque restituirli.

  Prima di concederci un prestito, valuterà il nostro merito creditizio esaminando tanti elementi, come il nostro reddito e il nostro comportamento passato; in base a questi elementi giudicherà se siamo affidabili. A volte vorrà delle garanzie: ad esempio che un’altra persona si impegni a pagare se non lo facciamo noi. Altre volte ci chiederà di sottoscrivere un’ipoteca su un nostro bene: se non riusciremo a ripagare il nostro debito il bene ipotecato verrà venduto e con l’importo ottenuto dalla vendita il creditore ripagherà il debito concesso.`
}