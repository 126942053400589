import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-05-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_INTEREST_CALC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Calcola il tuo interesse!`,
  paragraph: `Prova a vedere quanto interesse ti costerebbe chiedere dei soldi, puoi sbizzarrirti mettendo cifre astronomiche o molto piccole. Inserisci prima quanti soldi vuoi chiedere, poi il tasso d'interesse, un tasso realistico di solito è compreso tra lo 0 e il 5%.

  E ricorda: se è eccessivamente alto è illegale e si parla di usura!`,

  max_rate: 10
}