import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: 'Sicuri o pericolosi?',
  paragraph: `Isotta non è sicura di quello che può fare in rete. Sai riconoscere quali di questi comportamenti potrebbero essere pericolosi?`,

  label: 'PERICOLOSO?',

  items: [
    {
      term: 'Cliccare su un banner che le dice che è il milionesimo cliente e ha vinto un cellulare.',
      sum_cond_value: true
    },
    {
      term: 'Leggere una e-mail che le arriva da Omar.',
      sum_cond_value: false
    },
    {
      term: 'Scaricare sul computer una canzone da un sito che ne offre centinaia di migliaia gratis',
      sum_cond_value: true
    },
    {
      term: 'Cliccare su un link in una e-mail da uno sconosciuto che pubblicizza un nuovissimo negozio di scarpe online con prezzi stracciati.',
      sum_cond_value: true
    },
    {
      term: 'Comprare su un sito molto famoso e con parecchie recensioni.',
      sum_cond_value: false
    },
    {
      term: 'Cliccare su un banner pubblicitario che reclamizza suonerie di cellulari in offerta.',
      sum_cond_value: true
    }
  ],

  points: 1,

  feedback: {
    correct: `Ottima riuscita, 4 risposte giuste. Tu sì che sai come comportarti! Ma se devi fare acquisti o installare qualcosa, ricordati sempre di chiamare un adulto.`,
    wrong: `Fai attenzione... Non sei ancora in grado di riconoscere bene le trappole dalle situazioni più tranquille!`
  }
}