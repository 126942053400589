import React from 'react';
import { Link } from 'react-router-dom';

import Settings from '../../config/Settings';

class SidebarNav extends React.PureComponent {
  render() {
    const currSection = this.props.section;
    const currPage = this.props.page;

    return (
      <>
        <div className="col-sm-auto bankitalia-header__col bankitalia-header__col--chapters">
          <div className="bankitalia-pagination bankitalia-pagination--chapters">
            {
              currSection.pages.map((page, i) => (
                <Link to={`${page.base_path}/inizio/`}>
                  <button className={'bankitalia-pagination__item' + (page.base_path == currPage.base_path ? ' active' : '')} type="button">
                    <span className="bankitalia-pagination__item__label">Capitolo</span>
                    <span className="bankitalia-pagination__item__nr">{i + 1}</span>
                  </button>
                </Link>
              ))
            }
          </div>
        </div>
      </>
    );
  }
}

export default SidebarNav;