import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/331.png',

  heading: `Perché risparmiare?`,
  paragraph: `Se si può, conviene rinunciare a consumare subito tutto ciò che si guadagna e mettere qualcosa da parte, per vari, buoni motivi:
  
  Avere dei risparmi ci mette al riparo dagli imprevisti, perché se abbiamo qualche soldo da parte possiamo affrontare una spesa improvvisa o cavarcela in un periodo in cui per qualche motivo non guadagniamo abbastanza.
  Accumulando anche pochi soldi per volta, possiamo con il tempo arrivare a disporre della cifra necessaria per un acquisto importante, che altrimenti, difficilmente potremmo permetterci.
  Infine, se investiamo con accortezza i nostri risparmi, possiamo ottenere un guadagno: il denaro produce nuovo denaro.`
}