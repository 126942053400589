import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-01-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Nell'elenco sono riportati i 19 paesi dell'Area dell'euro. Sai riconoscere i tre che nel 2019 avevano il PIL pro capite più alto?`,

  items: [
    {
      text: `È un paese piccolo ed è l’unico Granducato al mondo.`,
      term: 'Lussemburgo'
    },
    {
      text: 'La capitale è Dublino',
      term: 'Irlanda'
    },
    {
      text: 'Una parte consistente della superficie di questo paese è sotto il livello del mare.',
      term: 'Paesi Bassi'
    }
  ],
  extra_terms: [
    'Austria',
    'Belgio',
    'Cipro',
    'Estonia',
    'Finlandia',
    'Francia',
    'Germania',
    'Grecia',
    'Italia',
    'Lettonia',
    'Lituania',
    'Malta',
    'Portogallo',
    'Slovacchia',
    'Slovenia',
    'Spagna'
  ],

  points: 1,

  feedback: {
    correct: `Bravo, ottimo intuito!`,
    wrong: `Ahimè non ci siamo: avresti bisogno di saperne di più.`
  }
}