import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-03-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/323.png',

  heading: `Senza contante`,
  paragraph: `Gli strumenti di pagamento alternativi al contante sono molti e ci aiutano nella nostra vita quotidiana a pagare in modo sicuro e anche comodo: spesso sono infatti integrati con gli strumenti digitali di uso quotidiano come lo smartphone.

  Vediamone alcuni.
  
  Bonifico: ordine di trasferimento di una somma tra due conti. Una persona (debitore) chiede alla propria banca di addebitare una certa somma di denaro sul proprio conto e di accreditarla su quello di un'altra persona (beneficiario). Si può fare anche online o con il telefono;  è utile anche per trasferire somme di denaro di grosso importo.
  
  Addebito diretto: è simile al bonifico ma funziona al contrario. Il beneficiario ordina alla banca di accreditare sul suo conto l’importo dovuto dal debitore, sulla base di un’autorizzazione all’incasso rilasciata da quest’ultimo. È molto utile per pagamenti ricorrenti come ad esempio le bollette del gas o dell’energia elettrica. 
  
  Carte di pagamento: tessere elettroniche che permettono – oltre al prelievo del contante – di trasferire denaro. Le principali carte di pagamento sono: carte di debito, carte prepagate e carte di credito. Si distinguono in base all’esistenza o meno di un collegamento con un conto e al momento e alla modalità con cui vengono addebitati i soldi spesi. Le carte di debito sono collegate ad un conto e permettono di effettuare una spesa o un  prelievo solo nei limiti della disponibilità del conto; l’addebito è in genere immediato.
  
  Sulla carta prepagata può essere  pre-caricato dal proprietario una certa somma di denaro, gli importi prelevati o spesi vengono addebitati immediatamente riducendo la disponibilità. In caso di furto o frode il rischio di perdita è limitato al solo importo pre-caricato! Ci sono anche carte prepagate a cui viene associato un codice IBAN (International Bank Account Number), consentono alcuni servizi di pagamento; ad esempio sono abilitate a ricevere accrediti ed effettuare pagamenti, bonifici o addebiti diretti. Per questo sono chiamate anche carte ibanizzate o carte conto. 
  
  Le carte di credito si distinguono dalle altre perché l’importo delle spese effettuate viene addebitato in un momento successivo rispetto all’acquisto. Proprio perché l’utilizzo delle carte di credito ci consente una piccola dilazione, per averle è necessario dimostrare una certa solidità finanziaria.`
}