import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_SORT,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Investendo, il rischio di perdere quanto investito è ripagato da interessi più alti. Sai mettere in ordine questi strumenti da quello più redditizio e rischioso a quello meno redditizio e più sicuro? `,

  items: [
    {
      text: `Azioni: rappresentano una parte di capitale di una società, e così chi compra le sue azioni ne diventa socio. Se le cose vanno bene l’azionista riceve i dividendi, cioè una parte dei guadagni della società, ma se vanno male sopporterà delle perdite. Il valore delle azioni sale o scende a seconda di come vanno gli affari: quando l’azionista le rivenderà potrà guadagnarci o rimetterci.`,
    },
    {
      text: `Obbligazioni: titoli emessi ad esempio da banche, imprese o Stati per ottenere soldi offrendo in cambio interessi a tasso fisso o variabile. In inglese si chiamano bond. Includono i titoli di Stato, come i BOT (buoni ordinari del tesoro) e BTP (buoni del tesoro pluriennali).`,
    },
    {
      text: `Depositi a tempo determinato: depositi bancari in cui si lasciano immobilizzati i soldi per un periodo prefissato in cambio di un interesse. In caso di necessità si possono ritirare prima, rinunciando però agli interessi. Anche in caso di fallimento della banca, ci verrebbero comunque restituiti i nostri risparmi fino a 100 mila euro.`,
    }
  ],

  points: 1,

  feedback: {
    correct: `Ottimo, hai compreso il meccanismo alla perfezione!`,
    wrong: `Purtroppo hai sbagliato: il deposito vincolato è il più sicuro e meno redditizio mentre le azioni sono quelle che rendono di più. Però c’è anche il rischio di perdere tutto il capitale investito.`
  }
}