import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';

import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

import PointsUtil from '../../../utils/PointsUtil';

import Bankitalia_ToggleSwitch from '../../../components/shared/Bankitalia_ToggleSwitch';
import HotspotModal from './HotspotModal';

import BANKITALIA from '../../../BANKITALIA';

export default class MultiTogglesModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,

      submitted: false,

      answersByTerm: {},
      numCorrect: 0,
      correct: false
    };
  }

  componentDidMount() {
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });
    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  handleSwitchChange = (yepnope, item) => {
    if (this.state.submitted) {
      return;
    }

    this.setState((prevState) => {
      prevState.answersByTerm[item.term] = yepnope;

      return prevState;
    });
  }

  handleSubmit = () => {
    if (this.state.submitted) {
      console.warn('Already submitted');

      return;
    }

    const { hotspot } = this.props;

    let numWrong = 0;
    hotspot.items.forEach((item) => {
      const answer = this.state.answersByTerm[item.term] !== undefined ? this.state.answersByTerm[item.term] : false; // if didn't answer it's basically false

      if (answer != item.sum_cond_value) {
        numWrong += 1;
      }
    })

    this.setState({
      submitted: true,
      numCorrect: hotspot.items.length - numWrong,
      correct: numWrong == 0
    }, () => {
      // update score
      const shouldAddPoints = this.state.correct;
      const numPoints = hotspot.points;
      if (shouldAddPoints) {
        PointsUtil.addPoints(hotspot, numPoints);
      }
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });

    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    const rowStyle = {
      padding: '0.65em 0',
      borderBottom: '1px solid #f1e8e8',
      display: 'flex',
      alignItems: 'center'
    };

    const itemStyle = {color: '#999', fontSize: '15px', fontFamily: 'FaunaOne'};

    const isSuccess = this.state.correct;

    return (
      <>
        <div className={`HOTSPOT-ASSOC bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal className="" open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="bankitalia-quiz">
              <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
              <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

              <div className="bankitalia-quiz__answers-wrapper">
                <div className="bankitalia-quiz__answer">
                  <div className="row">
                    <div className="col" style={{textAlign: 'right', whiteSpace: 'nowrap'}}><span style={{color: '#333'}}>{hotspot.label}</span></div>
                  </div>
                  {
                    hotspot.items.map((item) => {
                      return (
                        <div className="row" style={rowStyle}>
                          <div className="col">
                            <span style={itemStyle}>{item.term}</span>
                          </div>
                          <div className="col-auto" style={{textAlign: 'center', opacity: this.state.submitted ? 0.5 : 1}}>
                            <Bankitalia_ToggleSwitch disabled={this.state.submitted} onChange={(yepnope) => this.handleSwitchChange(yepnope, item)} />
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>

              {/* Submit button */}
              <div className="bankitalia-quiz__actions">
                <button className={`bankitalia-btn ${(this.state.submitted) && 'disabled'}`} type="button" onClick={this.handleSubmit}>
                  <span className="bankitalia-btn__label">invia</span>
                </button>
              </div>

              {/* Feedback message (classes are "success", "error") */}
              {
                this.state.submitted && (
                  <div className="bankitalia-quiz__message magictime slideDownReturn">
                    <div className={`bankitalia-message bankitalia-message--${isSuccess ? 'success' : 'error'}`}>
                      <p>
                        { isSuccess ? hotspot.feedback.correct.replace('{total}', this.state.numCorrect) : hotspot.feedback.wrong}<br />
                        { isSuccess && <>Hai vinto {hotspot.points} {hotspot.points > 1 ? 'punti' : 'punto'}</>}
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}