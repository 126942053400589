import slide1 from './slides/01/section-01-page-05-slide-01';
import slide2 from './slides/02/section-01-page-05-slide-02';

export default {
  id: 'SECT-01_P-05',

  first_page: false,
  base_path: '/scuole/elementare/albero-dei-soldi',

  name: `Un sogno nel cassetto`,

  icon: '/images/section-01/icons/page-05.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/034_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Un sogno nel cassetto`,
    paragraph: `Teo si è messo in testa che vuole realizzare un concerto, e ora chi lo ferma? Gli diamo una mano? Magari non lo facciamo andare in bancarotta!`
  },

  slides: [
    slide1,
    slide2
  ]
};