import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-02-SL-02-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-02-SL-02-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-02-SL-02-HOT-03';

export default {
  id: 'SECT-02–P-02-SL-02',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/115_0010_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 10
      },
      {
        image: '/images/section-02/animated/layers/115_0009_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 9
      },
      {
        image: '/images/section-02/animated/layers/115_0008_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 8
      },
      {
        image: '/images/section-02/animated/layers/115_0007_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 7
      },
      {
        image: '/images/section-02/animated/layers/115_0006_5.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 6
      },
      {
        image: '/images/section-02/animated/layers/115_0005_6.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 5
      },
      {
        image: '/images/section-02/animated/layers/115_0004_7.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      },
      {
        image: '/images/section-02/animated/layers/115_0003_8.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-02/animated/layers/115_0002_9.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-02/animated/layers/115_0001_10.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/115_0000_11.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `La Babele delle valute`,
    paragraph: `Paese che vai, denaro che trovi!`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};