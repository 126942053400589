import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/123.png',

  heading: `Carte di plastica e altre modernità`,
  paragraph: `Le carte di pagamento sono di solito tessere in plastica con una banda magnetica e un microchip.
  I negozianti hanno un POS (acronimo di Point of Sale): un terminale in cui inserire o su cui appoggiare la carta per autorizzare il trasferimento dei soldi dal conto del cliente a quello del negozio. A seconda della carta i soldi vengono tolti dal conto subito, come con la carta di debito, o dopo un po’ di tempo, come nella carta di credito. Con la carta prepagata invece i soldi che possiamo spendere sono già stati caricati sulla carta.

  È sempre più frequente che si possa pagare anche accostando al POS uno smartphone o un “wearable device”, come un braccialetto o un orologio, abilitati ai pagamenti. In certi posti, come la Scandinavia, gli strumenti di pagamento elettronici hanno oramai sostituito quasi del tutto i contanti.`
}