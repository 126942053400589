import constants from '../../../../../../constants';

import hotspot1 from './hotspots/section-01-page-02-slide-02-hotspot-01';
import hotspot2 from './hotspots/section-01-page-02-slide-02-hotspot-02';
import hotspot3 from './hotspots/section-01-page-02-slide-02-hotspot-03';
import hotspot4 from './hotspots/section-01-page-02-slide-02-hotspot-04';
import hotspot5 from './hotspots/section-01-page-02-slide-02-hotspot-05';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/017_0003_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-01/layers/animate/017_0000_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/017_0001_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-01/layers/animate/017_0002_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-01/layers/animate/017_0004_5.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      }
    ]
  },

  page_content: {
    heading: `Come nasce un prezzo`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3,
    hotspot4,
    hotspot5
  ],

  extra_class: 's1p2s2'
};