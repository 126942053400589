import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-01-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/310.png',

  heading: `Cos’è il budget?`,
  paragraph: `Il bilancio è uno strumento molto utile che riepiloga le entrate e le uscite che si registrano in un determinato periodo. Può essere consuntivo o preventivo: il consuntivo è un riassunto di ciò che è successo in un arco di tempo passato, mentre il preventivo cerca di immaginare e descrivere il futuro e viene solitamente denominato budget, dal francese bougette, borsellino.

  Un budget familiare e personale contiene quindi l’insieme delle spese e delle entrate previste in un determinato periodo di tempo futuro.

  Se il bilancio di un periodo passato può essere utile per capire come sono andate le cose, il budget è invece utile per capire se possiamo permetterci certe spese o no, se dobbiamo cercare di guadagnare di più o rinunciare a qualcosa, o addirittura se possiamo risparmiare un po’ di soldi.`
}