import constants from '../../constants';
import colorSchemes from '../../color_schemes';

import page1 from './pages/01/section-01-page-01';
import page2 from './pages/02/section-01-page-02';
import page3 from './pages/03/section-01-page-03';
import page4 from './pages/04/section-01-page-04';
import page5 from './pages/05/section-01-page-05';

export default {
  id: 'SECT-01',

  palette: colorSchemes['pink'],

  first_page: false,
  base_path: '/scuole/elementare',

  name: 'Scuola Elementare',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/001_0002_1.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 2
      },
      {
        image: '/images/section-01/layers/animate/001_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-01/layers/animate/001_0000_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/001_0003_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      }
    ]
  },
  animation_type: 'playful',

  page_content: {
    heading: `Vai alla Scuola Elementare?`,
    paragraph: `Clicca e scopri tante curiosità e informazioni su denaro e risparmio, in compagnia di quattro simpatici amici e del loro buffo cane!`
  },

  pages: [
    page1,
    page2,
    page3,
    page4,
    page5
  ],

  outro_image: '/images/section-01/outro.png'
};