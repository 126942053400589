import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-01-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/307.png',

  heading: `Del doman non v'è certezza`,
  paragraph: `Qualunque sia il nostro reddito oggi, non è detto che il prossimo mese rimarrà lo stesso! L’incertezza sulle nostre entrate dipende da numerosi fattori: potremmo avere un lavoro che come quello di Omar ci dà un reddito variabile, potremmo cambiare lavoro, potremmo dover cedere un immobile che oggi ci dà una rendita e così via.

  Non possiamo eliminare l’incertezza sul nostro reddito, ma possiamo imparare a gestirla. Come? Adottando comportamenti prudenti e orientati al futuro. Ad esempio, distinguendo tra le spese che possiamo o non possiamo permetterci, evitando queste ultime. E cercando, quando possibile, di risparmiare qualcosa.`
}