import React from 'react';
import { Link } from 'react-router-dom';

import Settings from '../config/Settings';

import BANKITALIA from '../BANKITALIA';

import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';

class TeachersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    (function($) {
      $(document).ready(function() {
        BANKITALIA.init();
      });
    })(window.jQuery);
  }

  render() {
    return (
      <div className={Settings.pageFXClass + ' ' + Settings.palettes.blue}>
        <div className="bankitalia-app bankitalia-app--teachers">
          <Header>
            <Navbar />
          </Header>

          <div className="container bankitalia-main bankitalia-info-page">
            <div className="row bankitalia-main__row bankitalia-main__row--heading  js-lazy">
              <div className="col">
                <h1 className="bankitalia-title bankitalia-title--alt-3">Sezione Insegnanti</h1>
              </div>
            </div>

            <div className="row bankitalia-main__row bankitalia-main__row--desc js-lazy">
              <div className="col">
                <p className="text-center">
                  <img src="/images/teachers/intro.png" className="w-100 w-lg-50" style={{maxWidth: '500px', position: 'relative', left: '50%', transform: 'translateX(-50%)'}} />
                </p>

                <p>Gentile insegnante,<br />
                ti diamo il benvenuto nella sezione di “Tutti per uno economia per tutti!” a supporto del progetto Educazione finanziaria nelle scuole della Banca d’Italia a voi dedicata.</p>

                <p>In questa sezione troverai la collana “Tutti per uno economia per tutti!” in formato elettronico. Sono disponibili un fascicolo per gli studenti e una guida per i docenti per la scuola primaria e per quella secondaria di primo e secondo grado.</p>

                <p>I volumi per gli studenti sono sviluppati a partire dalle vicende di cinque personaggi, Omar, Nina, Isotta, Teo e il cane Attila, che vivono “esperienze finanziarie” simili a quelle che i giovani possono affrontare nella vita quotidiana. Passando da un livello scolastico all’altro, tali esperienze si modificano, così come cambiano d’aspetto i personaggi della storia, per risultare, le une e gli altri, più immediatamente riconoscibili da parte degli studenti e per permettere loro di comprendere in che modo l’educazione finanziaria possa essere importante per affrontare i problemi quotidiani nelle diverse fasi della vita.</p>

                <p>Le guide per gli insegnanti hanno l’obiettivo di fornire ai docenti strumenti per affrontare con le proprie classi i temi più importanti legati all’economia e alla finanza. Le guide ripercorrono i volumi per gli studenti in modo più ricco e strutturato. Oltre alla trattazione teorica, esse propongo attività utili a rendere divertente e stimolante l’apprendimento. Le guide offrono inoltre spunti e suggerimenti per creare collegamenti tra le materie curricolari e l’educazione finanziaria, per aggiungere la prospettiva economica allo studio delle varie materie.</p>
                <p>Potrai inoltre trovare altro materiale utile per approfondire i temi di economia e finanza con gli studenti: raccolta di video, giochi e altre informazioni.</p>

              </div>
            </div>

            {/* Scuola Primaria */}
            <div className="row bankitalia-main__row bankitalia-main__row--desc2 js-lazy" id="scuola-elementare">
              <div className="col-md-10 col-lg-8">
                <h2 className="bankitalia-title bankitalia-title-h2">Volumi didattici</h2>
                <h3 className="bankitalia-title bankitalia-title-h3">Scuola Primaria</h3>

                <br />
                <br />

                <div className="js-lazy">
                  <div className="row">
                    <div className="col-6 col-md-4 mt-4 mt-md-0">
                      <a href="https://papermine.com/booklet/tuttiperunoeconomiapertutti-primaria/" target="_BLANK">
                        <img class="img-responsive" src="/images/teachers/icons/covers/1-1.png" /><br />
                        <h4 style={{textAlign: 'center'}}>Volume studenti</h4>
                      </a>
                    </div>
                    <div className="col-6 col-md-4 mt-4 mt-md-0">
                      <a href="https://papermine.com/booklet/tuttiperunoeconomiapertutti-guidainsegnanti-primaria1/" target="_BLANK">
                        <img class="img-responsive" src="/images/teachers/icons/covers/1-2.png" /><br />
                        <h4 style={{textAlign: 'center'}}>Guida insegnanti</h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Scuola Secondaria di I grado */}
            <div className="row bankitalia-main__row bankitalia-main__row--desc2 js-lazy" id="scuola-media">
              <div className="col-md-10 col-lg-8">
                <h3 className="bankitalia-title bankitalia-title-h3">Scuola Secondaria di I grado</h3>

                <br />
                <br />

                <div className="js-lazy">
                  <div className="row">
                    <div className="col-6 col-md-4 mt-4 mt-md-0">
                      <a href="https://papermine.com/booklet/tuttiperunoeconomiapertutti-secondaria1/" target="_BLANK">
                        <img class="img-responsive" src="/images/teachers/icons/covers/2-1.png" /><br />
                        <h4 style={{textAlign: 'center'}}>Volume studenti</h4>
                      </a>
                    </div>
                    <div className="col-6 col-md-4 mt-4 mt-md-0">
                      <a href="https://papermine.com/booklet/tuttiperunoeconomiapertutti-guidainsegnanti-secondaria1/" target="_BLANK">
                        <img class="img-responsive" src="/images/teachers/icons/covers/2-2.png" /><br />
                        <h4 style={{textAlign: 'center'}}>Guida insegnanti</h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Scuola Secondaria di II grado */}
            <div className="row bankitalia-main__row bankitalia-main__row--desc2 js-lazy" id="scuola-superiore">
              <div className="col-md-10 col-lg-8">
                <h3 className="bankitalia-title bankitalia-title-h3">Scuola Secondaria di II grado</h3>

                <br />
                <br />

                <div className="js-lazy">
                  <div className="row">
                    <div className="col-6 col-md-4 mt-4 mt-md-0">
                      <a href="https://papermine.com/booklet/tuttiperunoeconomiapertutti-secondaria2/" target="_BLANK">
                        <img class="img-responsive" src="/images/teachers/icons/covers/3-1.png" /><br />
                        <h4 style={{textAlign: 'center'}}>Volume studenti</h4>
                      </a>
                    </div>
                    <div className="col-6 col-md-4 mt-4 mt-md-0">
                      <a href="https://papermine.com/booklet/tuttiperunoeconomiapertutti-guidainsegnanti-secondaria2/" target="_BLANK">
                        <img class="img-responsive" src="/images/teachers/icons/covers/3-2.png" /><br />
                        <h4 style={{textAlign: 'center'}}>Guida insegnanti</h4>
                      </a>
                    </div>
                    <div className="col-6 col-md-4 mt-4 mt-md-0">
                      <a href="https://papermine.com/booklet/34007325/" target="_BLANK">
                        <img class="img-responsive" src="/images/teachers/icons/covers/3-3.png" /><br />
                        <h4 style={{textAlign: 'center'}}>Sostenibilità ambientale</h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bankitalia-separator js-lazy"></div>

            {/* Video */}
            <div className="row bankitalia-main__row bankitalia-main__row--desc2 js-lazy" id="scuola-superiore">
              <div className="col-md-10 col-lg-8">
                <h2 className="bankitalia-title bankitalia-title-h2">Materiale per approfondire</h2>
                <h3 className="bankitalia-title bankitalia-title-h3">Video</h3>

                <div className="js-lazy mt-4">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="bankitalia-teachers-par">
                        <a href="https://economiapertutti.bancaditalia.it/video/interviste-1/video-1588856325333/" target="_BLANK">
                          <img style={{width: '80px'}} src="/images/teachers/icons/video.png" /><br />
                          <strong>Video La cultura finanziaria dei quindicenni in Italia.</strong>
                        </a>
                      </p>
                      <p className="bankitalia-teachers-par">L’Invalsi commenta i dati sulla alfabetizzazione finanziaria degli studenti quindicenni rilevati dall'indagine OCSE-PISA 2018.</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="bankitalia-teachers-par">
                        <a href="https://economiapertutti.bancaditalia.it/video/storie-di-vita/video-1573238263699/" target="_BLANK">
                          <img style={{width: '80px'}} src="/images/teachers/icons/video.png" /><br />
                          <strong>Video Occhio alle scelte</strong>
                        </a>
                      </p>
                      <p className="bankitalia-teachers-par">Una serie di video che descrivono, con un po' di ironia, scelte di gestione del denaro con le quali ciascuno di noi si confronta nella vita.</p>
                    </div>
                  </div>
                </div>

                <div className="js-lazy mt-4">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="bankitalia-teachers-par">
                        <a href="https://www.youtube.com/watch?v=XfSs4hfaur4" target="_BLANK">
                          <img style={{width: '80px'}} src="/images/teachers/icons/video.png" /><br />
                          <strong>Video Bitcoin</strong>
                        </a>
                      </p>
                      <p className="bankitalia-teachers-par">La Banca d’Italia parla di cryptoasset a Superquark.</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="bankitalia-teachers-par">
                        <a href="https://youtu.be/L1QzYOt5gSU" target="_BLANK">
                          <img style={{width: '80px'}} src="/images/teachers/icons/video.png" /><br />
                          <strong>Video Visita guidata al Museo della Moneta della Banca d’Italia</strong>
                        </a>
                      </p>
                      <p className="bankitalia-teachers-par">Il Museo della Moneta della Banca d’Italia ti accompagna in una visita del proprio patrimonio numismatico.</p>
                    </div>
                  </div>
                </div>

                <div className="js-lazy mt-4">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="bankitalia-teachers-par" >
                        <a href="https://economiapertutti.bancaditalia.it/notizie/tutti-per-uno-economia-per-tutti-video-e-slides-dell-evento/" target="_BLANK">
                          <img style={{width: '80px'}} src="/images/teachers/icons/video.png" /><br />
                          <strong>Tutti per uno economia per tutti!</strong>
                        </a>
                      </p>
                      <p className="bankitalia-teachers-par">La Banca d’Italia presenta i volumi Tutti per uno economia per tutti! realizzati a supporto del progetto Educazione finanziaria nelle scuole.</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="bankitalia-teachers-par" >
                        <a href="https://www.youtube.com/watch?v=I8FbiNB65rQ&list=PLEqSZM0j_JP4LczryfXbXmK68pf27DtB9&index=17" target="_BLANK">
                          <img style={{width: '80px'}} src="/images/teachers/icons/video.png" /><br />
                          <strong>Video progetto Saper(e)Consumare</strong>
                        </a>
                      </p>
                      <p className="bankitalia-teachers-par">Il progetto promosso e finanziato dal Ministero dello Sviluppo Economico vede la partecipazione anche della Banca d’Italia per la realizzazione di webinar nell’ambito dell’educazione finanziaria.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bankitalia-separator js-lazy"></div>

            {/* Giochi */}
            <div className="row bankitalia-main__row bankitalia-main__row--desc2 js-lazy" id="scuola-superiore">
              <div className="col-md-10 col-lg-8">
                <h2 className="bankitalia-title bankitalia-title-h2">Giochi</h2>

                <br />
                <br />

                <div className="js-lazy">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <a href="https://economiapertutti.bancaditalia.it/crucipuzzle/index.html" target="_BLANK">
                        <img style={{width: '80px'}} src="/images/teachers/icons/games.png" /><br />
                        Gioca ai nostri crucipuzzle e scopri le caratteristiche dei principali prodotti bancari e finanziari o alcune curiosità.
                      </a>
                    </div>
                    <div className="col-12 col-md-6">
                      <a href="https://economiapertutti.bancaditalia.it/quiz/index.html" target="_BLANK">
                        <img style={{width: '80px'}} src="/images/teachers/icons/quiz.png" /><br />
                        Quanto ne sai o pensi di saperne su temi economici e finanziari? Mettiti alla prova con i nostri quiz!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bankitalia-separator js-lazy"></div>

            {/* Etc. */}
            <div className="row bankitalia-main__row bankitalia-main__row--desc2 js-lazy" id="scuola-superiore">
              <div className="col-md-10 col-lg-8">
                <h2 className="bankitalia-title bankitalia-title-h2">Altro materiale</h2>

                <br />
                <br />

                <div className="js-lazy">
                  <div className="row">
                    <div className="col-6 col-md-4">
                      <a href="/pdf/summary/Elementari.pdf" target="_BLANK">
                        <img style={{width: '120px'}} src="/images/teachers/icons/book.png" /><br />
                        <h4>Estratto in versione testuale dei contenuti della sezione studenti per la scuola primaria</h4>
                      </a>
                    </div>
                    <div className="col-12 col-md-4">
                      <a href="/pdf/summary/Superiori.pdf" target="_BLANK">
                        <img style={{width: '120px'}} src="/images/teachers/icons/book.png" /><br />
                        <h4>Estratto in versione testuale dei contenuti della sezione studenti per  la scuola secondaria  di II grado</h4>
                      </a>
                    </div>
                    <div className="col-6 col-md-4">
                      <a href="/pdf/summary/Medie.pdf" target="_BLANK">
                        <img style={{width: '120px'}} src="/images/teachers/icons/book.png" /><br />
                        <h4>Estratto in versione testuale dei contenuti della sezione studenti per  la scuola secondaria  di I grado</h4>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4">
                      <a href="https://aulascienze.scuola.zanichelli.it/speciali-scienze/speciale-educazione-finanziaria" target="_BLANK">
                        <img style={{width: '120px'}} src="/images/teachers/icons/book.png" /><br />
                        <h4>La Banca d’Italia ha realizzato in collaborazione con Zanichelli una serie di articoli, webinar e podcast per approfondire temi rilevanti di economia e finanza: inflazione, cripto-attività, pagamenti digitali, economia sostenibile e molti altri. Questi materiali sono arricchiti da esercizi per gli studenti e le studentesse delle scuole superiori.</h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <footer className="container bankitalia-footer">
            <div className="row bankitalia-footer__row">
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--logo">
                <Link className="bankitalia-logo" to="/">
                  <img src="/images/shared/bankitalia-logo.png" />
                </Link>
              </div>
              <div className="col-lg-6 bankitalia-footer__col bankitalia-footer__col--pagination">
                <div className="bankitalia-hero__pagination"></div>
              </div>
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--copyright">
                <p className="bankitalia-copyright">&copy; Copyright 2021 Banca d’Italia</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default TeachersPage;