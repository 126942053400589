import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/143.png',

  heading: `Attenti all’usura!`,
  paragraph: `Un buon credito è fondamentale per l’economia e in tutti i paesi la legge tutela debitori e creditori. I prestiti possono essere concessi solo da soggetti specificatamente autorizzati, come le banche, e il tasso di interesse non può superare un limite detto “tasso di usura”. Questo parametro, calcolato dalla Banca d’Italia per ogni diversa categoria di prestito, fissa le soglie oltre le quali un prestito è considerato troppo oneroso, quindi illegale.

  Ci sono però dei criminali che approfittano dei bisogni di persone in difficoltà, che non riescono a ottenere prestiti in banca perché non hanno abbastanza garanzie da dare: gli usurai. Talvolta gli usurai riciclano in questo modo i soldi della malavita. Impongono interessi più alti del mercato e ricorrono a ricatti e violenze quando le vittime non riescono a restituire il dovuto. Spesso finiscono per impadronirsi delle aziende e delle proprietà delle vittime, mandandole in rovina`
}