import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';

import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

import HotspotModal from './HotspotModal';

import BANKITALIA from '../../../BANKITALIA';

export default class ClickShowModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,

      currItemIndex: null
    };
  }

  componentDidMount() {
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });

    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  handleClick = (itemIndex) => {
    this.setState({
      currItemIndex: itemIndex
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });

    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    if (!hotspot) {
      return '';
    }

    const currItem = this.state.currItemIndex !== null ? hotspot.items[this.state.currItemIndex] : null;

    return (
      <>
        <div className={`HOTSPOT-DISCOVER bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
          <div className="bankitalia-circle__info-label magictime tinUpIn"><span>INFO</span></div>
        </div>

        <HotspotModal className="" open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="bankitalia-quiz">
              <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
              <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

              <div className="bankitalia-quiz__answers-wrapper">
                <div className="bankitalia-info">
                  {
                    currItem && (
                      <div className="bankitalia-info__answer">
                        <strong className="bankitalia-info__name">{currItem.term}</strong>
                        <p className="bankitalia-info__desc">{currItem.text}</p>
                      </div>
                    )
                  }

                  <div className="bankitalia-info__blocks">
                    {
                      hotspot.items.map((item, i) => (
                        <div className={`bankitalia-block ${this.state.currItemIndex === i && 'active'}`} onClick={(e) => this.handleClick(i)}>{item.term}</div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}