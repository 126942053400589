import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-05-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/140.png',

  heading: `Millenni di prestiti`,
  paragraph: `Il credito è la concessione di un prestito, con la fiducia che in futuro il denaro ci sia restituito: anche nel linguaggio comune “dare credito” significa fidarsi.

  Le banche raccolgono i soldi dei risparmiatori e li danno in prestito a chi ne ha bisogno per avviare un’attività, per creare un’azienda, per fare acquisti grandi o piccoli. E siccome le banche non prestano i propri soldi, ma quelli di noi risparmiatori, hanno il compito di prestarli “bene”, cioè facendo attenzione che chi li riceve possa restituirli, anche con gli interessi.

  Il credito era già diffuso nell’antichità, in Mesopotamia e Grecia, dove i templi gestivano anche il deposito di denaro e preziosi. Le prime leggi a tutela del prestito nacquero a Roma. La gestione del credito si diffuse soprattutto a partire dal XIV secolo: il genovese Banco di San Giorgio, nato nel 1407, è la più antica banca del mondo. Famosi banchieri dell’epoca furono i fiorentini Medici, Peruzzi, Bardi, che finanziarono le esplorazioni e le grandi opere del Rinascimento.`
}