import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-03-SL-01-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-03-SL-01-HOT-02';

export default {
  id: 'SECT-02–P-03-SL-01',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/121_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-02/animated/layers/121_0001_2.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/121_0002_3.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 2
      },
      {
        image: '/images/section-02/animated/layers/121_0003_4.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 3
      },
      {
        image: '/images/section-02/animated/layers/121_0004_5.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 4
      },
    ]
  },

  page_content: {
    heading: `Tanti modi per pagare`,
    paragraph: `Ci sono modi più comodi per pagare…`,
  },

  hotspots: [
    hotspot1,
    hotspot2
  ],

  extra_class: ''
};