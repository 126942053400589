import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-01-HOT-02',
  
  type: constants.HOTSPOT_TYPE_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `50 cent Germania`,
  paragraph: `Nelle monete da 10, 20 e 50 centesimi della Germania è rappresentata la famosa Porta di Brandeburgo, simbolo di Berlino.`
}