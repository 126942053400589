import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-01-HOT-04',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/114.png',

  heading: `Le tre funzioni della moneta`,
  paragraph: `La moneta è uno strumento di pagamento che serve per comprare merci, servizi e altro ed è accettato da chiunque e in qualunque luogo. La moneta è anche un’unità di conto: permette cioè di capire esattamente il valore di ogni cosa o di ogni servizio, facendo paragoni facili e immediati. La moneta è infine una riserva di valore che può essere conservata, permettendo dunque il risparmio.`
}