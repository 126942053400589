import React from 'react';
import { Link } from 'react-router-dom';

import Settings from '../config/Settings';

import BANKITALIA from '../BANKITALIA';

import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';

class AboutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    (function($) {
      $(document).ready(function() {
        BANKITALIA.init();
      });
    })(window.jQuery);
  }

  render() {
    return (
      <div className={Settings.pageFXClass + ' ' + Settings.palettes.blue}>
        <div className="bankitalia-app bankitalia-app--about">
          <Header>
            <Navbar />
          </Header>

          <div className="container bankitalia-main bankitalia-info-page">
            <div className="row bankitalia-main__row bankitalia-main__row--heading">
              <div className="col">
                <h1 className="bankitalia-title bankitalia-title--alt-3 js-lazy">Informazioni</h1>
                <div className="bankitalia-kids bankitalia-kids__layer-wrapper">
                  <div className="bankitalia-kids__main-layer">

                    <div className="bankitalia-kids__animated fromLeft">
                      <img className="bankitalia-kids__frame bankitalia-kids__frame--fastest" src="/images/about/animated/1-1.png" />
                    </div>
                    <div className="bankitalia-kids__animated fromLeft">
                      <img className="bankitalia-kids__frame bankitalia-kids__frame--fast" src="/images/about/animated/1-3.png" />
                    </div>
                    <div className="bankitalia-kids__animated fromLeft">
                      <img className="bankitalia-kids__frame bankitalia-kids__frame--fast" src="/images/about/animated/1-5.png" />
                    </div>
                    <div className="bankitalia-kids__animated fromTop">
                      <img className="bankitalia-kids__frame bankitalia-kids__frame--fast" src="/images/about/animated/1-2.png" />
                    </div>
                    <div className="bankitalia-kids__animated fromTop">
                      <img className="bankitalia-kids__frame bankitalia-kids__frame--fast" src="/images/about/animated/1-4.png" />
                    </div>
                  </div>
                </div>
                <div className="js-lazy">
                  <p>Benvenuto nella sezione di “Tutti per uno economia per tutti!” dedicata al progetto Educazione finanziaria nelle scuole promosso dalla Banca d’Italia in collaborazione col Ministero dell’Istruzione per favorire lo sviluppo dell’alfabetizzazione finanziaria nelle giovani generazioni.</p>
                  <p>In questa sezione troverai informazioni su come e perché è nato questo progetto, su quali sono le sue caratteristiche distintive e sul perché crediamo che l’educazione finanziaria sia importante per le giovani generazioni.</p>
                  <p>Buona lettura!</p>
                </div>
              </div>
            </div>

            <div className="bankitalia-separator js-lazy"></div>

            <div className="row bankitalia-main__row bankitalia-main__row--info">
              <div className="col-md-8 bankitalia-main__col bankitalia-main__col--text js-lazy">
                <h2 className="bankitalia-title bankitalia-title--alt-3">Perché l’educazione finanziaria e perché nelle scuole?</h2>
                <p>Le raccomandazioni e le esperienze nazionali e internazionali indicano che è importante familiarizzare con i concetti economici alla base delle scelte finanziarie fin da bambini, prima che giunga il momento della vita in cui vengono prese decisioni che possono incidere sul benessere economico.</p>
                <p>Acquisire da piccoli concetti essenziali come l’importanza di programmare e controllare la propria situazione economica, l’utilità del risparmio, la relazione tra rischio e rendimento, permetterà da adulti di effettuare le scelte finanziarie in maniera più serena e consapevole.</p>
                <p>La scuola costituisce un canale privilegiato per costruire la cultura finanziaria nei giovani perché consente di raggiungere tutti, senza distinzione di genere, condizione economica e area geografica di provenienza. Parlare di educazione finanziaria a scuola può anche favorire il dialogo sui temi economici dei giovani in famiglia, contesto in cui più che altrove si sviluppano queste competenze.</p>

              </div>
              <div className="col-md-4 bankitalia-main__col bankitalia-main__col--image js-lazy">
                <img src="/images/about/info-1.png" alt="" />
              </div>
            </div>

            <div className="bankitalia-separator js-lazy"></div>

            <div className="row bankitalia-main__row bankitalia-main__row--info">
              <div className="col-md-4 bankitalia-main__col bankitalia-main__col--image js-lazy">
                <img src="/images/about/info-2.png" alt="" />
              </div>
              <div className="col-md-8 bankitalia-main__col bankitalia-main__col--text js-lazy">
                <h2 className="bankitalia-title bankitalia-title--alt-3">Il progetto Educazione finanziaria nelle scuole</h2>
                <p>Educazione finanziaria delle scuole è nato nel 2007 da un accordo tra la Banca d’Italia e il Ministero dell’Istruzione. Da allora il progetto si è evoluto e rinnovato, in modo da venire incontro alle esigenze della scuola e rispondere ai mutamenti del contesto esterno.</p>
                <p>Il progetto è ispirato a una didattica per competenze e propone un approccio multidisciplinare; si caratterizza per l'offerta di percorsi formativi dedicati ai docenti di scuola primaria e secondaria di primo e secondo grado organizzati dal personale della Banca d'Italia sul territorio nazionale.</p>
                <p>Successivamente i docenti affrontano i temi economici e finanziari in classe con i loro studenti integrandoli nell'apprendimento curricolare con l’aiuto dei volumi “<a href="https://www.bancaditalia.it/pubblicazioni/quaderni-didattici/tuttixuno/index.html" target="_BLANK">Tutti per uno economia per tutti!</a>” per docenti e studenti.</p>
                <p>I docenti che partecipano agli incontri hanno diritto a richiedere l'esonero dall'attività di servizio e ricevono un attestato di partecipazione. Maggiori informazioni su come partecipare all'iniziativa possono essere richieste alle <a href="https://economiapertutti.bancaditalia.it/eventi/2022/elenco_contatti_regionali.pdf" target="_BLANK">Filiali della Banca d'Italia</a> o visitando <a href="https://economiapertutti.bancaditalia.it/progetti-educativi/edufin-scuole/" target="_BLANK">economiapertutti.bancaditalia.it</a>
                </p>
              </div>
            </div>

            <div className="bankitalia-separator js-lazy"></div>

            <div className="row bankitalia-main__row bankitalia-main__row--info">
              <div className="col-md-8 bankitalia-main__col bankitalia-main__col--text js-lazy">
                <h2 className="bankitalia-title bankitalia-title--alt-3">Le altre attività per la scuola</h2>
                <p>Oltre a Educazione finanziaria nelle scuole, la Banca d’Italia promuove numerose attività per gli studenti.</p>
                <p><strong>Inventiamo una banconota</strong> - Il Premio Inventiamo una banconota si propone di coinvolgere gli studenti delle scuole primarie e delle secondarie di primo e secondo grado in un progetto interdisciplinare, integrato nella programmazione didattica, che consiste nella realizzazione di un bozzetto di una banconota "immaginaria".
                Maggiori informazioni sono disponibili nel sito dedicato: <a href="https://premioscuola.bancaditalia.it/homepage/index.html" target="_BLANK">premioscuola.bancaditalia.it</a>.</p>
                <p><strong>Il mese dell’educazione finanziaria</strong> – Nell’ottobre di ogni anno la Banca d’Italia organizza attività per le scuole nell’ambito del Mese dell’educazione finanziaria, un’iniziativa promossa dal Comitato per la programmazione e il coordinamento delle attività di educazione finanziaria nel nostro paese.<br />
                Ulteriori informazioni: <a target="_BLANK" href="http://www.quellocheconta.gov.it/it/news-eventi/mese_educazione_finanziaria">quellocheconta.gov.it</a>
                </p>
                <p><strong>Global Money week</strong> – Nel mese di marzo la Banca d'Italia partecipa alla Global Money Week, una festa per aumentare la consapevolezza monetaria tra i ragazzi. Avviata da Child & Youth Finance International nel 2012, dal 2020 è organizzata dall'International Network for Financial Education (INFE) dell'OCSE.<br />
                Ulteriori informazioni: <a target="_BLANK" href="https://globalmoneyweek.org">globalmoneyweek.org</a>
                </p>
                <p><strong>Generation €uro Students’ Award</strong> – La Banca d’Italia, in coordinamento con la Banca Centrale europea, organizza la competizione di politica monetaria “Generation €uro Students’Award” per studenti degli ultimi due anni delle scuole superiori di qualunque indirizzo.
                Ulteriori informazioni: <a target="_BLANK" href="https://www.generationeuro.eu/it-it/home">www.generationeuro.eu</a>
                </p>
              </div>
              <div className="col-md-4 bankitalia-main__col bankitalia-main__col--image js-lazy">
                <img src="/images/about/info-3.png" alt="" />
              </div>
            </div>

            <div className="bankitalia-separator js-lazy"></div>

            <div className="row bankitalia-main__row bankitalia-main__row--disclaimer js-lazy">
              <div className="col">
                <h2 className="bankitalia-title bankitalia-title--alt-3">Disclaimer</h2>
                <p>Questo sito Internet completa l’offerta dei materiali didattici “Tutti per uno economia per tutti!” prodotti dalla Banca d’Italia a supporto del suo progetto Educazione finanziaria nelle scuole. In relazione all'interesse pubblico delle informazioni prodotte e diffuse su questo sito Internet e allo scopo di assicurare il più ampio e agevole accesso degli utenti, la Banca d’Italia cura l'aggiornamento del sito, l'accesso al quale è possibile 24 ore su 24. Al fine di agevolare la consultazione, la Banca d’Italia potrà fornire all'occorrenza particolari note tecniche, redatte sulla base delle conoscenze di cui via via dispone. Si precisa che con tali indicazioni tecniche la Banca d’Italia non intende in alcun modo fornire valutazioni di sorta su prodotti e/o società richiamati nel sito, restando gli utenti esclusivi responsabili dell'uso dei prodotti e dei rapporti che eventualmente instaureranno con le relative società.</p>
                <p>La Banca d'Italia non risponde degli eventuali danni causati dall'utilizzo non corretto dei contenuti (dati, informazioni, software ecc.) presenti su questo sito. Non assume, altresì, alcuna responsabilità per il contenuto di siti esterni collegati a questo sito, l'accesso ai quali è fornito come mero servizio agli utenti, senza che ciò implichi approvazione né alcuna forma di promozione o controllo dei siti stessi. </p>
              </div>
            </div>

            <div className="row bankitalia-main__row bankitalia-main__row--disclaimer js-lazy">
              <div className="col">
                <h2 className="bankitalia-title bankitalia-title--alt-3">Copyright</h2>
                <p>Il copyright e ogni altro diritto su questo sito spetta alla Banca d’Italia e tutti i contenuti e le informazioni presenti all’interno dello stesso sono protetti ai sensi della normativa sul diritto d’autore. La stampa e il salvataggio (su disco o su altri supporti di memorizzazione) dei contenuti di questo sito sono consentiti per solo uso personale, con esclusione di ogni utilizzo per fini di lucro o per trarne qualsivoglia utilità economica. Nessuna modifica è ammessa delle immagini e dei dati presenti su questo sito; neppure è consentita la riproduzione di questo sito o di parti di esso su altri siti Internet o su qualunque sistema informativo pubblico o privato, senza preventiva autorizzazione scritta della Banca.</p>
              </div>
            </div>

            <div className="row bankitalia-main__row bankitalia-main__row--disclaimer js-lazy">
              <div className="col">
                <h2 className="bankitalia-title bankitalia-title--alt-3">Privacy</h2>
                <p>Gli utenti non devono fornire i propri dati personali per consultare questo sito Internet.</p>

                <p>Cosa sono i Cookie - I Cookie sono brevi stringhe di codice che i siti visitati inviano al browser dell'utente (cioè il programma utilizzato per navigare come, ad esempio, Firefox oppure Internet Explorer) dove vengono memorizzati per essere poi ritrasmessi agli stessi siti durante le successive visite. Contestualmente, l'utente può ricevere sul proprio browser anche Cookie che vengono inviati da altri siti (c.d. "terze parti"). Questo sito utilizza cookie tecnici e di terze parti. Per ulteriori informazioni sui Cookie: <a href="https://www.bancaditalia.it/footer/privacy/" target="_BLANK">https://www.bancaditalia.it/footer/privacy/</a></p>
              </div>
            </div>

          </div>

          <footer className="container bankitalia-footer">
            <div className="row bankitalia-footer__row">
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--logo">
                <Link className="bankitalia-logo" to="/">
                  <img src="/images/shared/bankitalia-logo.png" />
                </Link>
              </div>
              <div className="col-lg-6 bankitalia-footer__col bankitalia-footer__col--pagination">
                <div className="bankitalia-hero__pagination"></div>
              </div>
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--copyright">
                <p className="bankitalia-copyright">&copy; Copyright 2021 Banca d’Italia</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default AboutPage;