import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/110.png',

  heading: `Cos’è il budget?`,
  paragraph: `Budget viene dal francese bougette, che significa “borsellino”. Nel linguaggio comune indica i soldi che abbiamo a disposizione in un certo momento. In termini tecnici è un bilancio di previsione, cioè un conto dove elenchiamo tutte le somme di denaro che contiamo di ricevere e tutte quelle che pensiamo di spendere in un certo periodo: le entrate e le uscite. Alcune sono dette “ricorrenti” perché arrivano periodicamente: come la paghetta che Nina ottiene tutte le settimane o la merenda che compra tutti i giorni di scuola. Altre sono straordinarie perché capitano occasionalmente, come 50 euro che le ha regalato la zia per il compleanno o il biglietto di un concerto a cui è andata lo scorso anno. Il budget si riferisce a un periodo futuro, per esempio il mese prossimo, e si chiama anche bilancio preventivo. Alla fine di un periodo, può essere utile fare un bilancio consuntivo, per vedere com’è andata, se le nostre previsioni si sono realizzate.`
}