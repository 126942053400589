import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Omar sta scegliendo una tavoletta di cioccolato in un negozio. Collega ogni informazione sul pacchetto a un buon motivo per controllarla.`,

  items: [
    {
      text: `Per capire a chi fare reclamo in caso di problemi...`,
      term: 'I recapiti del produttore'
    },
    {
      text: 'Per capire se è di buona qualità...',
      term: 'Gli ingredienti'
    },
    {
      text: 'Per capire quanto può far ingrassare...',
      term: 'I valori nutrizionali'
    },
    {
      text: 'Per capire se potrebbe essere andata a male...',
      term: 'La data di scadenza'
    },
    {
      text: 'Per capire se la confezione va nella raccolta differenziata...',
      term: 'Simboli, icone e sigle'
    },
    {
      text: 'Per capire quale prodotto conviene a parità di prezzo...',
      term: 'Il peso'
    },
  ],
  extra_terms: [],

  points: 1,

  feedback: {
    correct: `Congratulazioni! Potresti scrivere il manuale del perfetto consumatore.`,
    wrong: `Ahi ahi ahi... Ti conviene informarti meglio o potresti avere qualche problema con le cose che compri.`
  }
}