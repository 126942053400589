import React from 'react';
import { Link } from 'react-router-dom';

import MarkdownParser from '../components/shared/MarkdownParser';

import Settings from '../config/Settings';

import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import AnimatedIllustration from '../components/shared/AnimatedIllustration';

import BANKITALIA from '../BANKITALIA';
import PageNavbar from '../components/sections/PageNavbar';
import SidebarNav from '../components/sections/SidebarNav';

class StudentSectionIntroPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    (function($) {
      $(document).ready(function() {
        BANKITALIA.init();
      });
    })(window.jQuery);
  }

  render() {
    const { section, page } = this.props;

    document.getElementsByTagName("html")[0].scrollTop = 0

    return (
      <div className={Settings.pageFXClass + ' ' + section.palette}>
        <div className="bankitalia-app bankitalia-app--start">
          <Header>
            <PageNavbar section={section} page={page} />
            <SidebarNav section={section} page={page} />
          </Header>

          <div className="container bankitalia-main">
            <div className="row bankitalia-teaser">
              <div className="col-sm-10 col-md-6 col-xl-7 bankitalia-teaser__col bankitalia-teaser__col--pic">
                {
                  page.illustration && page.illustration.layers && page.illustration.layers.length ? <AnimatedIllustration layers={page.illustration.layers} animationType={section.animation_type} /> : <img src={page.featured_image} />
                }
              </div>
              <div className="col-sm-10 col-md-6 col-xl-5 bankitalia-teaser__col bankitalia-teaser__col--desc">
                <h2 className="bankitalia-title">{page.page_content.heading}</h2>
                <MarkdownParser source={page.page_content.paragraph} />

                <Link className="bankitalia-ico-btn bankitalia-ico-btn--negative" to={`${page.base_path}/gioca/`}>
                  <img className="bankitalia-btn__icon" src="/images/icons/arrow-2.png" />
                </Link>
              </div>
            </div>
          </div>

          <footer className="container bankitalia-footer">
            <div className="row bankitalia-footer__row">
              <div className="col-sm-6 bankitalia-footer__col bankitalia-footer__col--logo">
                <Link className="bankitalia-logo" to="/">
                  <img src="/images/shared/bankitalia-logo.png" />
                </Link>
              </div>
              <div className="col-sm-6 bankitalia-footer__col bankitalia-footer__col--copyright">
                <p className="bankitalia-copyright">&copy; Copyright 2021 Banca d’Italia</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default StudentSectionIntroPage;