import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';

import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

import PointsUtil from '../../../utils/PointsUtil';

import HotspotModal from './HotspotModal';

import BANKITALIA from '../../../BANKITALIA';


export default class AssocModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,

      items: [],
      terms: [],
      selTerms: [],

      answered: false,
      correct: null,
      submitted: false
    };
  }

  componentDidMount() {
    if (this.props.hotspot && this.props.hotspot.items) {
      let items = JSON.parse(JSON.stringify(this.props.hotspot.items));

      let terms = items.map(item => item.term).concat(this.props.hotspot.extra_terms);
      items = items.map((item) => {
        item.term = '';

        return item;
      });

      // shuffle
      terms.sort(() => Math.random() - 0.5);

      this.setState({
        items,

        terms
      });
    }


    // (function($) {
    //   $(document).ready(function() {
    //     BANKITALIA.drag.init();
    //   });
    // })(window.jQuery);
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });

    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");

      const modal = document.getElementsByClassName("modal")[0];

      modal.addEventListener("scroll", (e) => {
        const blocks = modal.querySelectorAll(".ddcontainerghost");

        blocks.forEach((block) => {
          block.style.transform = "translateY(" + modal.scrollTop + "px)"
        })
      })
    }, 1200)
  }

  handleDrop = (itemText, droppedTerm) => {
    const items = this.state.items.map((item) => {
      if (item.text == itemText) {
        item.term = droppedTerm;
      }

      return item;
    });

    let selTerms = this.state.selTerms;
    selTerms.push(droppedTerm);

    this.setState({
      items,

      selTerms
    }, () => {
      let answered = true;

      this.state.items.forEach((item) => {
        if (item.term == '') {
          answered = false
        }
      });

      this.setState({
        answered
      });
    });
  }

  handleSubmit = () => {
    const { hotspot } = this.props;

    if (!this.state.answered) {
      console.warn('items left');

      return;
    }
    if (this.state.submitted) {
      console.warn('Already submitted');

      return;
    }

    // see if correct
    let correct = true;
    this.state.items.forEach((item, i) => {
      if (item.term != this.props.hotspot.items[i].term) {
        correct = false;
      }
    });

    this.setState({
      submitted: true,
      correct: correct
    }, () => {
      // update score
      const shouldAddPoints = correct;
      const numPoints = hotspot.points;
      if (shouldAddPoints) {
        PointsUtil.addPoints(hotspot, numPoints);
      }
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });
    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    return (
      <>
        <div className={`HOTSPOT-ASSOC bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal className="" open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 bankitalia-modal__col bankitalia-modal__col--question">
                <div className="bankitalia-quiz">
                  <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
                  <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

                  <div className="bankitalia-quiz__answers-wrapper">
                    <div class="bankitalia-drag">
                      <div class="bankitalia-drag__answers-wrapper">
                        {
                          this.state.items.map(item => (
                            <div className="mb-4">
                            <DropTarget targetKey="bankitalia_AssocModal_elem" onHit={(term) => this.handleDrop(item.text, term.dragData)}>
                              <div class="bankitalia-drag__answer">
                                <div class="bankitalia-drag__answer__field">
                                  {
                                    item.term && <div class="bankitalia-block">{item.term}</div>
                                  }
                                </div>
                                <p class="bankitalia-drag__answer__txt">{item.text}</p>
                              </div>
                            </DropTarget>
                            </div>
                          ))
                        }
                      </div>
                      <div class="bankitalia-drag__blocks">
                        {
                          this.state.terms && this.state.terms.map(term => (
                            <>
                              {
                                this.state.selTerms.indexOf(term) === -1 ? (
                                  <DragDropContainer targetKey="bankitalia_AssocModal_elem" dragData={term}>
                                    <div class="bankitalia-block">{term}</div>
                                  </DragDropContainer>
                                ) : (
                                  <div class="bankitalia-block bankitalia-block--ghost"></div>
                                )
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                  </div>

                  {/* Submit button */}
                  <div className="bankitalia-quiz__actions">
                    <button className={`bankitalia-btn ${(!this.state.answered || this.state.submitted) && 'disabled'}`} type="button" onClick={this.handleSubmit}>
                      <span className="bankitalia-btn__label">invia</span>
                    </button>
                  </div>

                  {/* Feedback message (classes are "success", "error") */}
                  {
                    this.state.submitted && (
                      <div className="bankitalia-quiz__message magictime slideDownReturn">
                        <div className={`bankitalia-message bankitalia-message--${this.state.correct ? 'success' : 'error'}`}>
                          <p>
                            {this.state.correct ? hotspot.feedback.correct : hotspot.feedback.wrong}<br />
                            { this.state.correct && <>Hai vinto {hotspot.points} {hotspot.points > 1 ? 'punti' : 'punto'}</>}
                          </p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}