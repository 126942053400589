import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: 'Bisogni o desideri?',
  paragraph: `I bisogni sono quelle necessità che dobbiamo soddisfare per forza, i desideri invece vanno soddisfatti solo quando ce lo possiamo permettere. Sai distinguere in questa lista i bisogni dai desideri?`,

  label: 'BISOGNO?',

  items: [
    {
      term: 'Omar vuole un quinto paio di scarpe da ginnastica, ma stavolta il modello firmato dal suo sportivo preferito.',
      sum_cond_value: false
    },
    {
      term: 'Nina vuole comprare i libri che la professoressa ha assegnato da leggere in vacanza.',
      sum_cond_value: true
    },
    {
      term: 'Isotta vuole una giacca pesante per l’inverno, perché è cresciuta e non ne ha una della sua taglia.',
      sum_cond_value: true
    },
    {
      term: 'Nina vuole fare una gita a Siena per visitarne i monumenti.',
      sum_cond_value: false
    },
    {
      term: 'Teo vuole un gelato perché sente di non resistere se non lo mangia subito.',
      sum_cond_value: false
    },
    {
      term: 'Omar vuole uno zainetto nuovo per i libri di scuola, visto che il suo si è rotto.',
      sum_cond_value: true
    },
  ],

  points: 1,

  feedback: {
    correct: `Bravo! Hai 3 risposte giuste! Tu sì che sai capire quali sono le spese davvero necessarie.`,
    wrong: `Non ci siamo molto… Prova a riflettere di più su cosa serve davvero e a cosa si potrebbe rinunciare.`
  }
}