import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-01-HOT-05',
  
  type: constants.HOTSPOT_TYPE_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `5 cent Spagna`,
  paragraph: `Tutte le monete da 1, 2 e 5 centesimi della Spagna hanno come immagine la cattedrale di Santiago di Compostela, famosissima per un cammino di pellegrinaggio cristiano.`
}