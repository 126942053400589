import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/122.png',

  heading: `Senza un soldo in tasca`,
  paragraph: `Omar ha una tasca bucata e finisce sempre per perdere monete e banconote. Per fortuna può evitare di portarsi dietro il denaro contante, perché può tenere i soldi in banca, su un conto corrente. A quel punto ha vari modi per pagare , ad esempio...

  I bonifici sono operazioni con cui ordiniamo alla banca di spostare dei soldi dal nostro conto a un altro: basta indicare l’IBAN (International Bank Account Number) del destinatario, un codice che identifica ogni conto e che in Italia è di 27 caratteri. Possiamo fare i bonifici anche da computer o cellulare grazie all’homebanking, un sistema che ci permette di gestire il nostro conto ovunque siamo.
  
  E poi ci sono le carte di pagamento: di debito, di credito e prepagate... Ma Omar dovrebbe proprio farsi ricucire la tasca, altrimenti perderà anche quelle!`
}