import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-02-SL-03-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-02-SL-03-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-02-SL-03-HOT-03';

export default {
  id: 'SECT-02–P-02-SL-03',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/117_0001_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/117_0000_2.png',
        animation: 'playful',
        from: 'left',
        speed: 'xxxxx', // disable animation
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `In cambio dei soldi`,
    paragraph: `Il mio orologio vale 20 €. Ma quante ciliegie posso comprare con il mio orologio?`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};