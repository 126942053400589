import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-04-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_INVESTMENT_IDEAS,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Baby Brokers`,
  paragraph: `Hai accumulato 100 euro in risparmi, una banconota da 50 euro e 5 banconote da 10 euro (wow, sei stato bravo!), ora devi trovare tra queste idee simpatiche quelle che ti sembrano più promettenti e sicure per far fruttare i tuoi soldi. Puoi scegliere quanti soldi investire in ciascun progetto.`,

  starting_balance: 100,

  ideas: [
    {
      descr: `Il tuo migliore amico, dice di avere un'idea geniale: vuole costruire una bicicletta in cui due persone pedalano contemporaneamente, metà fatica, doppio divertimento! Ti promette di restituire il tuo investimento con un interesse del 20%.`,
      feedback: `Il tuo amico non si era troppo informato, la bicicletta per due si chiama tandem! Ed esiste già! Beh, non è stato un buon investimento, il tuo amico ti ha restituito solo i 3/4 dei soldi investiti.`,
      payoff_multiplier: 0.75
    },
    {
      descr: `Un genitore si offre di mettere in banca i tuoi soldi in un deposito a tempo. Ti promette che dopo un anno riceverai un interesse del 2%.`,
      feedback: `Se depositi i soldi in banca l'interesse non è alto, ma non ci sono sorprese!`,
      payoff_multiplier: 1.02
    },
    {
      descr: `Un amico ti propone di entrare in società con lui e di organizzare una grande festa all'aperto. C'è un po' di rischio ma organizzerà tutto lui e ti restituirà un interesse del 10% sulla somma che investirai. `,
      feedback: `La festa all'aperto non è stata un successo. Quel giorno il cielo era pieno di nuvole e il tuo amico ha venduto pochi biglietti. Alla fine il tuo investimento ha fruttato solo l'1%!`,
      payoff_multiplier: 1.01
    },
    {
      descr: `Un tuo parente ti dà un suggerimento: "Perché non li nascondi sotto il materasso? Saranno al sicuro lì!".`,
      feedback: `Attila ha mangiato tutte le banconote che avevi messo sotto il materasso.`,
      payoff_multiplier: 0
    }
  ]
}