import slide1 from './slides/01/SECT-02–P-05-SL-01';
import slide2 from './slides/02/SECT-02–P-05-SL-02';
import slide3 from './slides/03/SECT-02–P-05-SL-03';

export default {
  id: 'SECT-02–P-05',

  first_page: false,
  base_path: '/scuole/media/buone-pessime-idee',

  name: `Buone e pessime idee`,

  icon: '/images/section-02/icons/page-05.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/138_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Buone e pessime idee`,
    paragraph: `Omar e Nina sognano di creare un videogioco sull’esplorazione spaziale, Isotta e Teo vorrebbero aprire un sushi-bar libreria... Le idee per il futuro non mancano, servono solo i soldi per partire. Ma come si fa a trovarli?`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};