import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/344.png',

  heading: `Nelle mani sbagliate`,
  paragraph: `Chiedere un prestito, ovvero accedere al credito, impegna il nostro futuro. Per piccole somme possiamo pensare anche di rivolgerci a parenti e amici, ma quando le somme sono importanti bisogna rivolgersi al mercato “ufficiale” del credito. In ogni mercato esistono delle leggi che proteggono chi vi opera, stabilendo che cosa è permesso e cosa non lo è. In Italia, lo Stato stabilisce chi è abilitato a erogare credito e qual è il tasso di interesse massimo che può applicare.

  Per legge il tasso di interesse di un prestito non può superare un limite massimo chiamato “tasso di usura”, calcolato dalla Banca d’Italia per ogni diversa categoria di finanziamento. Si calcola sul tasso medio di mercato per quel tipo di prestiti, aumentandolo di un quarto e sommando 4 punti percentuali. Se per esempio in un certo momento i mutui hanno un tasso medio del 6%, il tasso di usura per i mutui è 6% aumentato di un quarto, cioè 7,5%, più 4 punti e quindi 11,5%.
  Chi pratica tassi di interesse superiori alle soglie stabilite commette il reato di usura.

  Gli usurai sono criminali che prestano denaro illegalmente approfittando dei bisogni di persone in difficoltà, che non riescono a ottenere prestiti in banca perché non hanno un buon merito creditizio. Talvolta riciclano in questo modo i soldi della malavita. Impongono interessi più alti del mercato e ricorrono a ricatti e violenze quando le vittime non riescono a restituire il dovuto. Spesso finiscono per impadronirsi delle aziende e delle proprietà delle vittime, mandandoli in rovina.`
}