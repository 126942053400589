import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';

import PointsUtil from '../../../utils/PointsUtil';

import HotspotModal from './HotspotModal';

export default class MultipleChoiceModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,

      answer: null,
      submitted: false
    };
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });

    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  handleSelect = (i, e) => {
    e.stopPropagation();

    if (this.state.submitted) {
      e.preventDefault();

      return false;
    }

    this.setState({
      answer: i
    });
  }

  handleSubmit = () => {
    if (this.state.submitted) {
      console.warn('Already submitted');

      return;
    }

    const { hotspot } = this.props;

    this.setState({
      submitted: true
    }, () => {
      // update score
      const shouldAddPoints = hotspot.answers[this.state.answer].points > 0;
      const numPoints = hotspot.answers[this.state.answer].points;
      if (shouldAddPoints) {
        PointsUtil.addPoints(hotspot, numPoints);
      }
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });

    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    return (
      <>
        <div className={`HOTSPOT-MULTICHOICE bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="row">

              {/* Hint(s) */}
              <div className="col-lg-3 bankitalia-modal__col bankitalia-modal__col--tips">
                <div className="bankitalia-tips">
                  {
                    hotspot.hint && hotspot.hint.message && (
                      <>
                        <div className="bankitalia-tips__box">
                          <p>{hotspot.hint.message}</p>
                        </div>

                        { hotspot.hint.image && <img src={hotspot.hint.image} /> }
                      </>
                    )
                  }
                </div>
              </div>

              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-0 bankitalia-modal__col bankitalia-modal__col--question">
                <div className="bankitalia-quiz">
                  <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
                  <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

                  <div className="bankitalia-quiz__answers-wrapper">
                    <ul className="bankitalia-quiz__answers">
                      {
                        hotspot.answers.map((answer, i) => {
                          const inputId = `MultipleChoiceModal_ans_${i}`;

                          return (
                          <li className="bankitalia-quiz__answer">
                            <div className="bankitalia-answer">
                              <input className="bankitalia-answer__input" type="radio" name="answer" id={inputId} onClick={(e) => this.handleSelect(i, e)} />
                              <label className="bankitalia-answer__label" for={inputId}>{answer.text}</label>
                            </div>
                          </li>
                          );
                        })
                      }
                    </ul>
                  </div>

                  {/* Submit button */}
                  <div className="bankitalia-quiz__actions">
                    <button className={`bankitalia-btn ${(this.state.answer === null || this.state.submitted) && 'disabled'}`} type="button" onClick={this.handleSubmit}>
                      <span className="bankitalia-btn__label">invia</span>
                    </button>
                  </div>

                  {/* Feedback message (classes are "success", "error") */}
                  {
                    this.state.submitted && (
                      <div className="bankitalia-quiz__message magictime slideDownReturn">
                        <div className={`bankitalia-message bankitalia-message--${hotspot.answers[this.state.answer].feedbackType}`}>
                          <p>
                            {hotspot.answers[this.state.answer].feedback}<br />
                            { hotspot.answers[this.state.answer].points > 0 && <>Hai vinto {hotspot.answers[this.state.answer].points} {hotspot.answers[this.state.answer].points > 1 ? 'punti' : 'punto'}</>}
                          </p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}