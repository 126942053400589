var BANKITALIA = BANKITALIA || {
  options: {
    swiper: {}
  }
};

BANKITALIA.init = function (options) {
  // set options
  if (options !== undefined && options.swiper !== undefined && options.swiper.heroCallback !== undefined) {
    BANKITALIA.options.swiper.heroCallback = options.swiper.heroCallback;
  }

  BANKITALIA.mobile.init();
  BANKITALIA.mainNavigation.init();
  BANKITALIA.swiper.init();
  BANKITALIA.inputMask.init();
  BANKITALIA.inputAmount.init();
  BANKITALIA.drag.init();
  BANKITALIA.info.init();
  BANKITALIA.circle.init();
  BANKITALIA.lazyLoad.init();
};

BANKITALIA.mobile = {
  isMobile: false,
  init: function () {
    var body = document.getElementsByTagName("body")[0];
    this.md = new window.MobileDetect(window.navigator.userAgent);
    this.isMobile = (this.md.mobile() !== null);

    if (this.isMobile) {
      body.classList.add("isMobile");
    }
  }
};

BANKITALIA.mainNavigation = {
  init: function () {
    const navigation = document.getElementsByClassName("bankitalia-header__col--nav")[0];

    if (navigation) {
      this.actions(navigation);
    }
  },
  actions: function (navigation) {
    const html = document.getElementsByTagName("html")[0];
    const hamburger = document.getElementsByClassName("bankitalia-hamburger")[0];
    const navigationClose = document.getElementsByClassName("bankitalia-navigation-close")[0];
    const navItems = document.querySelectorAll(".bankitalia-chapter:not(.bankitalia-chapter--alt-active), .bankitalia-navigation__item a:not(.active)");

    hamburger.addEventListener("click", function () {
      document.querySelector('html').classList.add("menu-opened");
    });
    navigationClose.addEventListener("click", function () {
      document.querySelector('html').classList.remove("menu-opened");
    });

    window.addEventListener('resize', function () {
      navigation.classList.remove("transitions");

      if (window.innerWidth > 767) {
        document.querySelector('html').classList.remove("menu-opened");
      } else {
        setTimeout(function (){
          navigation.classList.add("transitions");
        }, 500);
      }
    });

    navItems.forEach(function(chapter) {
      chapter.addEventListener("click", function() {
        document.querySelector('html').classList.remove("menu-opened");
      })
    })

    var eventResize = document.createEvent("Event");
    eventResize.initEvent("resize", false, true);
    window.dispatchEvent(eventResize);
  }
};

BANKITALIA.swiper = {
  $hero: null,
  $game: null,
  init: function () {
    this.hero();
    this.game();
  },
  hero: function () {
    const $hero = new window.Swiper('.bankitalia-hero__swiper', {
      slidesPerView: 1,
      pagination: {
        el: '.bankitalia-hero__pagination',
        bulletClass: 'bankitalia-pagination__item',
        bulletActiveClass: 'active',
        clickable: true
      },
      navigation: {
        prevEl: '.bankitalia-hero__nav--prev',
        nextEl: '.bankitalia-hero__nav--next',
      },
    });

    $hero.on('slideChange', function () {
      if (BANKITALIA.options.swiper.heroCallback !== undefined) {
        BANKITALIA.options.swiper.heroCallback(this.realIndex);
      }
    });
  },
  game: function () {
    const $hero = new window.Swiper('.bankitalia-game-carousel__swiper', {
      slidesPerView: 1,
      threshold: 10,
      pagination: {
        el: '.bankitalia-game-carousel__pagination',
        bulletClass: 'bankitalia-pagination__item',
        bulletActiveClass: 'active',
        clickable: true
      },
      navigation: {
        prevEl: '.bankitalia-hero__nav--prev',
        nextEl: '.bankitalia-hero__nav--next',
      },
    });

    $hero.on('transitionStart', function () {
      const circle = Array.prototype.slice.call(document.getElementsByClassName("js-circle active"))[0];

      if (circle) {
        circle.classList.remove("active");
      }
    });

    $hero.on('slideChange', function () {
      if (BANKITALIA.options.swiper.heroCallback !== undefined) {
        BANKITALIA.options.swiper.heroCallback(this.realIndex);
      }
    });
  }
};

BANKITALIA.inputMask = {
  init: function () {
    const $ = window.jQuery;

    $('.bankitalia-pin__input').mask('0');
  }
};

BANKITALIA.inputAmount = {
  init: function () {
    const amount = document.getElementsByClassName("bankitalia-amount")[0];

    if (amount) {
      const input = amount.getElementsByClassName("bankitalia-amount__input")[0];
      const increaseBtn = amount.getElementsByClassName("bankitalia-amount__increase")[0];
      const decreaseBtn = amount.getElementsByClassName("bankitalia-amount__decrease")[0];
      const max = parseFloat(input.getAttribute("data-max"));

      increaseBtn.addEventListener("click", function () {
        var value = parseFloat(input.value);
        value += 0.1;
        if (value > max) {
          value = max;
        } else {
          value = '';
        }

        input.value = value.toFixed(2);
      });

      decreaseBtn.addEventListener("click", function () {
        var value = parseFloat(input.value);
        value -= 0.1;
        if (value < 0.1) {
          value = 0;
        } else {
          value = '';
        }

        input.value = value.toFixed(2);
      });
    }
  }
};

BANKITALIA.info = {
  init: function () {
    const info = document.getElementsByClassName("bankitalia-info")[0];

    if (info) {
      const nameArea = info.getElementsByClassName("bankitalia-info__name")[0];
      const descArea = info.getElementsByClassName("bankitalia-info__desc")[0];
      const infoBlocks = Array.prototype.slice.call(info.getElementsByClassName("bankitalia-block"));

      infoBlocks.forEach(function (block) {
        block.addEventListener("click", function () {
          infoBlocks.forEach(function (block) {
            block.classList.remove("active");
          });

          const name = this.textContent
          const desc = this.getAttribute("data-desc");

          nameArea.textContent = name;
          descArea.textContent = desc;

          this.classList.add("active");
        });
      });
    }
  }
};

BANKITALIA.drag = {
  init: function (id) {
    const drag = document.getElementById(id);
    if (!drag) {
      console.error('Drag element not found');

      return;
    }

    if (drag) {
      const blocksContainer = drag.getElementsByClassName("bankitalia-drag__blocks")[0]
      const blocks = Array.prototype.slice.call(blocksContainer.getElementsByClassName("bankitalia-block"));
      const answersField = Array.prototype.slice.call(drag.getElementsByClassName("bankitalia-drag__answer__field"));
      var selectedBlockBody;
      var selectedBlockIndex

      blocks.forEach(function (block, index) {
        block.addEventListener("click", function () {
          selectedBlockIndex = index;

          // selected box was dropped before
          if (this.classList.contains("dropped")){
            this.classList.remove("dropped");

            var filledField = drag.querySelector(".bankitalia-drag__answer__field[data-index='"+selectedBlockIndex+"']");
            filledField.innerHTML = "";
            filledField.classList.remove("active");
          }
          selectedBlockBody = this.outerHTML;

          blocks.forEach(function (block) {
            block.classList.remove("selected");
          });

          this.classList.add("selected");
        });
      });

      answersField.forEach(function (field) {
        field.addEventListener("click", function () {
          if (selectedBlockBody) {
            var blockIndex = null;

            if (this.classList.contains("active")) {
              blockIndex = this.getAttribute("data-index");
              blocks[blockIndex].classList.remove("dropped");
            }

            this.innerHTML = selectedBlockBody;
            this.classList.add("active");
            this.setAttribute("data-index", selectedBlockIndex)

            selectedBlockBody = ""

            blocks[selectedBlockIndex].classList.remove("selected");
            blocks[selectedBlockIndex].classList.add("dropped");
          }
        });
      });
    }
  }
};

BANKITALIA.circle = {
  init: function () {
    const circles = Array.prototype.slice.call(document.getElementsByClassName("js-circle"));

    if (circles) {
      circles.forEach(function (circle) {
        circle.addEventListener("click", function () {
          if (!this.classList.contains("active")) {
            circles.forEach(function (circle) {
              circle.classList.remove("active")
            });
            this.classList.add("active")
          }
        });

        var close = circle.getElementsByClassName("bankitalia-circle__close")[0];
        if (close) {
          close.addEventListener("click", function (e) {
            e.stopPropagation();
            this.parentNode.classList.remove("active")
          });
        }
      });
    }
  }
};

BANKITALIA.lazyLoad = {
	endClass: "animated",
	init: function () {
		var that = this;

		window.addEventListener("scroll", function () {
      var lazeElement = Array.prototype.slice.call(document.getElementsByClassName("js-lazy"))
      that.onScroll(lazeElement);
		})

		var eventScroll = document.createEvent("Event");
		eventScroll.initEvent("scroll", false, true);
		window.dispatchEvent(eventScroll);
	},
	onScroll: function (lazeElement) {
		var windowHeight;
		var that = this;

		windowHeight = window.innerHeight;

		lazeElement.forEach(function (el) {
			if (BANKITALIA.offset.calculate(el) < BANKITALIA.winScrollTop.calculate() + windowHeight / 0.9) {
        el.classList.add(that.endClass);
      }
		});
	}
}

BANKITALIA.offset = {
	// function calculating offset top in vanilla js offset calculating (same as offset().top in jq)
	calculate: function (el) {
		var rect = el.getBoundingClientRect(),
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return rect.top + scrollTop
	}
}

BANKITALIA.winScrollTop = {
	calculate: function () {
		var doc = document.documentElement;
		var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

		return top;
	}
}

export default BANKITALIA;