import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Omar è un po' confuso su diversi modi per pagare. Prova ad aiutarlo, facendo i giusti collegamenti!`,
  paragraph: ``,

  items: [
    {
      text: `Sono le monete e le banconote, quelle che mettiamo nel portafogli`,
      term: 'Denaro contante'
    },
    {
      text: `Si usa per fare acquisti o prelevare allo sportello.`,
      term: 'Carta bancomat'
    },
    {
      text: `Si usa di solito per fare acquisti. In pratica, si paga il mese successivo perché non prende subito i soldi dal conto`,
      term: 'Carta di credito'
    },
    {
      text: `Si usa per fare acquisti o prelevare allo sportello: non è collegata a un conto e deve essere caricata prima`,
      term: 'Carta prepagata'
    },
    {
      text: `È un foglietto numerato, legato al conto corrente, da compilare con attenzione, cifra, firma, beneficiario. Oramai si usa poco`,
      term: 'Assegno'
    },
    {
      text: `È un ordine che diamo alla banca per spostare dei soldi da un conto a un altro: si può fare allo sportello o con l'homebanking`,
      term: 'Bonifico'
    }
  ],
  extra_terms: [],

  points: 1,

  feedback: {
    correct: `Bravo, sono i collegamenti giusti!`,
    wrong: `I collegamenti non sono giusti`
  }
}