import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/004.png',

  heading: `I soldi dei grandi`,
  paragraph: `**Il reddito** è un concetto importante. Vi ricordate? Il reddito è **la somma di denaro che riceviamo in un determinato periodo di tempo**, e possiamo usarlo come vogliamo.
  Possiamo metterlo nel **salvadanaio**, per **risparmiarlo**, o usarlo per fare **acquisti**: possiamo anche comprarci un pallone da calcio nuovo, proprio come vorrebbe fare Omar!`
}