import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-04-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j4.png',

  heading: `Una scelta difficile`,
  paragraph: `Attila vuole a tutti i costi risparmiare ma non sa resistere ai croccantini speciali, sono troppo buoni!

  Tu e Attila girate in città e a un certo punto senti tirare il guinzaglio. Woof! È Attila che ha visto un bellissimo collare borchiato, come quelli dei cani dei cartoni animati. Cosa possiamo dire ad Attila?`,

  answers: [
    {
      text: `Attila, te ne abbiamo appena regalato uno, ricordati che dobbiamo risparmiare per la cuccia!`,
      feedback: `Bravo! Non hai fatto cadere in tentazione Attila che ora potrà continuare a risparmiare per la sua nuova cuccia.`,
      feedbackType: 'success',
      points: 1
    },
    {
      text: `In effetti è molto bello, che male c'è a prenderlo?`,
      feedbackType: 'error',
      feedback: `Beh nessuno aveva detto che era facile! Ma ricorda, spesso bisogna rinunciare a qualcosa oggi per avere qualcosa di meglio domani!`,
      points: 0
    },
    {
      text: `No Attila, quel collare non mi piace, ma ce n'è un altro molto più bello al negozio dopo, compriamo quello!`,
      feedbackType: 'error',
      feedback: `Beh nessuno aveva detto che era facile! Ma ricorda, spesso bisogna rinunciare a qualcosa oggi per avere qualcosa di meglio domani!`,
      points: 0
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}