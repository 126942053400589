import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/007.png',

  heading: `Da dove vengono i soldi dei genitori?`,
  paragraph: `I soldi non crescono sugli alberi (purtroppo!). Anche Pinocchio l'ha imparato! A volte i soldi arrivano senza aver fatto nulla: qualche regalo oppure, per pochissimi fortunati, la vincita alla lotteria. Ma di norma non è così. Per mantenersi, a meno di non essere ricchi di famiglia e decidere di farsi bastare negli anni ciò che si possiede,  servono guadagni regolari. Per guadagnare, occorre lavorare.`
}