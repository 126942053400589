import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-02-HOT-03',
  
  type: constants.HOTSPOT_TYPE_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `L'agricoltore`,
  paragraph: `L'agricoltore cura le fragole, le fa crescere e le raccoglie, ma per farlo ha bisogno di semi, di macchinari e della sua fatica, quindi diciamo che vende le fragole a 1 euro al chilo.`
}