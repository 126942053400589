import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_CUSTOM_CC_PIN,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `La memoria fa brutti scherzi`,
  paragraph: `Il PIN è un codice che va imparato a memoria, è bene non scriverlo su un foglietto da tenere nel portafoglio perché potremmo perderlo e qualcun altro trovandolo potrebbe usarla al posto nostro. Ma quanto è importante? In fondo sono solo poche cifre, quanto ci vorrà a indovinare il codice corretto se ce ne siamo dimenticati? Nina si ricorda solo 4 cifre su 5 del PIN della sua carta, la aiuti a scoprire il PIN completo.`
}