import React from 'react';
import MarkdownParser from '../../../components/shared/MarkdownParser';
import PointsUtil from '../../../utils/PointsUtil';

import HotspotModal from './HotspotModal';

export default class InputValueModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,


      currValue: 0,

      submitted: false
    };
  }

  handleHotspotClick = () => {
    this.setState({
      modalOpen: true
    });
    setTimeout(() => {
      document.querySelector('html').classList.add("scroll-lock");
    }, 1200);
  }

  increaseValue = () => {
    const nextValue = this.state.currValue + 1;

    this.setCurrValue(nextValue);
  }
  decreaseValue = () => {
    const nextValue = this.state.currValue - 1;

    this.setCurrValue(nextValue);
  }
  setCurrValue = (value) => {
    value = value === '' ? '' : (!value ? 0 : parseInt(value));
    if (value < 0) {
      value = 0;
    }

    this.setState({
      currValue: value,
      submitted: false
    });
  }

  handleSubmit = () => {
    const { hotspot, pos, enabled } = this.props;

    // validate
    if (this.state.submitted) {
      console.warn('Already submitted');

      return;
    }
    if (isNaN(this.state.currValue)) {
      console.warn('value is wrong');

      return;
    }

    // look for feedback
    let feedback = null;
    for (let i=0; i<hotspot.feedback_by_value.length; i++) {
      const f = hotspot.feedback_by_value[i];

      switch (f.operator) {
        case '>':
          if (this.state.currValue > f.value) {
            feedback = f;

            break;
          }
          case '>=':
            if (this.state.currValue >= f.value) {
              feedback = f;
            }
            break;
          case '<':
            if (this.state.currValue < f.value) {
              feedback = f;
            }
            break;
          case '<=':
            if (this.state.currValue <= f.value) {
              feedback = f;
            }
            break;
          case '==':
            if (this.state.currValue == f.value) {
              feedback = f;
            }
            break;
          case '!=':
            if (this.state.currValue != f.value) {
              feedback = f;
            }
            break;
          default:
            throw Error('unknown operator: ' + f.operator);
      }
    }

    this.setState({
      submitted: true,

      feedbackMessage: feedback.feedback_message,
      feedbackType: feedback.feedback_type
    }, () => {
      // update score
      const shouldAddPoints = feedback.feedback_type == 'success';
      const numPoints = hotspot.points;
      if (shouldAddPoints) {
        PointsUtil.addPoints(hotspot, numPoints);
      }
    });
  }

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });

    document.querySelector('html').classList.remove("scroll-lock");
  }

  render() {
    const { hotspot, pos, enabled, highlighted } = this.props;

    return (
      <>
        <div className={`HOTSPOT-INPUT-VALUE bankitalia-circle ${this.state.active ? 'bankitalia-circle--active' : ''} ${!enabled ? 'bankitalia-circle--disabled' : ''} bankitalia-circle--${pos} js-circle magictime puffIn ${highlighted ? 'bankitalia-hotspot--highlighted' : ''} `} onClick={this.handleHotspotClick}>
          <div className="bankitalia-dev-hotspot-id">{hotspot.id}<br />{hotspot.type}</div>
        </div>

        <HotspotModal open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className="container-fluid bankitalia-modal__content">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 bankitalia-modal__col bankitalia-modal__col--question">
                <div className="bankitalia-quiz">
                  <h2 className="bankitalia-quiz__question">{hotspot.heading}</h2>
                  <div className="bankitalia-quiz__desc"><MarkdownParser source={hotspot.paragraph} /></div>

                  <div className="bankitalia-quiz__actions bankitalia-quiz__actions--amount">
                    <div className="bankitalia-amount">
                      <strong className="bankitalia-amount__currency">{hotspot.value_type_override || '€'}</strong>
                      <input className="bankitalia-amount__input" type="text" value={`${this.state.currValue}`} data-min="0" data-max="500" onChange={(e) => this.setCurrValue(e.currentTarget.value)} />
                      <div className="bankitalia-amount__controls">
                        <button className="bankitalia-amount__increase" type="button" onClick={this.increaseValue}>
                          <img src="/images/icons/arrow-up.png" srcset="/images/icons/arrow-up.png, /images/icons/arrow-up-2x.png 2x" title="Close menu" alt="Close" />
                        </button>
                        <button className="bankitalia-amount__decrease" type="button" onClick={this.decreaseValue}>
                          <img src="/images/icons/arrow-down.png" srcset="/images/icons/arrow-down.png, /images/icons/arrow-down-2x.png 2x" title="Close menu" alt="Close" />
                        </button>
                      </div>
                    </div>
                    <button className={`bankitalia-btn ${this.state.submitted && 'disabled'}`} type="button" onClick={this.handleSubmit}>
                      <span className="bankitalia-btn__label">invia</span>
                    </button>
                  </div>

                  {
                    this.state.submitted && (
                      <div className="bankitalia-quiz__message">
                        <div className={`bankitalia-message bankitalia-message--${this.state.feedbackType}`}>
                          <p>
                            <MarkdownParser source={this.state.feedbackMessage} />

                            { hotspot.feedback_comment && <p><small style={{fontSize: '80%'}}>{hotspot.feedback_comment}</small></p> }
                          </p>

                          {
                            this.state.feedbackType == 'success' && (
                              <button className="bankitalia-btn bankitalia-btn--green" onClick={this.handleModalClose}>
                                <span className="bankitalia-btn__label">continua</span>
                              </button>
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </HotspotModal>
      </>
    );
  }
}