import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-01-SL-01-HOT-04',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j3.png',

  heading: `Omar si chiede se può già lavorare... Ma è abbastanza grande?`,
  paragraph: `Indovina cosa dice la legge sul lavoro dei bambini`,

  answers: [
    {
      text: `Può lavorare solo chi ha compiuto 18 anni. Chi ne ha meno può solo apparire in film o alla televisione, ma unicamente gratis (così non è considerato lavoro) e dopo aver ottenuto il permesso di un giudice.`,
      feedback: `Sbagliato: adesso a certe condizioni si può anche prima della maggiore età. E i piccoli attori hanno diritto a essere pagati..`,
      feedbackType: 'error',
      points: 0
    },
    {
      text: `Può lavorare chi ha compiuto 16 anni, se ha finito la scuola dell’obbligo. Dai 15 anni si può già fare l’apprendista. Prima solo lavori artistici, culturali, sportivi, in film o alla tv, autorizzati dai genitori e dall’Ispettorato del lavoro.`,
      feedbackType: 'success',
      feedback: `Risposta esatta: hai proprio indovinato!`,
      points: 1
    },
    {
      text: `Può lavorare chi ha compiuto 8 anni, ma solo fuori dall’orario di scuola e con una ricreazione ogni 3 ore di lavoro.`,
      feedbackType: 'error',
      feedback: `Mi spiace, sbagliato: per fortuna i più piccoli sono protetti dallo sfruttamento.`,
      points: 0
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}