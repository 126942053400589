import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-02-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Ogni paese ha la sua moneta: sai riconoscerla?`,
  paragraph: ``,

  items: [
    {
      text: `Regno Unito`,
      term: 'Sterlina'
    },
    {
      text: 'Giappone',
      term: 'Yen'
    },
    {
      text: 'India',
      term: 'Rupia'
    },
    {
      text: 'Canada',
      term: 'Dollaro'
    },
    {
      text: 'Svezia',
      term: 'Corona'
    },
    {
      text: 'Svizzera',
      term: 'Franco'
    },
  ],
  extra_terms: [],

  points: 1,

  feedback: {
    correct: `Perfetto. Fai pure la valigia, non avrai problemi a girare il mondo!`,
    wrong: `Ma che confusione con tutte queste monete! Così proprio non va…`
  }
}