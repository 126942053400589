import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-01-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Per integrare la paghetta che riceve dalla famiglia, Omar ha deciso di lavorare...`,
  paragraph: ``,

  answers: [
    {
      text: `Per lavorare Omar ha bisogno di un contratto di lavoro regolare; pagherà tasse e contributi.`,
      feedback: `Risposta esatta: chiunque percepisca un reddito da lavoro deve pagare tasse e contributi!`,
      feedbackType: 'success',
      points: 1
    },
    {
      text: `In particolari casi, è possibile lavorare con un contratto regolare scegliendo di pagare solo le tasse ma non i contributi`,
      feedbackType: 'error',
      feedback: `Se si percepisce un reddito, tasse e contributi vanno pagati sempre`,
      points: 0
    },
    {
      text: `Chi lavora deve pagare le tasse solo se è maggiorenne.`,
      feedbackType: 'error',
      feedback: `Mi spiace, sbagliato: chiunque percepisca un reddito deve pagare le tasse.`,
      points: 0
    },
    {
      text: `Chi lavora in nero ha le medesime tutele in caso di incidente sul lavoro`,
      feedbackType: 'error',
      feedback: `Purtroppo il lavoratore in nero non gode della medesima tutela in caso di incidente.`,
      points: 0
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}