import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/023.png',

  heading: `Il PIN`,
  paragraph: `La parola PIN indica un acronimo inglese che sta per "Personal Identification Number" ovvero "Numero di identificazione personale". È un codice segreto fatto di numeri che dobbiamo conoscere per far funzionare la carta. Per la carta di debito i numeri sono cinque e, di solito, li dobbiamo digitare quando siamo allo sportello ATM per fare un prelievo o quando dobbiamo pagare alla cassa di un negozio. Essendo segreto, però, bisogna sempre fare molta attenzione quando lo digitiamo, perché nessuno ci guardi.`
}