import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-01-HOT-05',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/315.png',

  heading: `Le tre funzioni della moneta`,
  paragraph: `La moneta è uno strumento capace di svolgere una molteplicità di funzioni. La prima è quella di strumento di pagamento: facilita gli scambi e serve per comprare beni o servizi o pagare il lavoro di chi li produce o li vende. Inoltre, la moneta è unità di conto: serve cioè come riferimento comune per esprimere i prezzi di tutti i beni e i servizi in un’unica unità di misura, così che sia sempre possibile e immediato porli a confronto e sia agevole fare i conti necessari a stilare un budget (come abbiamo visto nel capitolo precedente). Infine, la moneta è riserva di valore: può venir messa da parte per utilizzi futuri, con la ragionevole certezza che conservi nel tempo il proprio potere d’acquisto, cioè la capacità anche domani di comprare le stesse cose di oggi.`
}