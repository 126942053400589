import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-01-SL-02-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-01-SL-02-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-01-SL-02-HOT-03';

export default {
  id: 'SECT-02–P-01-SL-02',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/107_0001_1.png',
        animation: 'playful',
        from: 'left',
        speed: 'slow',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/107_0000_2.png',
        animation: 'playful',
        from: 'right',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Che ci faccio con il denaro?`,
    paragraph: `…e ci sono vari modi per spendere i soldi! Ma bisogna saper scegliere quali sono i più urgenti.`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};