import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-04-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/133.png',

  heading: `Perché non spendere tutto?`,
  paragraph: `Teo ha ancora mezza paghetta. Potrebbe offrire un gelato a tutti! Oppure mettere da parte i soldi per un’altra volta, cioè risparmiarli. Ma perché dovrebbe?

  Ecco tre buoni motivi:

  Può mettere da parte i soldi per far fronte alle incertezze future: magari una settimana in cui non riceverà la paghetta, o vorrà comprarsi in edicola il numero speciale del suo fumetto preferito o in un negozio una irresistibile cover da cellulare…

  Può accumulare dei soldi fino a quando potrà permettersi una spesa speciale: bastano 2 € a settimana e in meno di un anno ne avrà 100, con cui potrà acquistare qualcosa di davvero costoso e importante per lui che altrimenti non si potrebbe permettere.

  Inoltre, se i risparmi vengono investiti possono fruttare un interesse, cioè un guadagno: alla fine, il risparmiatore si ritrova con più soldi di prima. Certo non è il caso di Teo, ma forse può riguardare i suoi genitori…`
}