import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/016.png',

  heading: `Un viaggio lungo o corto?`,
  paragraph: `Il prezzo di una cosa ci dice quanta moneta serve per acquistarla. Un prezzo non nasce mai a caso, ma è il risultato di un lungo viaggio che ha tante tappe. Scopriamo insieme il viaggio delle fragole!`
}