import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-04-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j7.png',

  heading: ``,
  paragraph: `Ma cos'è questo freddo improvviso? A quanto pare è previsto un inverno veramente rigido, ma come farà Attila? Avrà freddo ogni volta che andremo a fare una passeggiata? È un'emergenza!`,

  answers: [
    {
      text: `In fondo Attila è temprato, non ha certo bisogno di giubbottini, brrr che freddo però!`,
      feedback: `È bene sempre coprirsi dal freddo, altrimenti prendiamo tutti un bel raffreddore! eeetciù!`,
      feedbackType: 'error',
      points: 0
    },
    {
      text: `Meno male avevamo risparmiato qualche soldo, i giubbottini per cane non costano poco, ma almeno Attila sarà al calduccio quando passeggeremo questo inverno.`,
      feedbackType: 'success',
      feedback: `Bravo, i risparmi servono anche per gli imprevisti, la cuccia arriverà un po' più tardi ma ne sarà valsa la pena.`,
      points: 1
    },
    {
      text: `Dobbiamo risparmiare a tutti i costi! Attila, stringi i denti!`,
      feedbackType: 'error',
      feedback: `Non sempre il risparmio a tutti i costi è un bene, quando si verificano degli imprevisti è necessario fronteggiarli, e allora avere dei risparmi già accantonati aiuta!`,
      points: 0
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}