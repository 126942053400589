import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-03-SL-02-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-03-SL-02-HOT-02';
import hotspot3 from './hotspots/SECT-02–P-03-SL-02-HOT-03';

export default {
  id: 'SECT-02–P-03-SL-02',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/124_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Ma l’imbroglio è in agguato...`,
    paragraph: `Attenti a non cascare nella rete… dei truffatori in rete!`,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: ''
};