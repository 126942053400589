import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/119.png',

  heading: `L’inflazione che si mangia i risparmi`,
  paragraph: `Omar vorrebbe i soldi per un nuovo telefonino. Ha scoperto che è la Banca centrale a stampare il denaro e si chiede: perché non ci regala un bel milione di euro a testa, facendoci felici? Ma se la Banca centrale desse a ognuno di noi un milione risolverebbe i nostri problemi? Purtroppo non è così semplice… Le Banche centrali devono stare attente a quanto denaro mettono in giro, perché il valore di una moneta si misura con le cose che può comprare. Con un milione in tasca, tutti vorrebbero comprare più oggetti, ma se i prodotti offerti sono gli stessi la gente farebbe a gara a offrire più soldi per averli. Così i prezzi salirebbero sempre di più.

  Se i prezzi salgono, la gente ha bisogno di stipendi più alti per vivere e chiede aumenti. Se gli stipendi aumentano, per le imprese produttrici diventa più costoso produrre gli oggetti e quindi i prezzi aumentano ancora… insomma, è un cane che si morde la coda! Quando per un periodo i prezzi aumentano si dice che c’è inflazione, e i risparmi messi da parte valgono sempre meno. Il fenomeno contrario, in cui i prezzi scendono in continuazione, si chiama deflazione ma non è bella nemmeno lei: si rischia che la gente non compri in attesa che i prezzi scendano ancora, che i produttori non riescano a vendere la propria merce e dunque guadagnino sempre meno, fino a dover licenziare i propri dipendenti, che dunque perderebbero il lavoro, e che l'economia si fermi.

  Per questo le Banche centrali hanno di solito l’obiettivo di mantenere la variazione dei prezzi entro limiti ristretti. Nell’area dell’euro, l’obiettivo è mantenere l’inflazione su livelli inferiori ma prossimi al 2% nel medio periodo.`
}