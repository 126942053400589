import constants from '../../../../../../constants';

import hotspot1 from './hotspots/section-01-page-02-slide-01-hotspot-01';
import hotspot2 from './hotspots/section-01-page-02-slide-01-hotspot-02';
import hotspot3 from './hotspots/section-01-page-02-slide-01-hotspot-03';
import hotspot4 from './hotspots/section-01-page-02-slide-01-hotspot-04';
import hotspot5 from './hotspots/section-01-page-02-slide-01-hotspot-05';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/orig/015.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `L'unione fa la forza`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3,
    hotspot4,
    hotspot5
  ],

  extra_class: 's1p2s1'
};