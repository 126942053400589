import constants from '../../constants';
import colorSchemes from '../../color_schemes';

import page1 from './pages/01/section-03-page-01';
import page2 from './pages/02/section-03-page-02';
import page3 from './pages/03/section-03-page-03';
import page4 from './pages/04/section-03-page-04';
import page5 from './pages/05/section-03-page-05';

export default {
  id: 'SECT-03',

  palette: colorSchemes['green'],

  first_page: false,
  base_path: '/scuole/superiore',

  name: 'Scuola Superiore',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/intro.png',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },
  animation_type: 'simple',

  page_content: {
    heading: `Vai alla Scuola Superiore?`,
    paragraph: `Esplora all’interno di questo sito tanti temi dedicati all’economia e alla finanza: informazioni, curiosità e attività per comprendere quanto siano presenti nella nostra vita.`
  },

  pages: [
    page1,
    page2,
    page3,
    page4,
    page5
  ],

  outro_image: '/images/section-03/outro.png'
};