import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from './Navbar.js';

class Header extends React.PureComponent {
  render() {
    return (
      <header className="container bankitalia-header">
        <div className="row bankitalia-header__row">
          <div className="col bankitalia-header__col bankitalia-header__col--logo">
            <h1 className="bankitalia-logo-app">
              <Link to="/">
                <img src="/images/logos/app2x.png" title="Tutti per uno economia per tutti!" alt="Application logo" />
              </Link>
            </h1>
          </div>

          {this.props.children}
        </div>
      </header>
    )
  }
}

export default Header;