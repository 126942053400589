import slide1 from './slides/01/section-03-page-05-slide-01';
import slide2 from './slides/02/section-03-page-05-slide-02';
import slide3 from './slides/03/section-03-page-05-slide-03';

export default {
  id: 'SECT-03_P-05',

  first_page: false,
  base_path: '/scuole/superiore/credito',

  name: `Credito al consumatore`,

  icon: '/images/section-03/icons/page-05.png',
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/336.png',
        from: '',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Credito al consumatore`,
    paragraph: `Il computer di Nina si è rotto e lei non può farne a meno. Le serve anche per scuola! Ma i suoi non hanno i soldi da darle, è un momento difficile. Come trovarli?`,
  },

  slides: [
    slide1,
    slide2,
    slide3
  ]
};