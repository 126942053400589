import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Settings from '../config/Settings';

import Header from '../components/shared/Header';
import PageNavbar from '../components/sections/PageNavbar';
import SidebarNav from '../components/sections/SidebarNav';
import AnimatedIllustration from '../components/shared/AnimatedIllustration';

import BalloonHotspot from '../components/sections/hotspots/BalloonHotspot';
import InfoPanelHotspot from '../components/sections/hotspots/InfoPanelHotspot';
import MultipleChoiceModal from '../components/sections/hotspots/MultipleChoiceModal';
import SortingModal from '../components/sections/hotspots/SortingModal';
import AssocModal from '../components/sections/hotspots/AssocModal';
import ClickShowModal from '../components/sections/hotspots/ClickShowModal';
import CustomModalPIN from '../components/sections/hotspots/CustomModalPIN';
import MultiTogglesModal from '../components/sections/hotspots/MultiTogglesModal';
import MultiPriceTogglesModal from '../components/sections/hotspots/MultiPriceTogglesModal';
import InputValueModal from '../components/sections/hotspots/InputValueModal';
import InvestmentIdeasModal from '../components/sections/hotspots/InvestmentIdeasModal';
import InterestCalculatorModal from '../components/sections/hotspots/InterestCalculatorModal';

import BANKITALIA from '../BANKITALIA';


class StudentPage extends React.Component {
  constructor(props) {
    super(props);

    this.hotspotEnablingTimeout = null;

    this.state = {
      currSlide: null,

      enableHostpotsAt: null, // used to show slides after animation is done
      hotspotsDisabled: false
    };
  }

  componentDidMount = () => {
    // init vanilla JS stuff
    (function($, that) {
      $(document).ready(function() {
        BANKITALIA.init({
          swiper: {
            heroCallback: that.handleSlideChange
          }
        });
      });
    })(window.jQuery, this);

    // handle hotspots for first slide
    this.handleSlideChange(0);
  }

  handleSlideChange = (slideIndex) => {
    window.clearTimeout(this.hotspotEnablingTimeout);

    this.setState({
      currSlide: slideIndex,
      enableHostpotsAt: null
    }, () => {
      this.t = window.setTimeout(() => {
        this.setState({
          enableHostpotsAt: slideIndex
        });
      }, 2800);
    });
  }

  // if we click on the "Prev"/"Next" arrow on the first/last page, we switch chapter
  maybeSwitchPage = (dir) => {
    const { section, page, location, history } = this.props;

    if (dir == 'prev' && this.state.currSlide === 0 || dir == 'next' && (this.state.currSlide + 1) === page.slides.length) {
      const path = page.base_path + '/fine/';
      history.push(path);

      return;
    }
  }

  handleHotspotClick = (isOpen=true, e) => {
    e && e.stopPropagation();

    if (isOpen && this.state.hotspotsDisabled) {
      console.warn('hotspots disabled, ignore');
    }

    this.setState({
      hotspotsDisabled: isOpen ? true : false
    }, () => {
      // pass click
      const $ = window.jQuery;
      if (e && e.currentTarget) {
        $(e.currentTarget).trigger('click');
      }
    })
  }

  render() {
    const { section, page } = this.props;

    return (
      <div className={Settings.pageFXClass + ' ' + section.palette}>
        <div className="bankitalia-app bankitalia-app--game">
          <Header>
            <PageNavbar section={section} page={page} />
            <SidebarNav section={section} page={page} />
            <div id="bankitalia-react-portal"></div>
          </Header>

          <div className="container bankitalia-main">
            <div className="row">
              <div className="col">
                <div className="bankitalia-game-carousel">
                  <div className="swiper-container bankitalia-game-carousel__swiper">
                    <div className="swiper-wrapper">

                      {
                        page.slides.map((slide, slideIndex) => (
                          <div className="swiper-slide">
                            <div className={`bankitalia-game-slide bankitalia-game-slide--${slideIndex} ${slide.extra_class}`}>
                              <div className="row bankitalia-game-slide__row bankitalia-game-slide__row--header">
                                <div className="col">
                                  <strong className="bankitalia-title">{slide.page_content.heading}</strong>
                                </div>
                              </div>

                              <div className="row bankitalia-game-slide__row bankitalia-game-slide__row--content">
                                <div className="bankitalia-game-slide__inner">
                                  {
                                    slideIndex === this.state.currSlide && (
                                      <>
                                        {
                                          slide.illustration && slide.illustration.layers && slide.illustration.layers.length ? <AnimatedIllustration layers={slide.illustration.layers} animationType={section.animation_type} /> : <img src={slide.featured_image} />
                                        }
                                        {
                                          this.state.enableHostpotsAt === slideIndex && slide.hotspots.map((hotspot, i) => {
                                            const n = i + 1;

                                            const highlighted = [
                                              `SECT-03_P-05-SL-03-HOT-02`,
                                              `SECT-03_P-05-SL-02-HOT-04`,
                                              `SECT-03_P-05-SL-01-HOT-02`,
                                              `SECT-03_P-04-SL-03-HOT-04`,
                                              `SECT-03_P-04-SL-03-HOT-02`,
                                              `SECT-03_P-04-SL-02-HOT-03`,
                                              `SECT-03_P-03-SL-01-HOT-04`,
                                              `SECT-03_P-02-SL-01-HOT-02`,
                                              `SECT-03_P-03-SL-01-HOT-02`
                                            ];

                                            return (
                                              <div>
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_BALLOON && (
                                                    <BalloonHotspot hotspot={hotspot} enabled={this.state.hotspotsDisabled == false} pos={n} onClick={(e) => this.handleHotspotClick(true, e)} onClose={(e) => this.handleHotspotClick(false)} highlighted={highlighted.indexOf(hotspot.id) != -1} />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_BIG_BALLOON && (
                                                    <BalloonHotspot hotspot={hotspot} enabled={this.state.hotspotsDisabled == false} pos={n} big onClick={(e) => this.handleHotspotClick(true, e)} onClose={(e) => this.handleHotspotClick(false)} highlighted={highlighted.indexOf(hotspot.id) != -1} />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_INFO_PANEL && (
                                                    <InfoPanelHotspot hotspot={hotspot} enabled={this.state.hotspotsDisabled == false} pos={n} onClick={(e) => this.handleHotspotClick(true, e)} onClose={(e) => this.handleHotspotClick(false)} highlighted={highlighted.indexOf(hotspot.id) != -1} />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_MULTI && (
                                                    <MultipleChoiceModal hotspot={hotspot} enabled={this.state.hotspotsDisabled == false} pos={n} onClick={(e) => this.handleHotspotClick(true, e)} onClose={(e) => this.handleHotspotClick(false)} highlighted={highlighted.indexOf(hotspot.id) != -1} />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_SORT && (
                                                    <SortingModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_ASSOC && (
                                                    <AssocModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_DISCOVER && (
                                                    <ClickShowModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_CUSTOM_CC_PIN && (
                                                    <CustomModalPIN
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES && (
                                                    <MultiTogglesModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_MULTI_PRICE_TOGGLES && (
                                                    <MultiPriceTogglesModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_INPUT_VALUE && (
                                                    <InputValueModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_INVESTMENT_IDEAS && (
                                                    <InvestmentIdeasModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                                {
                                                  hotspot.type == Settings.HOTSPOT_TYPE_MODAL_INTEREST_CALC && (
                                                    <InterestCalculatorModal
                                                      pos={n}

                                                      highlighted={highlighted.indexOf(hotspot.id) != -1}

                                                      hotspot={hotspot}
                                                      enabled={this.state.hotspotsDisabled == false}

                                                      onClick={(e) => this.handleHotspotClick(true, e)}
                                                      onClose={(e) => this.handleHotspotClick(false)}
                                                    />
                                                  )
                                                }
                                              </div>
                                            );
                                          })
                                        }
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className="bankitalia-game-carousel__pagination"></div>
                  <button className="bankitalia-hero__nav bankitalia-hero__nav--prev" type="button" onMouseDown={(e) => this.maybeSwitchPage('prev')}></button>
                  <button className="bankitalia-hero__nav bankitalia-hero__nav--next" type="button" onMouseDown={(e) => this.maybeSwitchPage('next')}></button>
                </div>
              </div>
            </div>
          </div>

          <footer className="container bankitalia-footer">
            <div className="row bankitalia-footer__row">
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--logo">
                <Link className="bankitalia-logo" to="/">
                  <img src="/images/shared/bankitalia-logo.png" />
                </Link>
              </div>
              <div className="col-lg-6 bankitalia-footer__col bankitalia-footer__col--pagination">
                <div className="bankitalia-game-carousel__pagination"></div>
              </div>
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--copyright">
                <p className="bankitalia-copyright">&copy; Copyright 2021 Banca d’Italia</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(StudentPage);