import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-01_P-04-SL-02-HOT-01';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/orig/031.png',
        animation: 'playful',
        from: 'top',
        speed: 'fast',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Inizia dalle piccole cose`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1
  ],

  extra_class: 's1p4s2'
};