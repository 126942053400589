import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j13.png',

  heading: `Quindi qual è il vantaggio di avere i soldi nel "salvadanaio virtuale"?`,
  paragraph: ``,

  answers: [
    {
      text: `Nessuno! Preferisco il salvadanaio che è nella mia cameretta.`,
      feedback: `Per quanto siamo molto affezionati al nostro salvadanaio, questo non è il modo più comodo per conservare i nostri soldi. Non posso collegare la carta al salvadanaio che ho in casa: quindi se devo fare un acquisto devo avere i contanti sempre con me e non posso fare acquisti.`,
      feedbackType: 'error',
      points: 0
    },
    {
      text: `Poter usare una carta collegata per fare acquisti online oppure senza contanti.`,
      feedbackType: 'success',
      feedback: `Bravo, ora non ti resta che crescere ancora un po'!`,
      points: 1
    },
    {
      text: `Poter usare la carta per spendere quanto si vuole senza limiti.`,
      feedbackType: 'error',
      feedback: `Magari, ma i soldi non crescono sugli alberi, ricordi?`,
      points: 0
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}