import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-04-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/134.png',

  heading: `Il premio per chi dà soldi`,
  paragraph: `I soldi investiti possono rendere un interesse, che è il compenso per chi li mette a disposizione degli altri. Di solito, si esprime come un tasso, cioè una percentuale: il 3% all’anno, ad esempio, significa che per ogni 100 € investiti chi ha messo a disposizione i soldi ne ottiene 3 come interesse dopo ogni anno. Se investe 400 €, otterrà quattro volte tanto e quindi 3 x 4 = 12 €.  Ne otterrà… o meglio, si aspetta di ottenerne; in realtà quando investiamo non abbiamo la certezza assoluta del guadagno che realizzeremo, perché in ogni investimento c’è un po’ di rischio, cioè la possibilità che chi riceve il denaro non sia in grado pagare gli interessi e restituire, in tutto o in parte, il capitale.

  Alcuni investimenti sono più rischiosi: se presto dei soldi a una azienda potrebbe capitare che non riesca a restituirmeli, mentre se li presto allo Stato me li restituirà di sicuro... O meglio, solo in rari casi gli Stati sono falliti e in Europa non succede da molto tempo. Il rischio in finanza non va evitato a ogni costo, ma assunto in modo consapevole: tanto maggiore è il rischio, tanto più devo guadagnare; in pratica, gli investimenti più rischiosi sono ricompensati con un interesse più alto, anche perché altrimenti nessuno li sceglierebbe.`
}