import React from 'react';
import ReactDOM from 'react-dom';

export default class HotspotModal extends React.PureComponent {
  render() {
    const portalNode = document.getElementById('bankitalia-react-portal');
    if (!portalNode) {
      return '<p>NO PORTAL</p>';
    }

    if (!this.props.open) {
      return '';
    }

    return ReactDOM.createPortal(
      <div className="modal in magictime swashIn">
        <div className={`bankitalia-modal ${this.props.className}`} role="document">
          <button className="bankitalia-modal__close" type="button" data-dismiss="modal" onClick={this.props.onClose}>
            <img width="35px" src="/images/icons/close2x.png" />
          </button>

          { this.props.children }
        </div>
      </div>,
      portalNode
    );
  }
}