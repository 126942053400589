import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-04-SL-02-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-04-SL-02-HOT-02';

export default {
  id: 'SECT-02–P-04-SL-02',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/146_0000_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      }
    ]
  },

  page_content: {
    heading: `Mi interessa l’interesse`,
    paragraph: `Come far fruttare i miei risparmi? Piantandoli una mattonella ballerina della soffitta o sotto il materasso? Ma no... Conviene investirli. Con prudenza`,
  },

  hotspots: [
    hotspot1,
    hotspot2
  ],

  extra_class: ''
};