import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/011.png',

  heading: `Il costo che non si vede`,
  paragraph: `Ci sono però molte cose che sembrano gratuite ma che richiedono manutenzione o qualche lavoro. Se possiamo camminare per le **strade** è perché qualcuno le ha costruite e le mantiene in ordine. Se possiamo prendere gratuitamente dei libri in **biblioteca** è perché qualcuno li ha comprati e paga per l'edificio in cui si trovano e per il personale che ce li presta. Se possiamo correre al pronto soccorso quando stiamo male ed essere curati è perché qualcuno paga **l'ospedale**, i medici, gli infermieri, le attrezzature, le medicine`
}