import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-01-SL-01-HOT-02',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/305.png',

  heading: `Parliamo di... reddito`,
  paragraph: `La nostra capacità di spesa dipende dai nostri redditi! Come sappiamo esistono tanti tipi di reddito: ricordiamo i principali.  
  
  Redditi da lavoro dipendente, che si ottengono svolgendo il proprio lavoro presso un datore di lavoro pubblico o privato in cambio di una retribuzione periodica.  
  Redditi da lavoro autonomo, che si ottengono conducendo personalmente e abitualmente la propria attività produttiva e offrendo un servizio o un bene...  
  Redditi d’impresa, che dipendono dal profitto che l’imprenditore ottiene dalla propria attività cioè l’eccedenza dei ricavi che resta all’imprenditore dopo aver pagato i beni e i servizi, compreso il lavoro degli altri, necessari per la sua attività.  
  Redditi da capitale, che si ottengono investendo i soldi che si hanno.  Redditi fondiari, che si ottengono cedendo ad altri l’uso temporaneo di terreni e fabbricati.  
  Redditi diversi, tutto ciò che non rientra nei precedenti, come un’eredità.  
  
  Sommando i redditi di tutte le famiglie e le imprese che compongono il sistema economico di un paese si ottiene, a meno di alcune correzioni, il Prodotto Interno Lordo, o PIL. Osservando le variazioni del PIL nel tempo abbiamo una buona indicazione di come va l’economia di un paese. Inoltre se dividiamo il PIL di un paese per il numero di abitanti otteniamo il PIL pro capite. Questa grandezza è molto utile per confrontare il PIL tra diversi paesi.`
}