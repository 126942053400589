import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-01-HOT-04',
  
  type: constants.HOTSPOT_TYPE_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `2 euro Irlanda`,
  paragraph: `Tutte le monete dell'Irlanda hanno questa splendida arpa celtica sul retro. L'arpa infatti è un'importante tradizione ed è accompagnata dalla parola Eire, Irlanda in irlandese.`
}