import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-05-SL-01-HOT-01',
  
  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/036.png',

  heading: `Un sogno dispendioso`,
  paragraph: `Non sempre abbiamo tutti i soldi che ci servono per realizzare quello che vogliamo, a volte mancano pochi centesimi per comprare il gelato, a volte mancano molti soldi per realizzare un sogno come quello di Teo. Ma bisogna stare attenti, quando si tratta di soldi bisogna chiedere alle persone giuste! Quando servono pochi soldi si può tranquillamente chiedere ad amici e parenti, senza tenere conto di interessi e altre cose complicate, basta ricordarsi di restituirli! Quando invece i soldi che servono sono tanti, è bene chiedere sempre ai propri genitori per capire se è una spesa che si può affrontare; quando si è più grandi si può andare in banca per chiedere un prestito. Non fidatevi di chi vuole prestarvi i soldi come una banca ma non è autorizzato a farlo!`
}