import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/317.png',

  heading: `Conservare la moneta e preservarne il valore`,
  paragraph: `Nella sua funzione di riserva di valore, la moneta viene conservata per un uso futuro, con la ragionevole certezza che permetta anche domani di acquistare la stessa quantità di beni che potrebbe essere acquistata oggi mantenendo quindi il suo potere di acquisto. Uno dei più comuni strumenti per conservare i propri soldi è depositarli presso un intermediario in un conto corrente. Si tratta di una forma di deposito in cui le somme che si sono versate possono essere utilizzate in ogni momento e che, per questo motivo, non dà interesse o lo dà in misura molto contenuta.

  E, anche quando garantisce una remunerazione, bisogna ricordare che il tasso di interesse pattuito è nominale, ovvero ci dice quanto cresce, solitamente in un anno, la somma versata, ma non assicura necessariamente il mantenimento del potere di acquisto del capitale iniziale: l’interesse nominale potrebbe non essere sufficiente a compensare un’eventuale crescita dei prezzi. `
}