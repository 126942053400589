import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-02-SL-01-HOT-04',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI_TOGGLES,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: '',
  paragraph: `Mantenere la stabilità dei prezzi è l’obiettivo primario della Banca Centrale Europea per i paesi che hanno adottato l’euro. Ma quali sono questi paesi?`,

  label: 'EURO?',

  items: [
    {
      term: 'Finlandia, Belgio, Cipro, Estonia',
      sum_cond_value: true
    },
    {
      term: 'Austria, Francia, Germania, Irlanda, Italia',
      sum_cond_value: true
    },
    {
      term: 'Ungheria, Islanda, Polonia, Svezia, Svizzera',
      sum_cond_value: false
    },
    {
      term: 'Grecia, Lettonia Lituania, Spagna, Malta',
      sum_cond_value: true
    },
    {
      term: 'Paesi Bassi, Portogallo, Slovacchia, Slovenia, Lussemburgo',
      sum_cond_value: true
    }
  ],

  points: 1,

  feedback: {
    correct: `Bravo hai indovinato
    Ricorda però che l’euro è anche adottato da Andorra, Principato di Monaco, San Marino e Stato della Città del Vaticano, microstati che non fanno parte dell’UE, ma hanno stipulato con essa accordi che permettono l’utilizzo dell’euro quale valuta ufficiale.`,
    wrong: `Risposta sbagliata
    Ad oggi fanno parte di quella che chiamiamo Eurozona, oltre all’Italia, Austria, Belgio, Cipro, Estonia, Finlandia, Francia, Germania, Grecia, Irlanda, Lettonia, Lituania, Lussemburgo, Malta, Paesi Bassi, Portogallo, Slovacchia, Slovenia e Spagna. `
  }
}