import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Le fragole`,
  paragraph: `Uhm! Che buone, ma non crescono al supermercato. Da dove vengono?`
}