import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-04-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/j14.png',

  heading: `Il risparmio`,
  paragraph: `Il risparmio è quel denaro che scegliamo di non spendere subito e di mettere via per il futuro. Serve principalmente a due cose: a realizzare un desiderio, cioè a mettere da parte una cifra che ti permette di acquistare qualcosa che vogliamo, oppure ad affrontare una spesa imprevista e a risolvere un'emergenza.`
}