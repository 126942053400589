import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-02-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_MODAL_ASSOC,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Associa ogni tipo di moneta-merce a un luogo dove è stata o è utilizzata...`,
  paragraph: ``,

  items: [
    {
      text: `conchiglie`,
      term: 'India dell’Ottocento'
    },
    {
      text: 'barre di sale',
      term: 'Etiopia, ancora oggi'
    },
    {
      text: 'riso',
      term: 'Giappone del Seicento'
    },
    {
      text: 'semi di cacao',
      term: 'Impero azteco'
    },
    {
      text: 'spiedi di metallo',
      term: 'Grecia antica'
    },
    {
      text: 'pacchi di foglie di tabacco',
      term: 'Colonie nordamericane del Settecento'
    },
  ],
  extra_terms: [],

  points: 1,

  feedback: {
    correct: `Ottimo risultato! Hai davvero un buon intuito e il senso della storia. `,
    wrong: `Era una sfida difficile... Andrà meglio la prossima.`
  }
}