import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-02-SL-01-HOT-03',

  type: constants.HOTSPOT_TYPE_BALLOON,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `1 euro Grecia`,
  paragraph: `Nella moneta da 1 euro della Grecia è rappresentata una civetta, un simbolo della città di Atene: pensate che lo stesso simbolo era presente sulle dacme ateniesi del quinto secolo a.C., un sacco di tempo fa!`
}