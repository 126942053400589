import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-05-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_MULTI,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Qual è l'interesse più conveniente?`,
  paragraph: `Ora proviamo a metterci nei panni dei grandi e proviamo a capire qual è la proposta più conveniente per la somma necessaria per organizzare il concerto di Teo. Teo pensa in grande e ha calcolato che serviranno circa 4000 euro che restituirà dopo 12 mesi. Ricorda: dato che chiediamo dei soldi, gli interessi li dobbiamo pagare a chi ci presta i soldi, quindi meno interessi dovremo pagare meglio è! (Se hai dei dubbi, usa prima il calcolatore dell'interesse in questa stessa pagina, così puoi assicurarti di aver fatto la scelta giusta!):`,

  answers: [
    {
      text: `Una banca ci propone un prestito di 4000 euro a un tasso d'interesse dell'1%. Per ricevere questo finanziamento dobbiamo però pagare alla banca una somma iniziale di 20 euro per la gestione della pratica.`,
      feedback: `Corretto! Gli interessi che dovremmo pagare sono l'1% di 4000, quindi 40 euro. A questa somma di denaro devo aggiungere i 20 euro che chiede la banca. Nel complesso pagherò 40 euro di interessi e 20 euro di somma iniziale. Totale 60 euro che sono meno di quelli che mi propone l'altra banca e soprattutto il mio amico!`,
      feedbackType: 'success',
      points: 1
    },
    {
      text: `Un amico ci propone un prestito di 4000 euro dicendoci che ne vuole 2000 in più quando li restituiamo.`,
      feedbackType: 'error',
      feedback: `Sarà anche un amico, ma è anche un gran furbone con i soldi, 2000 euro in più significa un tasso d'interesse del 50%, è tantissimo! Non è una buona idea.`,
      points: 0
    },
    {
      text: `Un'altra banca ci darebbe 4000 euro con un interesse del 2% all'anno.`,
      feedbackType: 'error',
      feedback: `Alla fine pagheremo 80 euro di interessi, mentre l'altra banca ci farebbe pagare 60 euro. Se non sei convinto prova a usare il calcolatore dell'interesse in questa pagina!`,
      points: 0
    },
  ],
  hint: {
    message: '',
    image: ''
  }
}