import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-03_P-01-SL-03-HOT-01';

export default {
  id: 'SECT-03_P-01-SL-03',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-03/all/309.png',
        from: '',
        speed: '',
        stack: 0
      },
    ]
  },

  page_content: {
    heading: `Ma ce l’abbiamo il budget?`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1
  ],

  extra_class: ''
};