import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-05-SL-03-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_INPUT_VALUE,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Calcola il tasso di interesse`,
  paragraph: `Immagina che il tasso annuo di usura sia del 9%.

  Calcola il valore del tasso di interesse annuo per capire se è all'interno dei valori consentiti:

  Prestito: 250 €
  Durata in anni: 1 anno
  Contante da restituire: 275 €`,

  value_type_override: '%',

  feedback_by_value: [
    {
      value: 10,
      operator: '==',
      feedback_message: `Ottimo lavoro! Il valore del 10% è considerato tasso di usura.`,
      feedback_type: 'success'
    },
    {
      value: 10,
      operator: '!=',
      feedback_message: `Risposta sbagliata. Il valore corretto è 10% che è considerato tasso di usura.`,
      feedback_type: 'error'
    }
  ],

  feedback_comment: ``
}