import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-01-HOT-04',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/005.png',

  heading: `Il reddito dei piccoli`,
  paragraph: `Come hanno detto i nostri amici, il reddito dei bambini è la **paghetta**. Tutti sanno cos'è una paghetta! Sono quei soldi che i bambini ricevono ogni tanto dai genitori, dai nonni o da altri parenti.

  Bisogna **sempre stare attenti** a come si spende, perché **la paghetta non è infinita**! Ci sono cose più importanti e altre meno.`
}