import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/010.png',

  heading: `Non tutto si paga`,
  paragraph: `Non tutto ha un **prezzo**. Ci sono tante cose che possiamo fare senza dover pagare: ridere con gli **amici**, contemplare un tramonto, giocare nel parco, girare per la città a piedi, visitare bei posti, godere di iniziative e spettacoli organizzati da associazioni di **volontariato**.`
}