import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-01-SL-02-HOT-04',

  type: constants.HOTSPOT_TYPE_MODAL_DISCOVER,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Omar vuole fare il cantante da grande. E tu, cosa vuoi fare da grande? Clicca e scopri di più su questi lavori`,
  paragraph: ``,

  items: [
    {
      text: `Ti piacciono gli animali, eh? Il veterinario ama tantissimo gli animali ed è il loro medico. Il veterinario che ha il suo studio ed è un libero professionista avrà un onorario.`,
      term: 'Veterinario',
      color: 'yellow'
    },
    {
      text: `Ti piace la storia? L'archeologo è sempre in giro alla scoperta di oggetti antichi e misteriosi. L'archeologo che lavora per un museo o per l'università è un dipendente e avrà uno stipendio.`,
      term: 'Archeologo',
      color: 'yellow'
    },
    {
      text: `Ti piace l'arte? Il pittore fa della propria creatività un lavoro a tempo pieno e guadagna vendendo le sue opere ad amanti dell'arte. Essendo libero professionista, il pittore avrà un onorario.`,
      term: 'Pittore',
      color: 'yellow'
    },
    {
      text: `Ti piace scrivere? Il giornalista scrive articoli per farci conoscere sempre nuove notizie. Il giornalista è sempre a caccia di nuove storie da raccontare. Il giornalista che lavora come dipendente di un giornale avrà uno stipendio.`,
      term: 'Giornalista',
      color: 'crimson'
    },
    {
      text: `È un giornalista ma non è dipendente di un giornale. Infatti "Freelance" è composto dalle parole inglesi "Free" (libera) e "lance" (lancia) e venivano chiamati così i mercenari! Un giornalista freelance riceverà un onorario per ogni pezzo che pubblica.`,
      term: 'Giornalista Freelance',
      color: 'yellow'
    },
    {
      text: `Ti piace la scienza? Di scienziati ce ne sono di tantissimi tipi! C'è l'astronomo che studia le stelle, il biologo che studia la natura, il chimico che studia le sostanze e molti altri. Gli scienziati di solito sono dipendenti di un centro di ricerca o di una università, quindi avranno uno stipendio.`,
      term: 'Scienziato',
      color: 'crimson'
    },
    {
      text: `Ti piace sapere come si costruiscono le cose? L'ingegnere potrebbe essere la scelta per te. Ce ne sono tanti tipi e ognuno è specializzato in un campo preciso, come per esempio gli ingegneri meccanici che costruiscono le macchine e altri marchingegni. L'ingegnere può essere un libero professionista e ricevere un onorario ma può anche lavorare per un'azienda e avere uno stipendio.`,
      term: 'Ingegnere',
      color: 'darkorange'
    },
    {
      text: `Sei brava con i calcoli? Potresti essere molto brava a gestire i conti di un'azienda. Gestire i conti richiede molta responsabilità perché devi avere cura dei soldi che l'azienda gestisce. Anche il contabile è un lavoratore dipendente e avrà uno stipendio.`,
      term: 'Contabile',
      color: 'crimson'
    },
    {
      text: `I commessi servono ovunque. Sono le persone che aiutano i clienti a trovare quello che stanno cercando. I commessi sono dipendenti, quindi hanno uno stipendio.`,
      term: 'Commesso',
      color: 'crimson'
    },
    {
      text: `Ne avrai sicuramente visti in giro di postini. Sono quelli che portano la posta casa per casa. Il postino è una dipendente delle poste e quindi avrà uno stipendio.`,
      term: 'Postino',
      color: 'crimson'
    },
    {
      text: `Spengono i fuochi, salvano i gattini sugli alberi, sono i vigili del fuoco anche chiamati pompieri. I pompieri sono dipendenti dello Stato e quindi avranno uno stipendio.`,
      term: 'Pompiere',
      color: 'crimson'
    },
    {
      text: `Non c'è niente di meglio di un bel gelato soprattutto quando fa caldo. Il gelataio fa il gelato per professione. Non pensate che si mangi un sacco di gelato tutti i giorni. Sennò come fa a guadagnare? A proposito di guadagni, il gelataio di solito ha un profitto.`,
      term: 'Gelataio',
      color: 'deepskyblue'
    },
    {
      text: `Buono il pane appena sfornato eh? Il panettiere è un bel mestiere anche se faticoso, infatti i panettieri si svegliano molto presto la mattina per fare il pane e venderlo a noi. Il panettiere, se vendendo il suo pane riuscirà a ricavare più di quanto ha speso, avrà un profitto`,
      term: 'Panettiere',
      color: 'deepskyblue'
    },
    {
      text: `Vende giornali, riviste e figurine. L'avrete incontrato sicuramente! L'edicolante di solito possiede un'edicola e quindi avrà un profitto dalla sua attività.`,
      term: 'Edicolante',
      color: 'deepskyblue'
    },
    {
      text: `Ti piacciono i libri? Il libraio ha un intero negozio pieno di libri, ma non li legge tutti. Li vende! È quello il suo lavoro. Il libraio ha un negozio e quindi avrà un profitto.`,
      term: 'Libraio',
      color: 'deepskyblue'
    }
  ]
}