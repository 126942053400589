import constants from '../../../../../../constants';

import hotspot1 from './hotspots/section-01-page-04-slide-01-hotspot-01';
import hotspot2 from './hotspots/section-01-page-04-slide-01-hotspot-02';
import hotspot3 from './hotspots/section-01-page-04-slide-01-hotspot-03';

export default {
  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-01/layers/animate/j15_0000_12.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-01/layers/animate/j15_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-01/layers/animate/j15_0002_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-01/layers/animate/j15_0003_4.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-01/layers/animate/j15_0004_5.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 4
      },
      {
        image: '/images/section-01/layers/animate/j15_0005_6.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 5
      },
      {
        image: '/images/section-01/layers/animate/j15_0006_7.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 6
      },
      {
        image: '/images/section-01/layers/animate/j15_0007_8.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 7
      },
      {
        image: '/images/section-01/layers/animate/j15_0008_9.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 8
      },
      {
        image: '/images/section-01/layers/animate/j15_0009_10.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 9
      },
      {
        image: '/images/section-01/layers/animate/j15_0010_11.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 10
      },
      {
        image: '/images/section-01/layers/animate/j15_0011_14.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 11
      },
      {
        image: '/images/section-01/layers/animate/j15_0012_13.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 12
      },
      {
        image: '/images/section-01/layers/animate/j15_0013_1.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 13
      }
    ]
  },

  page_content: {
    heading: `Per cosa vuoi risparmiare?`,
    paragraph: ``,
  },

  hotspots: [
    hotspot1,
    hotspot2,
    hotspot3
  ],

  extra_class: 's1p4s1'
};