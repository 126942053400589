import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-03-HOT-03',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/335.png',

  heading: `L’importante è capire`,
  paragraph: `Gli investimenti vanno fatti con attenzione. Anche quando ci affidiamo a un consulente occorre comprendere le caratteristiche dei prodotti, le possibilità di rendimento che offrono e i rischi a cui sono sottoposti. Essere informati è un diritto: i consulenti devono proporre investimenti coerenti con la situazione del cliente e dare tutti i dettagli del caso. Attenzione: come dice un proverbio statunitense, “non esistono pranzi gratis”: le promesse di guadagni facili e sicuri dovrebbero sempre spingerci a diffidare. Ricordatevi quello che promettevano il gatto e la volpe a Pinocchio... e com’è andata a finire la storia!

  Questo non significa comunque evitare a qualsiasi costo il rischio: significa invece costruire un portafoglio di investimenti dove una parte dei nostri soldi – quelli che possiamo permetterci di lasciare investiti per un tempo più lungo - possono essere impiegati in strumenti più rischiosi, al fine di puntare a un rendimento più elevato, ma un’altra parte dovrebbe essere detenuta in strumenti liquidi o facilmente liquidabili e con basso rischio.
  La ricetta non è unica: ognuno deve individuare il mix giusto per le proprie esigenze, tenendo a mente che nel tempo le cose possono cambiare.`
}