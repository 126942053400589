import React from 'react';
import { Link } from 'react-router-dom';

import MarkdownParser from '../components/shared/MarkdownParser';

import Settings from '../config/Settings';

import Header from '../components/shared/Header';
import Navbar from '../components/shared/Navbar';
import AnimatedIllustration from '../components/shared/AnimatedIllustration';

import BANKITALIA from '../BANKITALIA';

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.COLOR_SCHEMES = [
      Settings.palettes.blue,
      Settings.palettes.pink,
      Settings.palettes.orange,
      Settings.palettes.green
    ];

    this.state = {
      currSlide: 0
    };
  }

  handleSlideChange = (i) => {
    this.setState({
      currSlide: i
    });
  }

  componentDidMount = () => {
    (function($, that) {
      $(document).ready(function() {
        BANKITALIA.init({
          swiper: {
            heroCallback: that.handleSlideChange
          }
        });
      });
    })(window.jQuery, this);
  }

  render() {
    return (
      <div className={Settings.pageFXClass + ' ' + this.COLOR_SCHEMES[this.state.currSlide]}>
        <div className="bankitalia-app bankitalia-app--mainpage">
          <Header>
            <Navbar />
          </Header>

          <div className="container bankitalia-main">
            <div className="row">
              <div className="col">

                <div className="bankitalia-hero">
                  <div className="swiper-container bankitalia-hero__swiper">
                    <div className="swiper-wrapper">
                      {
                        [
                          {
                            base_path: '/',

                            name: 'Homepage',

                            featured_image: '',
                            illustration: {
                              layers: [
                                {
                                  image: '/images/home/intro/0001_1.png',
                                  animation: 'playful',
                          from: 'left',
                                  speed: '',
                                  delay: '',
                                  stack: 1
                                },
                                {
                                  image: '/images/home/intro/0002_2.png',
                                  animation: 'playful',
                          from: 'left',
                                  speed: '',
                                  delay: '',
                                  stack: 2
                                },
                                {
                                  image: '/images/home/intro/0003_3.png',
                                  animation: 'playful',
                          from: 'left',
                                  speed: '',
                                  delay: '',
                                  stack: 2
                                },
                                {
                                  image: '/images/home/intro/0000_END.png',
                                  animation: 'playful',
                          from: 'top',
                                  speed: 'fast',
                                  delay: '',
                                  stack: 0
                                },
                              ]
                            },

                            page_content: {
                              heading: `A scuola di economia con la Banca d’Italia`,
                              paragraph: `Scegli la tua Sezione e scopri i segreti dell’economia!`
                            },
                          }
                        ].concat(
                          Settings.sections
                        ).map((section, i) => (
                          <div key={`slide-${i}`} className="swiper-slide">
                            {
                              i == this.state.currSlide && (
                                <>

                                  {
                                    i === 0 ? ( // homepage hero slide
                                      <>
                                        <div className="row">
                                          <div className="col-10 offset-1 bankitalia-teaser__col bankitalia-teaser__col--pic">
                                            {
                                              section.illustration && section.illustration.layers && section.illustration.layers.length ? <AnimatedIllustration layers={section.illustration.layers} animationType="playful" /> : <img src={section.featured_image} />
                                            }
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-10 offset-1">
                                            <h2 className="bankitalia-title" style={{textAlign: 'center'}}>{section.page_content.heading}</h2>
                                            <div style={{textAlign: 'center'}}><MarkdownParser source={section.page_content.paragraph} /></div>
                                          </div>
                                        </div>
                                      </>
                                    ) : ( // section slides
                                      <div className="row bankitalia-teaser">
                                        <div className="col-sm-10 col-md-6 col-xl-7 bankitalia-teaser__col bankitalia-teaser__col--pic">
                                          {
                                            section.illustration && section.illustration.layers && section.illustration.layers.length ? <AnimatedIllustration layers={section.illustration.layers} animationType="playful" /> : <img src={section.featured_image} />
                                          }
                                        </div>

                                        <div className="col-sm-10 col-md-6 col-xl-5 bankitalia-teaser__col bankitalia-teaser__col--desc">
                                          <h2 className="bankitalia-title">{section.page_content.heading}</h2>
                                          <MarkdownParser source={section.page_content.paragraph} />

                                          <Link className="bankitalia-btn bankitalia-btn--negativeViolet" to={`${section.base_path}/sommario/`} type="button">
                                            <span className="bankitalia-btn__label">inizia</span>
                                          </Link>
                                        </div>
                                      </div>
                                    )
                                  }
                                </>
                              )
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <button className="bankitalia-hero__nav bankitalia-hero__nav--prev" type="button"></button>
                  <button className="bankitalia-hero__nav bankitalia-hero__nav--next" type="button"></button>

                  <div className="bankitalia-hero__pagination"></div>
                </div>

              </div>
            </div>
          </div>
          <footer className="container bankitalia-footer">
            <div className="row bankitalia-footer__row">
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--logo">
                <Link className="bankitalia-logo" to="/">
                  <img src="/images/shared/bankitalia-logo.png" />
                </Link>
              </div>
              <div className="col-lg-6 bankitalia-footer__col bankitalia-footer__col--pagination">
                <div className="bankitalia-hero__pagination"></div>
              </div>
              <div className="col-sm-6 col-lg-3 bankitalia-footer__col bankitalia-footer__col--copyright">
                <p className="bankitalia-copyright">&copy; Copyright 2021 Banca d’Italia</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default HomePage;