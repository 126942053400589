import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-04-SL-02-HOT-02',

  type: constants.HOTSPOT_TYPE_MODAL_SORT,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: ``,
  paragraph: `Metti in ordine questi investimenti, da quello che dà più interessi a quello che ne dà di meno.`,

  items: [
    {
      text: `200 € investiti al 3% annuo e 300 investiti al 10% annuo`,
    },
    {
      text: `250 € investiti al 6,5% annuo e 250 investiti al 3% annuo`,
    },
    {
      text: `300 € investiti al 4% annuo e 200 investiti al 5% annuo`,
    },
    {
      text: `500 € investiti al 4,3% annuo`,
    },
  ],

  points: 1,

  feedback: {
    correct: `Bravo, ottimo lavoro!`,
    wrong: `Non ci siamo, prova a rifare bene i conti!`
  }
}