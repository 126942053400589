import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-04-SL-03-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '',

  heading: `Per difendere il risparmio`,
  paragraph: `Gli investimenti che promettono un rendimento più elevato sono più rischiosi, nel senso che i soldi investiti vengono utilizzati per finanziare attività produttive che possono dare risultati buoni oppure cattivi. Ad un maggior rendimento si associa una maggiore probabilità di risultati negativi del progetto finanziato. In altri termini il rendimento può essere visto come il compenso che richiediamo per tollerare il rischio che non ci venga riconosciuto alcun interesse o, nei casi peggiori, non ci venga restituito in parte o addirittura completamente il capitale, cioè i soldi che abbiamo investito.

  La relazione rischio - rendimento si manifesta quindi in questo modo: chi non vuole subire perdite deve scegliere investimenti poco rischiosi, da cui si ottiene un rendimento più basso; per puntare a rendimenti maggiori occorre assumersi più rischio.

  Inoltre, una buona strategia è fare come la nonna di Omar con le uova! Non puntare a un solo investimento ma diversificare, cioè suddividere in investimenti diversi. Per diversificare in maniera accorta i nostri investimenti non è però sufficiente investire in tanti progetti ma è importante che questi progetti siano diversi tra loro. Ad esempio, se scegliamo di investire tutti i nostri risparmi in un’azienda che produce ombrelli non avremo effettuato nessuna diversificazione. Se investiamo i nostri risparmi in un’azienda che produce ombrelli e in una che produce stivali da pioggia abbiamo effettuato una diversificazione ingenua perché, una stagione con un clima particolarmente secco, inciderà negativamente sulle vendite di entrambi i prodotti e quindi sui profitti di tutte e due le aziende. Al contrario se investiamo in un’azienda che produce ombrelli e in una che produce creme solari abbiamo fatto una buona diversificazione: se ci sarà poca pioggia e molto sole la vendita di creme solari aumenterà, altrimenti aumenterà quella di ombrelli. In entrambi i casi almeno una delle due aziende avrà buone vendite!`
}