import constants from '../../../../../../../constants';

export default {
  id: 'SECT-01_P-03-SL-02-HOT-03',
  
  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-01/orig/024.png',

  heading: `Cosa fare quando la carta si blocca?`,
  paragraph: `Per evitare che qualcuno usi la carta di qualcun altro le carte si bloccano se si inserisce un PIN errato per un certo numero di volte, di solito per più di 3 volte. E se ci siamo dimenticati il PIN? Niente paura, contattando la banca e dimostrando di essere i legittimi possessori della carta sarà possibile riceverlo nuovamente in tempi brevi. Stavolta ricordiamocelo però!`
}