import constants from '../../../../../../../constants';

export default {
  id: 'SECT-03_P-03-SL-01-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-03/all/322.png',

  heading: `Come ti mando i soldi`,
  paragraph: `Monete e banconote saranno presto un ricordo del passato? Forse sì, perché sono sempre più diffusi altri strumenti di pagamento che non richiedono di portarsi dietro monetine e banconote, con il rischio di perderle o di farsele rubare! Carte, bonifici, addebiti… sono così comodi da utilizzare che talvolta nemmeno ci sembra di avere speso, e invece il denaro si muove anche se non lo tocchiamo. Tutte queste transazioni sono possibili grazie al sistema dei pagamenti. Questo sistema è fondamentale per le moderne economie: garantisce che, anche se non c’è uno scambio fisico di moneta, la moneta si sposti comunque.

  Per poter usare molti di questi strumenti serve un conto di pagamento aperto presso un intermediario. Esistono molti conti di pagamento: il conto corrente bancario e quello postale sono le soluzioni più note. Ogni conto è associato a un IBAN, l’International Bank Account Number, un codice alfanumerico che lo individua senza possibilità di dubbio e ci consente di effettuare le operazioni.`
}