import constants from '../../../../../../constants';

import hotspot1 from './hotspots/SECT-02–P-05-SL-03-HOT-01';
import hotspot2 from './hotspots/SECT-02–P-05-SL-03-HOT-02';

export default {
  id: 'SECT-02–P-05-SL-03',

  featured_image: '',
  illustration: {
    layers: [
      {
        image: '/images/section-02/animated/layers/144_0003_4.png',
        animation: 'playful',
        from: 'right',
        speed: '',
        stack: 3
      },
      {
        image: '/images/section-02/animated/layers/144_0000_1.png',
        animation: 'playful',
        from: 'right',
        speed: '',
        stack: 0
      },
      {
        image: '/images/section-02/animated/layers/144_0001_2.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 1
      },
      {
        image: '/images/section-02/animated/layers/144_0002_3.png',
        animation: 'playful',
        from: 'left',
        speed: '',
        stack: 2
      },
      {
        image: '/images/section-02/animated/layers/144_0004_5.png',
        animation: 'playful',
        from: 'left',
        speed: 'xxxxx', // disable animatino
        stack: 4
      },
    ]
  },

  page_content: {
    heading: `A chi posso chiedere?`,
    paragraph: `Se ogni persona sulla Terra mi darà un centesimo, potrò costruirmi una navicella spaziale in men che non si dica…`,
  },

  hotspots: [
    hotspot1,
    hotspot2
  ],

  extra_class: ''
};