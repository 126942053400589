import React from 'react';

class Bankitalia_ToggleSwitch extends React.Component {
	constructor() {
		super();

		this.state = {
			checked: false
		};

		this.onBankitalia_ToggleSwitchChange = this.onBankitalia_ToggleSwitchChange.bind(this);
	}

	onBankitalia_ToggleSwitchChange() {
		if (this.props.disabled) {
			return false;
		}

		this.setState({
			checked: !this.state.checked
		}, () => {
      this.props.onChange(this.state.checked);
    });
	}

	render() {
		return (
			<div className='Bankitalia_ToggleSwitch Bankitalia_ToggleSwitch__rounded'>
				<div className='Bankitalia_ToggleSwitch__wrapper'>
					<div className={`Slider ${this.state.checked && 'isChecked'}`} onClick={this.onBankitalia_ToggleSwitchChange}></div>
				</div>
			</div>
		);
	}
}

export default Bankitalia_ToggleSwitch;