import constants from '../../../../../../../constants';

export default {
  id: 'SECT-02–P-03-SL-02-HOT-01',

  type: constants.HOTSPOT_TYPE_INFO_PANEL,

  x: null,
  y: null,

  hotspot_icon: '',
  featured_image: '/images/section-02/static/125.png',

  heading: `La rete idraulica della moneta`,
  paragraph: `Il sistema dei pagamenti è l’insieme di norme, operatori, strutture materiali o informatiche che consentono a persone, aziende ed enti pubblici di portare a termine un pagamento in maniera sicura ed efficiente, sia all’interno di un paese che a livello internazionale. E sono in tanti a lavorare perché tutto avvenga in sicurezza e affinchè nemmeno una "goccia" vada perduta`
}